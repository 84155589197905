import React, { useMemo } from "react";
import { AppContext } from "../context/app/context";
import { Role, rolePermissions } from "../models/Role";

interface Permissions {
  canView: boolean;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  canCopy: boolean;
  isAdmin: boolean;
}

export const useAuthorization = (
  section?: string,
  type: "data" | "template" = "template"
): Permissions => {
  const { state: context } = React.useContext(AppContext);

  const userPermissions = useMemo(() => {
    const userPermissions: Permissions = {
      canView: false,
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      canCopy: false,
      isAdmin: false,
    };

    if (context.user && section) {
      if (context.user.role === Role.Admin) {
        return {
          ...userPermissions,
          canCopy: true,
          canCreate: true,
          canDelete: true,
          canUpdate: true,
          canView: true,
          isAdmin: true,
        };
      }

      const permissions = rolePermissions?.find(
        (r) => r.role === context.user?.role
      )?.permissions;

      if (!permissions) return userPermissions;

      userPermissions.canView =
        permissions.includes(`view:${section}-${type}`) ||
        permissions.includes(`view:${section}-full`);

      userPermissions.canCreate =
        permissions.includes(`create:${section}-${type}`) ||
        permissions.includes(`create:${section}-full`);

      userPermissions.canUpdate =
        permissions.includes(`update:${section}-${type}`) ||
        permissions.includes(`update:${section}-full`);

      userPermissions.canDelete =
        permissions.includes(`delete:${section}-${type}`) ||
        permissions.includes(`delete:${section}-full`);

      userPermissions.canCopy =
        permissions.includes(`copy:${section}-${type}`) ||
        permissions.includes(`copy:${section}-full`);
    }

    return userPermissions;
  }, [section, type, context.user]);

  return userPermissions;
};
