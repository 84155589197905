export interface BulkImageUpload {
  documentCode: string;
  filePath: string;
  fileName: string;
  uploadCategory: BulkImageUploadCategory;
  error?: string;
}

export enum BulkImageUploadCategory {
  MultiSidedDocuments = "MultiSidedDocuments",
  DoubleSidedDocuments = "DoubleSidedDocuments",
  Counterfeits = "Counterfeits",
  Banknotes = "Banknotes",
}
