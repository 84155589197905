import { useContext, useEffect } from "react";
import { AppContext } from "../context/app/context";
import { useLocation } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

interface ReturnTypes {
  initializeFilters: (templateId: string) => Filter;
  changeFilterPanel: (visible: boolean) => void;
  removeFilters: () => Filter;
  setSessionFilter: (filter: Filter) => void;
  disableBefore: (current: Dayjs, last?: string) => boolean;
  disabledAfter: (current: Dayjs, last?: string) => boolean;
}

export interface Filter {
  filterStatus?: string;
  filterType?: string;
  filterFromDate?: string;
  filterUntilDate?: string;
  filterAttribute?: { [x: string]: any };
}

export const useDocumentFilter = (): ReturnTypes => {
  const { dispatch } = useContext(AppContext);
  const location = useLocation();

  const initializeFilters = (templateId: string): Filter => {
    const getFilters = sessionStorage.getItem("sessionAttributeFilters");
    if (
      getFilters &&
      templateId ===
        new URLSearchParams(location.search).get("documentTemplateId")
    ) {
      const parsedFilters = JSON.parse(getFilters);
      const parsedAttributeFilters = JSON.parse(parsedFilters.filterAttribute);
      return {
        ...parsedFilters,
        filterAttribute: parsedAttributeFilters,
      };
    }

    return removeFilters();
  };

  const changeFilterPanel = (visible: boolean) => {
    dispatch({
      type: "SET_FILTER_DOCUMENTSERIES_PANEL",
      payload: visible,
    });
    localStorage.setItem("setFilterDocumentSeriesPanel", visible.toString());
  };

  const removeFilters = () => {
    return {
      filterAttribute: {},
      filterFromDate: undefined,
      filterStatus: undefined,
      filterUntilDate: undefined,
      filterType: undefined,
    };
  };

  const setSessionFilter = (filter: Filter) => {
    sessionStorage.setItem(
      "sessionAttributeFilters",
      JSON.stringify({
        ...filter,
        filterAttribute: JSON.stringify(filter.filterAttribute),
      })
    );
  };

  const disableBefore = (current: Dayjs, last?: string): boolean => {
    return (current && last && current < dayjs(last).endOf("day")) as boolean;
  };

  const disabledAfter = (current: Dayjs, last?: string): boolean => {
    return (current && last && current > dayjs(last).endOf("day")) as boolean;
  };

  useEffect(() => {
    const getFilterPanel = localStorage.getItem("setFilterDocumentSeriesPanel");
    if (getFilterPanel != null) {
      dispatch({
        type: "SET_FILTER_DOCUMENTSERIES_PANEL",
        payload: JSON.parse(getFilterPanel) === true,
      });
    }
  }, [dispatch]);

  return {
    initializeFilters,
    changeFilterPanel,
    removeFilters,
    setSessionFilter,
    disableBefore,
    disabledAfter,
  };
};
