import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Region } from "../../models/reference_lists/Region";
import api from "../../services/api";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../hooks/useNotification";
import { useMutationBase } from "../useMutationBase";
import { CONST_REGIONS } from "../../utilities/constants/query-contants";

export const getRegion = (id?: string): Promise<Region> => {
  return api.get({
    path: `${CONST_REGIONS.plural}/${id}`,
  });
};

export const useQueryRegion = (id?: string) => {
  return useQuery<Region>(
    [CONST_REGIONS.singular, { id }],
    () => getRegion(id),
    {
      enabled: !!id,
    }
  );
};

export const postRegion = (payload: Region): Promise<void> => {
  return api.post({ path: CONST_REGIONS.plural, body: { ...payload } });
};

export const useSaveRegion = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (payload: Region) => postRegion(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_REGIONS.singular]);
      queryClient.invalidateQueries([CONST_REGIONS.plural]);

      notifySuccess(
        t("common:successSave", {
          entity: t("entities:region").toLocaleLowerCase(),
        })
      );
    },
  });
};

export const deleteRegion = (id: string) => {
  return api.delete({ path: `${CONST_REGIONS.plural}/${id}` });
};

export const useDeleteRegion = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (id: string) => deleteRegion(id),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_REGIONS.singular]);
      queryClient.invalidateQueries([CONST_REGIONS.plural]);

      notifySuccess(
        t("common:successDelete", {
          entity: t("common:region").toLocaleLowerCase(),
        })
      );
    },
  });
};
