import { EditOutlined } from "@ant-design/icons";
import { Button, Drawer, Layout } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { CmsCreateButton } from "../../common/ButtonComponents";
import { GroupManager, GroupType } from "../../common/GroupManager";
import {
  CmsContent,
  CmsPageHeader,
  CmsSider,
  CmsTable,
} from "../../common/PageComponents";
import { ITreeViewItem, TreeView } from "../../common/TreeView";
import { AppContext } from "../../../context/app/context";
import { useDeleteSecurityFeature } from "../../../queries/security-features/detail";
import { useQuerySecurityFeatureGroups } from "../../../queries/security-features-groups/lists";
import { useQuerySecurityFeatures } from "../../../queries/security-features/lists";

export const SecurityFeatureList = () => {
  const { t } = useTranslation();
  const { canCreate, canUpdate, canDelete, canView } =
    useAuthorization("securityFeature");
  const { push } = useHistory();
  const { dispatch } = React.useContext(AppContext);
  const [state, setState] = useState<{
    selectedGroup?: string;
    expandedGroups?: string[];
    groupsManagerVisible: boolean;
  }>({
    selectedGroup:
      sessionStorage.getItem("currentSecurityFeatureGroup") ?? undefined,
    expandedGroups: undefined,
    groupsManagerVisible: false,
  });

  const {
    data: securityFeatures,
    isLoading: isLoadingSecurityFeatures,
    refetch: refetchSecurityFeatures,
  } = useQuerySecurityFeatures(state.selectedGroup);
  const {
    mutateAsync: deleteSecurityFeature,
    isLoading: isDeletingSecurityFeature,
  } = useDeleteSecurityFeature();
  const {
    data: securityFeatureGroups,
    isLoading: isLoadingSecurityFeatureGroups,
    refetch: refetchSecurityFeatureGroups,
  } = useQuerySecurityFeatureGroups();

  useEffect(() => {
    if (securityFeatures) {
      const sessionKeys = sessionStorage.getItem(
        "securityFeatureGroupExpandedKeys"
      );
      const keys = sessionKeys ? sessionKeys.split(",") : [];
      setState((prevState) => ({ ...prevState, expandedGroups: keys }));
    }
  }, [securityFeatures]);

  useEffect(() => {
    if (state.selectedGroup) {
      sessionStorage.setItem(
        "currentSecurityFeatureGroup",
        state.selectedGroup
      );
    }

    if (state.expandedGroups) {
      sessionStorage.setItem(
        "securityFeatureGroupExpandedKeys",
        state.expandedGroups.join(",")
      );
    }
  }, [state.selectedGroup, state.expandedGroups]);

  React.useEffect(() => {
    if (securityFeatures) {
      dispatch({
        type: "SET_CUSTOM_DUPLICATE_ERROR_MESSAGE",
        payload: t("errors:securityFeatureLinked"),
      });
    }
  }, [securityFeatures, dispatch, t]);

  const isLoadingAny =
    isLoadingSecurityFeatures ||
    isDeletingSecurityFeature ||
    isLoadingSecurityFeatureGroups;

  return (
    <React.Fragment>
      <CmsPageHeader
        title={t("entities:securityFeatures")}
        extra={[
          canCreate || canUpdate ? (
            <Button
              key="manage"
              icon={<EditOutlined />}
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  groupsManagerVisible: true,
                }))
              }
            >
              {t("common:manageGroups")}
            </Button>
          ) : null,
          canCreate ? (
            <CmsCreateButton
              key="create"
              onClick={() => push(`/security-feature`)}
            />
          ) : null,
        ]}
      />
      <Layout>
        <CmsSider>
          <TreeView
            items={securityFeatureGroups as ITreeViewItem[]}
            selectedKey={state.selectedGroup}
            expandedKeys={state.expandedGroups}
            onExpand={(keys) =>
              setState((prevState) => ({ ...prevState, expandedGroups: keys }))
            }
            onSelect={(groupId) =>
              setState((prevState) => ({
                ...prevState,
                selectedGroup: groupId,
              }))
            }
          />
        </CmsSider>
        <CmsContent>
          <CmsTable
            loading={isLoadingAny}
            dataSource={securityFeatures ?? []}
            rowKey="id"
            columns={[
              { title: t("properties:name"), dataIndex: "name" },
              { title: t("properties:code"), dataIndex: "code" },
              {
                title: t("properties:caption"),
                dataIndex: "caption",
                render: (caption) => getLocalizedValue(caption),
              },
            ]}
            onEdit={
              canUpdate || canView
                ? (id) => push(`/security-feature/${id}`)
                : undefined
            }
            onDelete={
              canDelete
                ? async (id) => {
                    await deleteSecurityFeature(id);
                    await refetchSecurityFeatures();
                  }
                : undefined
            }
          />
        </CmsContent>
      </Layout>
      <Drawer
        placement="left"
        open={state.groupsManagerVisible}
        width={600}
        onClose={async () => {
          await refetchSecurityFeatureGroups();
          await refetchSecurityFeatures();
          setState((prevState) => ({
            ...prevState,
            groupsManagerVisible: false,
          }));
        }}
        title={t("common:manageGroups")}
      >
        <GroupManager
          visible={state.groupsManagerVisible}
          groupType={GroupType.SecurityFeature}
        />
      </Drawer>
    </React.Fragment>
  );
};
