import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Select } from "antd";
import { connect } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { DocumentTemplate } from "../../../models/templates/DocumentTemplate";
import { FieldLink } from "../../../models/templates/FieldLink";
import {
  OrderableItem,
  addItem,
  rearrangeItems,
  removeItem,
} from "../../../utilities/ListHelpers";
import { DragSortTable } from "../../common/DragSortTable";
import { CmsForm } from "../../common/FormComponents";
import { MultilingualInput } from "../../common/MultilingualInput";
import { RowButtonSet } from "../../common/RowButtonSet";
import { FieldLinkDetailsModal } from "../fieldLinks/FieldLinkDetailsModal";

interface Props {
  disabled?: boolean;
  readOnly?: boolean;
  viewTemplateIndex: number;
  imageTemplateIndex: number;
}

interface State {
  selectedFieldLinkIndex: number | null;
  selectedFieldLink: FieldLink | null;
  fieldLinkModalVisible: boolean;
}

export const ImageTemplateDetails = connect<Props, DocumentTemplate>(
  (props) => {
    const { t } = useTranslation();
    const [state, setState] = React.useState<State>({
      selectedFieldLinkIndex: null,
      selectedFieldLink: null,
      fieldLinkModalVisible: false,
    });

    const { handleChange, values, setFieldValue } = props.formik;
    const errors =
      props.formik.errors.viewTemplates &&
      (props.formik.errors.viewTemplates[props.viewTemplateIndex] as any)
        .imageTemplates &&
      (props.formik.errors.viewTemplates[props.viewTemplateIndex] as any)
        .imageTemplates[props.imageTemplateIndex]
        ? (props.formik.errors.viewTemplates[props.viewTemplateIndex] as any)
            .imageTemplates[props.imageTemplateIndex]
        : {};

    const imageTemplate =
      values.viewTemplates[props.viewTemplateIndex]?.imageTemplates[
        props.imageTemplateIndex
      ];

    const pathToImageTemplate = `viewTemplates[${props.viewTemplateIndex}].imageTemplates[${props.imageTemplateIndex}]`;

    return (
      <React.Fragment>
        <CmsForm>
          <Form.Item
            label={t("properties:name")}
            validateStatus={!errors.name ? "success" : "error"}
            help={errors.name}
            required={true}
          >
            <Input
              placeholder={t("properties:name")}
              maxLength={50}
              disabled={props.disabled}
              readOnly={props.readOnly}
              id={`${pathToImageTemplate}.name`}
              value={imageTemplate?.name}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item
            label={t("properties:caption")}
            validateStatus={!errors.caption ? "success" : "error"}
            help={errors.caption}
            required={true}
          >
            <MultilingualInput
              mlData={imageTemplate?.caption}
              disabled={props.disabled}
              readOnly={props.readOnly}
              onChange={(e) =>
                setFieldValue(`${pathToImageTemplate}.caption`, e)
              }
            />
          </Form.Item>
          <Form.Item label={t("properties:scanningLight")}>
            <Select
              value={imageTemplate?.light}
              style={{ width: "100%" }}
              placeholder={t("properties:scanningLight")}
              disabled={props.readOnly || props.disabled}
              onChange={(e) => setFieldValue(`${pathToImageTemplate}.light`, e)}
            >
              <Select.Option value="VISIBLE">
                {t("properties:scanningLightVisible")}
              </Select.Option>
              <Select.Option value="ULTRAVIOLET">
                {t("properties:scanningLightUltraviolet")}
              </Select.Option>
              <Select.Option value="INFRARED">
                {t("properties:scanningLightInfrared")}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={t("properties:required")}>
            <Checkbox
              checked={imageTemplate?.isRequired ?? false}
              disabled={props.readOnly || props.disabled}
              onChange={(e) =>
                setFieldValue(
                  `${pathToImageTemplate}.isRequired`,
                  e.target.checked
                )
              }
            />
          </Form.Item>
          <Form.Item label={t("properties:defaultSelected")}>
            <Checkbox
              checked={imageTemplate?.isDefault ?? false}
              disabled={props.readOnly || props.disabled}
              onChange={(e) =>
                setFieldValue(
                  `${pathToImageTemplate}.isDefault`,
                  e.target.checked
                )
              }
            />
          </Form.Item>
          <Form.Item label={t("entities:fields")}>
            <DragSortTable
              bordered
              size={"small"}
              onRow={(record, index) =>
                ({
                  moveRow: (fromIndex, toIndex) =>
                    setFieldValue(
                      `${pathToImageTemplate}.fieldLinks`,
                      rearrangeItems(
                        imageTemplate?.fieldLinks as OrderableItem[],
                        fromIndex,
                        toIndex
                      )
                    ),
                } as any)
              }
              columns={[
                { title: t("entities:field"), dataIndex: "fieldName" },
                {
                  title: t("properties:exportable"),
                  dataIndex: "exportable",
                  render: (value) => (value ? t("common:yes") : t("common:no")),
                },
                {
                  title: t("properties:required"),
                  dataIndex: "isRequired",
                  render: (value) => (value ? t("common:yes") : t("common:no")),
                },
                ...(!props.readOnly
                  ? [
                      {
                        title: (
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<PlusOutlined />}
                            disabled={props.disabled}
                            style={{ margin: "0px 2px" }}
                            size="small"
                            onClick={() => {
                              setState((prevState) => ({
                                ...prevState,
                                selectedFieldLink: new FieldLink(),
                                selectedFieldLinkIndex: -1,
                                fieldLinkModalVisible: true,
                              }));
                            }}
                          />
                        ),
                        dataIndex: "actions",
                        align: "right" as any,
                        render: (text: any, record: any, index) => (
                          <RowButtonSet
                            onEdit={() =>
                              setState((prevState) => ({
                                ...prevState,
                                selectedFieldLink: record,
                                fieldLinkModalVisible: true,
                                selectedFieldLinkIndex: index,
                              }))
                            }
                            onDelete={() => {
                              const attributeLinks = removeItem(
                                index,
                                imageTemplate.fieldLinks as OrderableItem[]
                              );
                              setFieldValue(
                                `${pathToImageTemplate}.fieldLinks`,
                                attributeLinks
                              );
                              setState((prevState) => ({
                                ...prevState,
                                selectedFieldLink: null,
                                selectedFieldLinkIndex: -1,
                              }));
                            }}
                          />
                        ),
                      },
                    ]
                  : []),
              ]}
              dataSource={imageTemplate?.fieldLinks}
              rowKey={(row, index) => row.id || (index as number)}
              pagination={false}
              readOnly={props.readOnly}
            />
          </Form.Item>
        </CmsForm>
        {state.selectedFieldLink && (
          <FieldLinkDetailsModal
            fieldLink={state.selectedFieldLink}
            visible={state.selectedFieldLink != null}
            onSave={(fieldLink) => {
              let fieldLinks = imageTemplate.fieldLinks;

              if (
                state.selectedFieldLinkIndex &&
                state.selectedFieldLinkIndex >= 0
              ) {
                fieldLinks[state.selectedFieldLinkIndex] = fieldLink;
              } else {
                fieldLinks = addItem(
                  fieldLink as OrderableItem,
                  fieldLinks as OrderableItem[]
                ) as FieldLink[];
              }

              setFieldValue(`${pathToImageTemplate}.fieldLinks`, fieldLinks);

              setState((prevState) => ({
                ...prevState,
                selectedFieldLink: null,
                selectedFieldLinkIndex: -1,
                fieldLinkModalVisible: false,
              }));
            }}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                selectedFieldLink: null,
                selectedFieldLinkIndex: -1,
                fieldLinkModalVisible: false,
              }));
            }}
          />
        )}
      </React.Fragment>
    );
  }
);
