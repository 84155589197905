import generateOptions from "./generateOptions";
import request from "./request";
import { FetchCall, Options } from "./types";

class ApiHelper {
  public get: FetchCall = (args) =>
    this.generateRequest({ method: "GET", ...args });
  public delete: FetchCall = (args) =>
    this.generateRequest({ method: "DELETE", ...args });
  public post: FetchCall = (args) =>
    this.generateRequest({ method: "POST", ...args });
  public put: FetchCall = (args) =>
    this.generateRequest({ method: "PUT", ...args });
  public patch: FetchCall = (args) =>
    this.generateRequest({ method: "PATCH", ...args });
  public options: FetchCall = (args) =>
    this.generateRequest({ method: "OPTIONS", ...args });

  private generateRequest = async (options: Options): Promise<any> =>
    request(
      generateOptions({
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
    );
}

export default ApiHelper;
