import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Currency } from "../../../models/reference_lists/Currency";
import { CmsCreateButton } from "../../common/ButtonComponents";
import {
  CmsPageHeader,
  CmsSearch,
  CmsTable,
} from "../../common/PageComponents";
import { useQueryCurrencies } from "../../../queries/currencies/lists";
import { useDeleteCurrency } from "../../../queries/currencies/detail";
import { AppContext } from "../../../context/app/context";

export const CurrencyList = () => {
  const { t } = useTranslation();
  const { canCreate, canDelete, canUpdate, canView } =
    useAuthorization("currency");
  const { push } = useHistory();
  const { dispatch } = useContext(AppContext);
  const {
    data: currencies,
    isLoading: isLoadingCurrencies,
    refetch: refetchCurrencies,
  } = useQueryCurrencies();
  const { mutateAsync: deleteCurrency, isLoading: isDeletingCurrency } =
    useDeleteCurrency();

  const [currenciesFilter, setCurrenciesFilter] = useState<Currency[]>(
    currencies ?? []
  );

  useEffect(() => {
    if (!!currencies) {
      setCurrenciesFilter(currencies);
    }
  }, [currencies]);

  useEffect(() => {
    if (currenciesFilter) {
      dispatch({
        type: "SET_CUSTOM_DUPLICATE_ERROR_MESSAGE",
        payload: t("errors:currencyLinked"),
      });
    }
  }, [currenciesFilter, dispatch, t]);

  const search = (searchText: any) => {
    if (currencies) {
      const search = searchText.toLowerCase();
      const filteredCurrencies = currencies.filter((currency) => {
        const name = currency.name && currency.name.toLowerCase();
        const iso = currency.isoCode && currency.isoCode.toLowerCase();
        const isoNumber =
          currency.isoNumber && currency.isoNumber.toLowerCase();
        const subunit = currency.subunit && currency.subunit.toLowerCase();
        const symbol = currency.symbol && currency.symbol.toLowerCase();
        return (
          (name && name.includes(search)) ||
          (iso && iso.includes(search)) ||
          (isoNumber && isoNumber.includes(search)) ||
          (subunit && subunit.includes(search)) ||
          (symbol && symbol.includes(search))
        );
      });

      setCurrenciesFilter(filteredCurrencies);
    }
  };

  return (
    <React.Fragment>
      <CmsPageHeader
        title={t("entities:currencies")}
        extra={[
          <CmsSearch key="search" onChange={search} />,
          canCreate ? (
            <CmsCreateButton key="create" onClick={() => push(`/currency`)} />
          ) : null,
        ]}
      />
      <CmsTable
        loading={isLoadingCurrencies || isDeletingCurrency}
        dataSource={currenciesFilter}
        rowKey="id"
        columns={[
          { title: t("properties:name"), dataIndex: "name" },
          { title: t("properties:isoCode3"), dataIndex: "isoCode" },
          { title: t("properties:isoNumber3"), dataIndex: "isoNumber" },
          { title: t("properties:exponent"), dataIndex: "exponent" },
          { title: t("properties:subunit"), dataIndex: "subunit" },
          { title: t("properties:symbol"), dataIndex: "symbol" },
        ]}
        onEdit={
          canUpdate || canView ? (id) => push(`/currency/${id}`) : undefined
        }
        onDelete={
          canDelete
            ? async (id) => {
                await deleteCurrency(id);
                await refetchCurrencies();
              }
            : undefined
        }
      />
    </React.Fragment>
  );
};
