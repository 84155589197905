import { Tree } from "antd";
import * as React from "react";
import { TreeNodeNormal } from "antd/lib/tree/Tree";

export interface ITreeViewItem {
  id: string;
  parentId?: string;
  name: string;
  children?: ITreeViewItem[];
}

interface Props {
  items: ITreeViewItem[];
  expandedKeys?: string[];
  selectedKey?: string;
  onSelect?(itemId: string): void;
  onExpand?(items: string[]): void;
}

export const TreeView = (props: Props) => {
  const buildTree = (nodes: ITreeViewItem[]): TreeNodeNormal[] => {
    if (!nodes) {
      return [];
    }

    return nodes.map<TreeNodeNormal>((node) => ({
      key: node.id,
      title: node.name,
      children:
        node.children && node.children.length > 0
          ? buildTree(node.children)
          : undefined,
    }));
  };

  return (
    <Tree
      showLine
      expandedKeys={props.expandedKeys}
      selectedKeys={props.selectedKey ? [props.selectedKey] : []}
      onExpand={(expandedKeys) =>
        props.onExpand && props.onExpand(expandedKeys as string[])
      }
      onSelect={(selectedKeys) =>
        props.onSelect && props.onSelect(selectedKeys[0] as string)
      }
      treeData={buildTree(props.items)}
    />
  );
};
