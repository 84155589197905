export default function handleStatusCodes(code: number): boolean {
  switch (code) {
    case 204:
      return true;
    case 403:
      window.location.href = "/forbidden";
      return true;
    case 404:
      window.location.href = "/not-found";
      return true;
    case 500:
      window.location.href = "/server-error";
      return true;
    default:
      return false;
  }
}
