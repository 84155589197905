import {
  DeleteOutlined,
  FileImageOutlined,
  FileTextOutlined,
  IdcardOutlined,
  PlusOutlined,
  CaretDownFilled,
} from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import * as _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentTemplate } from "../../../models/templates/DocumentTemplate";
import { ImageTemplate } from "../../../models/templates/ImageTemplate";
import { ViewTemplate } from "../../../models/templates/ViewTemplate";
import "./DocumentTemplateTree.scss";

interface Props {
  title: string;
  documentTemplate: DocumentTemplate;
  selectedItem: { viewTemplateIndex: number; imageTemplateIndex: number };
  readOnly?: boolean;
  onSelectItem(
    viewTemplateIndex: number | null,
    imageTemplateIndex: number | null
  );
  onAddViewTemplate(): void;
  onDeleteViewTemplate(viewTemplateIndex: number): void;
  onAddImageTemplate(viewTemplateIndex: number): void;
  onDeleteImageTemplate(
    imageTemplateIndex: number,
    viewTemplateIndex: number
  ): void;
}

const ViewTemplateItem = (props: {
  onSelectItem(viewTemplateIndex: number, imageTemplateIndex: number);
  onAddViewTemplate(): void;
  onDeleteViewTemplate(viewTemplateIndex: number): void;
  onAddImageTemplate(viewTemplateIndex: number): void;
  onDeleteImageTemplate(
    imageTemplateIndex: number,
    viewTemplateIndex: number
  ): void;
  readOnly: boolean;
  selectedItem: { viewTemplateIndex: number; imageTemplateIndex: number };
  viewTemplate: ViewTemplate;
  viewTemplateIndex: number;
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const key = `vt-${props.viewTemplateIndex}`;
  const selected =
    props.selectedItem.viewTemplateIndex === props.viewTemplateIndex &&
    props.selectedItem.imageTemplateIndex === null;
  return (
    <React.Fragment key={key}>
      <div className={`document-template-tree`}>
        <CaretDownFilled
          onClick={() => setOpen(!open)}
          style={{
            display: "flex",
            alignItems: "center",
            transform: `rotate(${open ? "0deg" : "-90deg"})`,
          }}
        />
        <div
          key={key}
          className={`document-template-tree-item viewTemplate ${
            selected ? "selected" : ""
          }`}
          onClick={() => props.onSelectItem(props.viewTemplateIndex, -1)}
        >
          <div>
            <FileTextOutlined style={{ marginRight: "5px" }} />
            {props.viewTemplate.name
              ? props.viewTemplate.name
              : t("entities:viewTemplate")}
          </div>
          {!props.readOnly ? (
            <div>
              <Button
                type="primary"
                size="small"
                shape="circle"
                icon={<PlusOutlined />}
                title={t("common:add")}
                onClick={(event) => {
                  event.stopPropagation();
                  props.onAddImageTemplate(props.viewTemplateIndex);
                }}
              />
              <Popconfirm
                title={t("common:confirmDelete")}
                cancelText={t("common:no")}
                okText={t("common:yes")}
                onCancel={(event) => event && event.stopPropagation()}
                onConfirm={(event) => {
                  event?.stopPropagation();
                  props.onDeleteViewTemplate(props.viewTemplateIndex);
                }}
              >
                <Button
                  danger
                  type="primary"
                  size="small"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  title={t("common:delete")}
                  onClick={(event) => event.stopPropagation()}
                />
              </Popconfirm>
            </div>
          ) : null}
        </div>
      </div>
      {open &&
        _.map(
          props.viewTemplate.imageTemplates,
          (imageTemplate, imageTemplateIndex) => (
            <ImageTemplateItem
              onDeleteImageTemplate={props.onDeleteImageTemplate}
              imageTemplate={imageTemplate}
              imageTemplateIndex={imageTemplateIndex}
              viewTemplateIndex={props.viewTemplateIndex}
              readOnly={props.readOnly}
              selectedItem={props.selectedItem}
              onSelectItem={props.onSelectItem}
            />
          )
        )}
    </React.Fragment>
  );
};

const ImageTemplateItem = (props: {
  onSelectItem(viewTemplateIndex: number, imageTemplateIndex: number);
  onDeleteImageTemplate(
    imageTemplateIndex: number,
    viewTemplateIndex: number
  ): void;
  readOnly: boolean;
  imageTemplate: ImageTemplate;
  imageTemplateIndex: number;
  viewTemplateIndex: number;
  selectedItem: { viewTemplateIndex: number; imageTemplateIndex: number };
}) => {
  const { t } = useTranslation();
  const key = `vt-${props.viewTemplateIndex}:it-${props.imageTemplateIndex}`;
  const selected =
    props.selectedItem.viewTemplateIndex === props.viewTemplateIndex &&
    props.selectedItem.imageTemplateIndex === props.imageTemplateIndex;
  return (
    <div
      key={key}
      className={`document-template-tree-item imageTemplate ${
        selected ? "selected" : ""
      }`}
      onClick={() =>
        props.onSelectItem(props.viewTemplateIndex, props.imageTemplateIndex)
      }
    >
      <div>
        <FileImageOutlined style={{ marginRight: "5px" }} />
        {props.imageTemplate.name
          ? props.imageTemplate.name
          : t("entities:imageTemplate")}
      </div>
      {!props.readOnly ? (
        <div>
          <Popconfirm
            title={t("common:confirmDelete")}
            cancelText={t("common:no")}
            okText={t("common:yes")}
            onCancel={(event) => event && event.stopPropagation()}
            onConfirm={(event) => {
              event?.stopPropagation();
              props.onDeleteImageTemplate(
                props.imageTemplateIndex,
                props.viewTemplateIndex
              );
            }}
          >
            <Button
              danger
              size="small"
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
              title={t("common:delete")}
              onClick={(event) => event.stopPropagation()}
            />
          </Popconfirm>
        </div>
      ) : null}
    </div>
  );
};

export const DocumentTemplateTree = (props: Props) => {
  const { t } = useTranslation();

  const selected =
    props.selectedItem.viewTemplateIndex === null &&
    props.selectedItem.imageTemplateIndex === null;

  return (
    <React.Fragment>
      <div
        key="dt"
        className={`document-template-tree-item ${selected ? "selected" : ""}`}
        onClick={() => props.onSelectItem(null, null)}
      >
        <div>
          <IdcardOutlined style={{ marginRight: "5px" }} />
          {props.title}
        </div>
        {!props.readOnly ? (
          <div>
            <Button
              type="primary"
              size="small"
              shape="circle"
              icon={<PlusOutlined />}
              title={t("common:add")}
              onClick={(event) => {
                event.stopPropagation();
                props.onAddViewTemplate();
              }}
            />
          </div>
        ) : null}
      </div>
      {_.map(
        props.documentTemplate.viewTemplates,
        (viewTemplate, viewTemplateIndex) => (
          <ViewTemplateItem
            viewTemplate={viewTemplate}
            viewTemplateIndex={viewTemplateIndex}
            readOnly={props.readOnly ?? false}
            selectedItem={props.selectedItem}
            onAddImageTemplate={props.onAddImageTemplate}
            onAddViewTemplate={props.onAddViewTemplate}
            onDeleteViewTemplate={props.onDeleteViewTemplate}
            onDeleteImageTemplate={props.onDeleteImageTemplate}
            onSelectItem={props.onSelectItem}
          />
        )
      )}
    </React.Fragment>
  );
};
