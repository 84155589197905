import { TFunction } from "i18next";
import { SecurityFeature } from "../../models/templates/SecurityFeature";
import { validateName, validateRegEx, validateRequired } from "../Validators";

export const ValidateSecurityFeature = (
  securityFeature: SecurityFeature,
  t: TFunction
) => {
  const errors: any = {};

  if (!securityFeature.name || !securityFeature.code) return errors;

  const nameResult =
    validateRequired(securityFeature.name, t("properties:name")) ||
    validateName(securityFeature.name, t("properties:name"));
  if (nameResult != null) {
    errors.name = nameResult;
  }

  const codeResult =
    validateRequired(securityFeature.code, t("properties:code")) ||
    validateRegEx(
      securityFeature.code,
      /^[A-Z0-9_]*$/,
      t("validations:code", { property: t("properties:code") })
    );
  if (codeResult != null) {
    errors.code = codeResult;
  }

  return errors;
};
