import {
  Button,
  Checkbox,
  Form,
  Input,
  Layout,
  Modal,
  Select,
  TreeSelect,
} from "antd";
import { Formik } from "formik";
import * as _ from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../hooks/useApi";
import { Attribute } from "../../../models/templates/Attribute";
import { AttributeLink } from "../../../models/templates/AttributeLink";
import { Group } from "../../../models/templates/Group";
import { convertGroupsForTreeSelect } from "../../../utilities/GroupHelper";
import { validateRequired } from "../../../utilities/Validators";
import { CmsModalForm } from "../../common/FormComponents";
import { MultilingualInput } from "../../common/MultilingualInput";
import { CmsPageLoader } from "../../common/PageComponents";

interface Props {
  attributeLink: AttributeLink;
  onClose(): void;
  onSave(attributeLink: AttributeLink): void;
}

interface State {
  selectedGroupId?: string;
}

export const AttributeLinkDetailsModal = (props: Props) => {
  const { t } = useTranslation();
  const [state, setState] = React.useState<State>({
    selectedGroupId: undefined,
  });

  const {
    data: attributeGroups,
    getAll: getAttributeGroups,
    isLoading: isLoadingGroups,
  } = useApi<Group>("attributeGroups");
  const {
    data: attributes,
    getAll: getAttributes,
    get: getAttribute,
    isLoading: isLoadingAttributes,
  } = useApi<Attribute>("attributes");

  useEffect(() => {
    getAttributeGroups();
  }, [getAttributeGroups]);

  useEffect(() => {
    if (state.selectedGroupId) {
      getAttributes({ querystringParams: { groupId: state.selectedGroupId } });
    }
  }, [state.selectedGroupId, getAttributes]);

  useEffect(() => {
    async function setGroup(attributeId) {
      const attribute = await getAttribute(attributeId);
      const groupId = attribute.groupId;
      setState((prevState) => ({ ...prevState, selectedGroupId: groupId }));
    }

    if (props.attributeLink) {
      if (props.attributeLink.attributeId) {
        setGroup(props.attributeLink.attributeId);
      } else {
        setState((prevState) => ({ ...prevState, selectedGroupId: undefined }));
      }
    }
  }, [props.attributeLink, getAttribute]);

  const validate = (attributeLink: AttributeLink) => {
    const errors: any = {};

    const attributeIdResult = validateRequired(
      attributeLink.attributeId as string,
      t("entities:attribute")
    );
    if (attributeIdResult != null) {
      errors.attributeId = attributeIdResult;
    }

    return errors;
  };

  if (props.attributeLink == null) {
    return null;
  } else {
    return (
      <Formik
        initialValues={props.attributeLink}
        enableReinitialize={true}
        validate={validate}
        onSubmit={(attributeLink) => {
          props.onSave(attributeLink);
        }}
      >
        {(formikProps) => {
          const {
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            errors,
            isValid,
          } = formikProps;

          return (
            <Modal
              width={700}
              title={t("entities:attribute")}
              visible={true}
              footer={[
                <Button id="btnCancel" key="cancel" onClick={props.onClose}>
                  {t("common:cancel")}
                </Button>,
                <Button
                  id="btnSave"
                  key="save"
                  type="primary"
                  disabled={!isValid}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {t("common:save")}
                </Button>,
              ]}
              onCancel={props.onClose}
            >
              <CmsPageLoader
                loading={isLoadingGroups}
                subTitle={t("common:loadingData")}
              >
                <Layout style={{ background: "#fff" }}>
                  <div key="attributeLinkDetails">
                    <CmsModalForm>
                      <Form.Item
                        label={t("entities:attribute")}
                        validateStatus={
                          !errors.attributeId ? "success" : "error"
                        }
                        help={errors.attributeId}
                        required={true}
                      >
                        <React.Fragment>
                          <TreeSelect
                            id="groupId"
                            treeDefaultExpandAll={true}
                            style={{ width: "40%" }}
                            placeholder={t("properties:group")}
                            onChange={(groupId) => {
                              setFieldValue("groupId", groupId);
                              setFieldValue("attributeId", undefined);
                              setState((prevState) => ({
                                ...prevState,
                                selectedGroupId: groupId,
                              }));
                            }}
                            value={state.selectedGroupId}
                            treeData={convertGroupsForTreeSelect(
                              attributeGroups
                            )}
                          ></TreeSelect>
                          &nbsp;
                          <Select
                            showSearch
                            id="attributeId"
                            style={{ width: "58%" }}
                            placeholder={t("entities:attribute")}
                            loading={isLoadingAttributes}
                            disabled={isLoadingAttributes}
                            filterOption={(input, option) =>
                              option?.props.children
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(attributeId: string) => {
                              const attribute = attributes.find(
                                (attribute) => attribute.id === attributeId
                              );
                              if (attribute) {
                                setFieldValue("attributeName", attribute.name);
                              }
                              setFieldValue("attributeId", attributeId);
                            }}
                            value={values.attributeId}
                          >
                            {_.map(attributes, (value, index) => (
                              <Select.Option key={`${index}`} value={value.id}>
                                {value.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </React.Fragment>
                      </Form.Item>
                      <Form.Item
                        label={t("properties:caption")}
                        validateStatus={!errors.caption ? "success" : "error"}
                        help={errors.caption}
                      >
                        <MultilingualInput
                          mlData={values.caption}
                          onChange={(e) => setFieldValue("caption", e)}
                        />
                      </Form.Item>
                      <Form.Item label={t("properties:defaultValue")}>
                        <Input
                          placeholder={t("properties:defaultValue")}
                          maxLength={50}
                          id="defaultValue"
                          value={values.defaultValue}
                          onChange={handleChange}
                        />
                      </Form.Item>
                      <Form.Item label={t("properties:exportable")}>
                        <Checkbox
                          id="exportable"
                          checked={values.exportable}
                          onChange={(e) =>
                            setFieldValue("exportable", e.target.checked)
                          }
                        />
                      </Form.Item>
                      <Form.Item label={t("common:filterable")}>
                        <Checkbox
                          id="filterable"
                          checked={values.filterable}
                          onChange={(e) =>
                            setFieldValue("filterable", e.target.checked)
                          }
                        />
                      </Form.Item>
                      <Form.Item label={t("properties:required")}>
                        <Checkbox
                          id="isRequired"
                          checked={values.isRequired}
                          onChange={(e) =>
                            setFieldValue("isRequired", e.target.checked)
                          }
                        />
                      </Form.Item>
                    </CmsModalForm>
                  </div>
                </Layout>
              </CmsPageLoader>
            </Modal>
          );
        }}
      </Formik>
    );
  }
};
