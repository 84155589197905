import { useQuery } from "@tanstack/react-query";
import { Field } from "../../models/templates/Field";
import api from "../../services/api";
import { CONST_FIELDS } from "../../utilities/constants/query-contants";

export const getFields = (groupId?: string): Promise<Field[]> => {
  return api.get({
    path: CONST_FIELDS.plural,
    query: groupId ? { groupId: groupId } : undefined,
  });
};

export const useQueryFields = (groupId?: string) => {
  return useQuery<Field[]>([CONST_FIELDS.plural, { groupId }], () =>
    getFields()
  );
};
