import { Breadcrumb } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { AppContext } from "../context/app/context";

interface State {
  component?: { name: string; forRoutes: string[]; routeTo: string };
}

export const CmsBreadcrumb = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { state: contextState, dispatch } = useContext(AppContext);
  const [state, setState] = useState<State>({
    component: undefined,
  });

  const retreiveDocumentTemplateId = React.useCallback(() => {
    return new URLSearchParams(location.search).get("documentTemplateId");
  }, [location.search]);

  const [getDocumentTemplateId, setDocumentTemplateId] = useState("");

  useEffect(() => {
    if (
      retreiveDocumentTemplateId() !== null &&
      retreiveDocumentTemplateId() !== ""
    ) {
      sessionStorage.setItem(
        "retreiveDocumentTemplateId",
        retreiveDocumentTemplateId() as string
      );
      setDocumentTemplateId(retreiveDocumentTemplateId() as string);
    } else {
      setDocumentTemplateId(
        sessionStorage.getItem("retreiveDocumentTemplateId") as string
      );
    }
  }, [setDocumentTemplateId, retreiveDocumentTemplateId]);

  const components = useMemo(
    () => [
      {
        name: t("entities:attributes"),
        forRoutes: ["attributes", "attribute"],
        routeTo: "/attributes",
      },
      {
        name: t("entities:countries"),
        forRoutes: ["countries", "country"],
        routeTo: "/countries",
      },
      {
        name: t("entities:currencies"),
        forRoutes: ["currencies", "currency"],
        routeTo: "/currencies",
      },
      {
        name: t("entities:documentTemplates"),
        forRoutes: ["document-templates", "document-template"],
        routeTo: "/document-templates",
      },
      {
        name: t("entities:documentTypes"),
        forRoutes: ["document-types", "document-type"],
        routeTo: "/document-types",
      },
      {
        name: t("entities:documentSeries"),
        forRoutes: ["document-series", "document-wizard"],
        routeTo: `/document-series?documentTemplateId=${getDocumentTemplateId}`,
      },
      {
        name: t("entities:fields"),
        forRoutes: ["fields", "field"],
        routeTo: "/fields",
      },
      {
        name: t("entities:languages"),
        forRoutes: ["languages", "language"],
        routeTo: "/languages",
      },
      {
        name: t("entities:materialTypes"),
        forRoutes: ["material-types", "material-type"],
        routeTo: "/material-types",
      },
      {
        name: t("entities:organizations"),
        forRoutes: ["organizations", "organization"],
        routeTo: "/organizations",
      },
      {
        name: t("entities:regions"),
        forRoutes: ["regions", "region"],
        routeTo: "/regions",
      },
      {
        name: t("entities:securityFeatures"),
        forRoutes: ["security-features", "security-feature"],
        routeTo: "/security-features",
      },
      {
        name: t("common:translations"),
        forRoutes: ["translations"],
        routeTo: "/translations",
      },
      {
        name: t("common:imageUpload"),
        forRoutes: ["image-upload"],
        routeTo: "/image-upload",
      },
    ],
    [t, getDocumentTemplateId]
  );

  useEffect(() => {
    dispatch({ type: "SET_BREADCRUMB_ITEMS", payload: [] });

    const segments = location.pathname.split("/");
    const component =
      segments.length > 1
        ? components.find(
            (c) => c.forRoutes.indexOf(segments[1].toLowerCase()) > -1
          )
        : undefined;

    setState((prevState) => ({ ...prevState, component }));
  }, [dispatch, location.pathname, components]);

  const items = [] as { title: string; href?: string }[];

  if (state.component) {
    items.push({
      title: state.component.name,
      href: state.component.routeTo,
    });
  }

  contextState.breadcrumbItems.forEach((item) => {
    items.push({
      title: item.name,
    });
  });

  return (
    <Breadcrumb
      style={{ marginBottom: "16px" }}
      separator={"/"}
      items={[{ title: "Home", href: "/" }, ...items]}
    />
  );
};
