import { useTranslation } from "react-i18next";
import { validateName, validateRequired } from "../../../utilities/Validators";
import { DetailsModal } from "../../data/documents/DetailsModal";

export type CurrencySortingOrderName = {
  name: string;
};

export const CurrencySortingOrderModal = ({
  show,
  editIndex,
  data,
  onClose,
  onSave,
}: {
  show: boolean;
  editIndex?: number;
  data: string[];
  onClose: () => void;
  onSave: (value: string[]) => void;
}) => {
  const { t } = useTranslation();

  const processSubmit = (value: CurrencySortingOrderName) => {
    let sortingOrder: string[] = [];

    if (data) {
      const currentOrder = data;

      if (editIndex !== undefined && editIndex !== -1) {
        currentOrder[editIndex] = value.name;
      } else {
        currentOrder.push(value.name);
      }

      sortingOrder = currentOrder;
    } else {
      sortingOrder.push(value.name);
    }

    onSave(sortingOrder);
  };

  const validate = (data: CurrencySortingOrderName) => {
    const errors: any = {};

    const nameCheck =
      validateRequired(data.name, t("properties:name")) ||
      validateName(data.name, t("properties:name"));

    if (nameCheck != null) {
      errors.name = nameCheck;
    }

    return errors;
  };

  const modalData = () => {
    let name = "";
    if (editIndex !== undefined && editIndex !== -1 && data) {
      name = data[editIndex];
    }

    return { name };
  };

  return (
    <DetailsModal
      key={"currencySortingOrderModal"}
      title="currencySortingOrder"
      validate={validate}
      data={modalData()}
      onClose={onClose}
      fields={[{ name: "name", type: "INPUT" }]}
      onSave={processSubmit}
      show={show}
    />
  );
};
