import {
  WarningOutlined,
  UnlockOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { Button, Modal, Select, Tabs } from "antd";
import { Formik } from "formik";
import * as _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { AppContext } from "../../../context/app/context";
import { useApi } from "../../../hooks/useApi";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { EntityType } from "../../../models/data/DC/EntityType";
import { ActionType } from "../../../models/data/DC/ActionType";
import { NotificationType } from "../../../models/data/DC/NotificationType";
import { Notification } from "../../../models/data/DC/Notification";
import { DocumentSeries, DocumentStatus } from "../../../models/data/Document";
import { Entity } from "../../../models/data/Entity";
import { DocumentTemplate } from "../../../models/templates/DocumentTemplate";
import classNames from "classnames";
import { default as config } from "../../../Config";
import dayjs from "dayjs";
import {
  CmsBackButton,
  CmsButton,
  CmsSaveButton,
} from "../../common/ButtonComponents";
import { CmsSelect, CmsForm, CmsFormLayout } from "../../common/FormComponents";
import {
  CmsPageHeader,
  CmsPageLoader,
  CmsTabs,
} from "../../common/PageComponents";
import { AttributeValueEntries } from "./AttributeValueEntries";
import { DocumentDetails } from "./DocumentDetails";
import "./DocumentEdit.scss";
import { DocumentInstructions } from "./DocumentInstructions";
import { Notes } from "./Notes";
import { Views } from "./Views";
import {
  EyeOutlined,
  CheckCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { validateRequired } from "../../../utilities/Validators";
import { DocumentCode } from "../../../models/data/DocumentCode";
import { DOCUMENTTEMPLATE_NAMES } from "../../../utilities/Constants";
import { dateFormat } from "../../../utilities/configs/DocumentStatisticsConfig";
import { useNotification } from "../../../hooks/useNotification";
const { TabPane } = Tabs;

export const updateFormStatusWithSection = (
  currentStatus: any,
  section: string,
  valid: boolean
): any => {
  const newStatus = _.cloneDeep(currentStatus);
  if (!valid && !newStatus.invalidSections.has(section)) {
    newStatus.invalidSections.add(section);
  } else if (valid && newStatus.invalidSections.has(section)) {
    newStatus.invalidSections.delete(section);
  }
  return newStatus;
};

interface State {
  document: DocumentSeries | null;
  documentTemplate: DocumentTemplate | null;
}

export const DocumentEdit = () => {
  const { canUpdate, isAdmin } = useAuthorization("document");
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();
  const history = useHistory();
  const { id: documentId } = useParams<Entity>();

  const { state: contextState, dispatch } = useContext(AppContext);
  const [state, setState] = useState<State>({
    document: null,
    documentTemplate: null,
  });
  const [notificationModal, setNotificationModal] = useState<{
    modal: boolean;
    send: boolean;
  }>({ modal: false, send: false });
  const [dcNotification, setDcNotification] = useState<Notification>({
    action: ActionType.NotSpecified,
    entityId: undefined,
    entityType: EntityType.NotSpecified,
    type: NotificationType.NotSpecified,
  });

  const {
    get: getDocument,
    post: saveDocument,
    isLoading: isLoadingDocument,
    setCustomErrors,
  } = useApi<DocumentSeries>("documentSeries");
  const { get: getDocumentTemplate, isLoading: isLoadingTemplate } =
    useApi<DocumentTemplate>("documentTemplates");
  const { post: createNotification, isLoading: isLoadingNofication } =
    useApi<Notification>("notifications");
  const { get: checkCode } = useApi<DocumentCode>("keesing/CodeGenerator");

  const isLoading = isLoadingTemplate || isLoadingDocument;

  useEffect(() => {
    (async () => {
      if (documentId) {
        setState((prevState) => ({
          ...prevState,
          document: null,
          documentTemplate: null,
        }));

        const document = await getDocument(documentId);
        let template;
        if (document && document.documentTemplateId) {
          template = await getDocumentTemplate(document.documentTemplateId);
        }
        setState((prevState) => ({
          ...prevState,
          document,
          documentTemplate: template,
        }));
      } else {
        history.push("/not-found");
      }
    })();
  }, [getDocument, getDocumentTemplate, documentId, history]);

  useEffect(() => {
    if (state.document?.code) {
      setCustomErrors({
        409: t("errors:duplicateDocument", { code: state.document.code }),
      });
      dispatch({
        type: "SET_BREADCRUMB_ITEMS",
        payload: [{ key: "documents", name: state.document.code }],
      });
    }
  }, [state.document, setCustomErrors, dispatch, t]);

  useEffect(() => {
    const getSafeModeSetting = localStorage.getItem("safeEditMode");
    if (getSafeModeSetting != null) {
      dispatch({
        type: "SET_SAFE_EDIT_MODE",
        payload: isAdmin ? JSON.parse(getSafeModeSetting) === true : true,
      });
    }
  }, [dispatch, isAdmin]);

  const getSectionValidity = (status: any) => {
    let viewsValid = true;
    for (const key of status.invalidSections.keys()) {
      if (key.indexOf("view") === 0) {
        viewsValid = false;
        break;
      }
    }

    return {
      documentDetailsValid: !status.invalidSections.has("documentDetails"),
      documentAttributesValid:
        !status.invalidSections.has("documentAttributes"),
      viewsValid,
    };
  };

  const handleNoficationCreation = async () => {
    let entityType;
    switch (state.documentTemplate?.name) {
      case DOCUMENTTEMPLATE_NAMES.BANKNOTE:
        entityType = EntityType.Banknote;
        break;
      case DOCUMENTTEMPLATE_NAMES.COUNTERFEIT_BANKNOTE:
        entityType = EntityType.CounterfeitBanknote;
        break;
      default:
        entityType = EntityType.Document;
        break;
    }

    const response = await createNotification({
      ...dcNotification,
      entityId: documentId,
      entityType: entityType,
    });

    if (response.isSuccess) {
      notifySuccess(t("notifications:successCreatedNofication"));
      setNotificationModal({ modal: false, send: false });
      setDcNotification((prevState) => ({
        ...prevState,
        action: ActionType.NotSpecified,
        type: NotificationType.NotSpecified,
      }));
    }
  };

  const changeSafeEditingMode = (enabled: boolean) => {
    dispatch({
      type: "SET_SAFE_EDIT_MODE",
      payload: enabled,
    });
    localStorage.setItem("safeEditMode", enabled.toString());

    notifySuccess(
      t(
        `safeEditMode:${
          enabled ? "enabledDescription" : "disabledDescription"
        }`,
        t(`safeEditMode:${enabled ? "enabled" : "disabled"}`)
      )
    );
  };

  const validateCode = async (code: string) => {
    const documentCode = await checkCode(code);
    if (!documentCode.available) {
      return t("validations:inputCodeInUse", {
        value: code,
      });
    }
    return null;
  };

  const validateAsync = async (values) => {
    const errors: any = {};

    const codeResult = validateRequired(values.code, t("properties:code"));
    if (codeResult) {
      errors.code = codeResult;
    } else {
      if (state.document?.code !== values.code) {
        const codeInUse = await validateCode(values.code);
        if (codeInUse) {
          errors.code = codeInUse;
        }
      }
    }

    const documentTypeResult = validateRequired(
      values.documentTypeId,
      t("properties:type")
    );
    if (documentTypeResult) {
      errors.documentTypeId = documentTypeResult;
    }

    const materialTypeResult = validateRequired(
      values.materialTypeId,
      t("properties:materialType")
    );
    if (materialTypeResult) {
      errors.materialTypeId = materialTypeResult;
    }

    return errors;
  };

  const getDocumentCheckerUrl = () => {
    switch (state.documentTemplate?.name) {
      case DOCUMENTTEMPLATE_NAMES.DOUBLE_SIDED:
      case DOCUMENTTEMPLATE_NAMES.MULTI_SIDED:
        return "document";
      case DOCUMENTTEMPLATE_NAMES.COUNTERFEIT_BANKNOTE:
        return "counterfeit";
      case DOCUMENTTEMPLATE_NAMES.BANKNOTE:
        return "banknote";
      default:
        return "";
    }
  };

  const setPublishingDate = (document: DocumentSeries): DocumentSeries => {
    if (
      state.document?.status !== DocumentStatus.ReadyForPublishing &&
      document.status === DocumentStatus.ReadyForPublishing
    ) {
      const attributeLink = state.documentTemplate?.attributeLinks.find(
        (link) => link.attributeName === "Publishing date"
      );

      const index =
        document.attributeValues?.findIndex(
          (attrValue) => attrValue.attributeLinkId === attributeLink?.id
        ) ?? -1;

      const value = dayjs(new Date(Date.now()).toLocaleDateString()).format(
        dateFormat
      );

      if (document.attributeValues) {
        if (index !== -1) {
          if (document.attributeValues[index].value === "") {
            document.attributeValues[index].value = value;
          }
        } else {
          document.attributeValues.push({
            attributeLinkId: attributeLink?.id,
            value,
          });
        }
      }

      return document;
    }

    return document;
  };

  return (
    <React.Fragment>
      <CmsPageLoader
        loading={!state.document && isLoading}
        subTitle={t("common:loadingData")}
      >
        {state.document && state.documentTemplate ? (
          <Formik
            initialValues={state.document}
            initialStatus={{ invalidSections: new Set() }}
            enableReinitialize={true}
            validate={validateAsync}
            onSubmit={async (document) => {
              const response = await saveDocument(setPublishingDate(document));
              if (response.isSuccess) {
                notifySuccess(
                  t("common:successSave", {
                    entity: t("common:document").toLocaleLowerCase(),
                  })
                );

                setState((prevState) => ({
                  ...prevState,
                  document: response.data,
                }));

                if (notificationModal.send) {
                  await handleNoficationCreation();
                }
              }
            }}
          >
            {(formikProps) => {
              const {
                values,
                status,
                dirty,
                setFieldValue,
                setStatus,
                submitForm,
              } = formikProps;

              const {
                documentDetailsValid,
                documentAttributesValid,
                viewsValid,
              } = getSectionValidity(status);

              return (
                <React.Fragment>
                  <Prompt when={dirty} message={t("common:unsavedChanges")} />
                  <CmsPageHeader
                    title="Document"
                    extra={[
                      <CmsBackButton
                        key="back"
                        disabled={isLoadingDocument}
                        onClick={() =>
                          history.push(
                            `/document-series?documentTemplateId=${state.document?.documentTemplateId}`
                          )
                        }
                      />,
                      isAdmin &&
                        state.document?.status === DocumentStatus.Preview && (
                          <Button
                            type="default"
                            key="new"
                            icon={<EyeOutlined />}
                            disabled={isLoadingDocument}
                            onClick={() =>
                              window.open(
                                `${
                                  config.dcBaseUrl
                                }/${getDocumentCheckerUrl()}/${
                                  state.document?.id
                                }`
                              )
                            }
                          />
                        ),
                      isAdmin && (
                        <CmsButton
                          key="saveEditMode"
                          buttonType="default"
                          confirmText={
                            contextState.setSafeEditMode
                              ? t("safeEditMode:disableDescription")
                              : undefined
                          }
                          disabled={isLoadingDocument}
                          onClick={() =>
                            changeSafeEditingMode(!contextState.setSafeEditMode)
                          }
                          className={classNames({
                            safeModeActivated: contextState.setSafeEditMode,
                            safeModeDisabled: !contextState.setSafeEditMode,
                          })}
                          title={t("safeEditMode:description")}
                        >
                          {contextState.setSafeEditMode ? (
                            <LockOutlined />
                          ) : (
                            <UnlockOutlined className="blinker" />
                          )}
                        </CmsButton>
                      ),
                      canUpdate && (
                        <>
                          <Button
                            key="addNotification"
                            disabled={
                              isLoadingDocument ||
                              status.invalidSections.size > 0
                            }
                            type={notificationModal.send ? "primary" : "dashed"}
                            icon={
                              notificationModal.send ? (
                                <CheckCircleOutlined />
                              ) : (
                                <PlusCircleOutlined />
                              )
                            }
                            loading={isLoadingNofication}
                            onClick={() =>
                              setNotificationModal((prevState) => ({
                                ...prevState,
                                modal: true,
                              }))
                            }
                          >
                            {t(
                              `common:${
                                notificationModal.send
                                  ? "addedNotification"
                                  : "addNotification"
                              }`
                            )}
                          </Button>
                          <CmsSaveButton
                            key="save"
                            disabled={
                              isLoadingDocument ||
                              status.invalidSections.size > 0 ||
                              !dirty
                            }
                            loading={isLoadingDocument}
                            onClick={async () => await submitForm()}
                          />
                        </>
                      ),
                    ]}
                  />
                  <CmsTabs destroyInactiveTabPane={true}>
                    <TabPane
                      key="documentDetails"
                      tab={
                        <span
                          className={
                            !documentDetailsValid || !documentAttributesValid
                              ? "error"
                              : undefined
                          }
                        >
                          {t("common:details")}{" "}
                          {!documentDetailsValid || !documentAttributesValid ? (
                            <WarningOutlined />
                          ) : null}
                        </span>
                      }
                    >
                      <DocumentDetails />
                      {state.documentTemplate?.attributeLinks &&
                        values.attributeValues && (
                          <AttributeValueEntries
                            attributeLinks={
                              state.documentTemplate.attributeLinks
                            }
                            attributeValues={values.attributeValues}
                            onChange={(attributeValues) =>
                              setFieldValue("attributeValues", attributeValues)
                            }
                            onValidityChanged={(isValid) =>
                              setStatus(
                                updateFormStatusWithSection(
                                  status,
                                  "documentAttributes",
                                  isValid
                                )
                              )
                            }
                          />
                        )}
                    </TabPane>
                    <TabPane
                      tab={t("common:instructions")}
                      key="texts"
                      disabled={!canUpdate}
                    >
                      <DocumentInstructions
                        documentTemplateType={state.documentTemplate?.name}
                      />
                    </TabPane>
                    <TabPane
                      key="views"
                      tab={
                        <span className={!viewsValid ? "error" : undefined}>
                          {t("common:views")}{" "}
                          {!viewsValid ? <WarningOutlined /> : null}
                        </span>
                      }
                    >
                      <Views
                        viewTemplates={
                          state.documentTemplate?.viewTemplates || []
                        }
                      />
                    </TabPane>
                    <TabPane tab={t("common:notesBanknotes")} key="notes">
                      <Notes />
                    </TabPane>
                  </CmsTabs>
                </React.Fragment>
              );
            }}
          </Formik>
        ) : null}
        {canUpdate && notificationModal.modal && (
          <Modal
            width={400}
            title={t("common:createDcNotification", {
              for: state.document?.code,
            })}
            open={true}
            closable={true}
            onCancel={() =>
              setNotificationModal((prevState) => ({
                ...prevState,
                modal: false,
              }))
            }
            footer={null}
          >
            <div>
              <div>
                <CmsForm {...CmsFormLayout.twocolumn}>
                  <CmsSelect
                    readOnly={!canUpdate}
                    id="actionType"
                    label={t("properties:actionType")}
                    placeholder={t("properties:actionType")}
                    allowClear={false}
                    value={dcNotification?.action}
                    onChange={(action: ActionType) =>
                      setDcNotification((prevState) => ({
                        ...prevState,
                        action: action,
                      }))
                    }
                  >
                    <Select.Option value={ActionType.NotSpecified}>
                      {t("notifications:notSpecified")}
                    </Select.Option>
                    <Select.Option value={ActionType.DocumentEdited}>
                      {t("notifications:documentEdited")}
                    </Select.Option>
                    <Select.Option value={ActionType.DocumentExpected}>
                      {t("notifications:documentExpected")}
                    </Select.Option>
                    <Select.Option value={ActionType.DocumentOutOfCirculation}>
                      {t("notifications:documentOutOfCirculation")}
                    </Select.Option>
                    <Select.Option
                      value={ActionType.DocumentGoingOutOfCirculation}
                    >
                      {t("notifications:documentGoingOutOfCirculation")}
                    </Select.Option>
                    <Select.Option value={ActionType.DocumentPublished}>
                      {t("notifications:documentPublished")}
                    </Select.Option>
                    <Select.Option value={ActionType.DocumentOutmoded}>
                      {t("notifications:documentOutmoded")}
                    </Select.Option>
                  </CmsSelect>
                  <CmsSelect
                    readOnly={!canUpdate}
                    id="notificationType"
                    label={t("properties:notificationType")}
                    placeholder={t("properties:notificationType")}
                    allowClear={false}
                    value={dcNotification?.type}
                    onChange={(type: NotificationType) =>
                      setDcNotification((prevState) => ({
                        ...prevState,
                        type: type,
                      }))
                    }
                  >
                    <Select.Option value={NotificationType.NotSpecified}>
                      {t("notifications:notSpecified")}
                    </Select.Option>
                    <Select.Option value={NotificationType.Info}>
                      {t("notifications:info")}
                    </Select.Option>
                  </CmsSelect>
                </CmsForm>
              </div>
              <h5>{t("notifications:attention")}</h5>
              <p style={{ fontSize: "12px" }}>
                {t("notifications:saveMessage")}
              </p>
              <div style={{ display: "block" }}>
                <CmsButton
                  buttonType="cancel"
                  style={{ marginRight: "10px" }}
                  loading={isLoadingDocument}
                  onClick={() => {
                    setNotificationModal(() => ({
                      modal: false,
                      send: false,
                    }));
                    setDcNotification(() => ({
                      ...dcNotification,
                      type: NotificationType.NotSpecified,
                      action: ActionType.NotSpecified,
                    }));
                  }}
                />
                <CmsButton
                  buttonType="save"
                  style={{ marginRight: "10px" }}
                  disabled={
                    dcNotification.type === NotificationType.NotSpecified ||
                    dcNotification.action === ActionType.NotSpecified
                  }
                  loading={isLoadingDocument}
                  onClick={() =>
                    setNotificationModal(() => ({
                      modal: false,
                      send: true,
                    }))
                  }
                />
                <CmsButton
                  buttonType="send"
                  style={{ marginRight: "10px" }}
                  disabled={
                    dcNotification.type === NotificationType.NotSpecified ||
                    dcNotification.action === ActionType.NotSpecified
                  }
                  loading={isLoadingDocument}
                  onClick={async () => {
                    await handleNoficationCreation();
                    setNotificationModal(() => ({
                      modal: false,
                      send: false,
                    }));
                  }}
                />
              </div>
            </div>
          </Modal>
        )}
      </CmsPageLoader>
    </React.Fragment>
  );
};
