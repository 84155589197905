import { Form, Input, Select, TreeSelect } from "antd";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { AppContext } from "../../../context/app/context";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import {
  AttributeSchema,
  getDefaultSchema,
  IListSchema,
} from "../../../models/InputComponentData";
import {
  Attribute,
  AttributeDataType,
} from "../../../models/templates/Attribute";
import { convertGroupsForTreeSelect } from "../../../utilities/GroupHelper";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import { CmsForm } from "../../common/FormComponents";
import { MultilingualInput } from "../../common/MultilingualInput";
import { CmsPageHeader, CmsPageLoader } from "../../common/PageComponents";
import { AttributeInputSchema } from "./AttributeInputSchema";
import {
  useQueryAttribute,
  useSaveAttribute,
} from "../../../queries/attributes/detail";
import { useQueryAttributeGroups } from "../../../queries/attributeGroups/lists";
import { ValidateAttribute } from "../../../utilities/helpers/attribute-helper";

export const AttributeDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("attribute");
  const { push } = useHistory();
  const { id: attributeId } = useParams<Entity>();
  const { dispatch } = React.useContext(AppContext);

  const {
    data: attribute,
    isLoading: isLoadingAttribute,
    refetch: refetchAttribute,
  } = useQueryAttribute(attributeId);
  const { mutateAsync: saveAttribute, isLoading: isSavingAttribute } =
    useSaveAttribute();
  const { data: attributeGroups, isLoading: isLoadingAttributeGroups } =
    useQueryAttributeGroups();

  useEffect(() => {
    if (attribute) {
      dispatch({
        type: "SET_CUSTOM_DUPLICATE_ERROR_MESSAGE",
        payload: t("errors:duplicateAttribute", { name: attribute.name }),
      });
      dispatch({
        type: "SET_BREADCRUMB_ITEMS",
        payload: [
          {
            key: "attribute",
            name: attribute.name ?? t("common:new"),
          },
        ],
      });
    }
  }, [attribute, dispatch, t]);

  const noDataAny = (!attribute && !!attributeId) || !attributeGroups;

  const isLoadingAny =
    (isLoadingAttribute && !!attributeId) ||
    isSavingAttribute ||
    isLoadingAttributeGroups;

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <Formik
      initialValues={attribute ?? new Attribute()}
      enableReinitialize={true}
      validate={(attribute: Attribute) => ValidateAttribute(attribute, t)}
      onSubmit={async (attribute: Attribute) => {
        await saveAttribute(attribute);
        !attribute.id ? push(`/attributes`) : await refetchAttribute();
      }}
    >
      {(formikProps) => {
        const {
          values,
          handleChange,
          setFieldValue,
          handleSubmit,
          errors,
          isValid,
          dirty,
        } = formikProps;

        return (
          <React.Fragment>
            <Prompt when={dirty} message={t("common:unsavedChanges")}></Prompt>
            <CmsPageHeader
              title={t("entities:attribute")}
              extra={[
                <CmsBackButton
                  key="back"
                  disabled={isLoadingAny}
                  onClick={() => push(`/attributes`)}
                />,
                !!canUpdate && (
                  <CmsSaveButton
                    key="save"
                    disabled={!isValid || isLoadingAny}
                    loading={isLoadingAny}
                    onClick={() => handleSubmit()}
                  />
                ),
              ]}
            />
            <CmsForm>
              <Form.Item
                label={t("properties:group")}
                validateStatus={!errors.groupId ? "success" : "error"}
                help={errors.groupId}
                required={true}
              >
                <TreeSelect
                  style={{ width: "100%", marginTop: "5px" }}
                  disabled={!canUpdate || isLoadingAny}
                  placeholder={t("properties:group")}
                  treeDefaultExpandAll={true}
                  treeData={convertGroupsForTreeSelect(attributeGroups)}
                  value={values.groupId}
                  id="groupId"
                  onChange={(e) => setFieldValue("groupId", e)}
                />
              </Form.Item>
              <Form.Item
                label={t("properties:type")}
                validateStatus={!errors.dataType ? "success" : "error"}
                help={errors.dataType}
                required={true}
              >
                <Select
                  showSearch
                  style={{ width: "100%", marginTop: "5px" }}
                  disabled={!canUpdate || isLoadingAny}
                  placeholder={t("properties:type")}
                  filterOption={(input, option) =>
                    option?.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => {
                    switch (e as AttributeDataType) {
                      case AttributeDataType.YesNo:
                        setFieldValue("defaultValue", "No");
                        break;
                      case AttributeDataType.Number:
                        setFieldValue("defaultValue", 0);
                        break;
                    }
                    setFieldValue(
                      "dataTypeSchema",
                      getDefaultSchema(e as AttributeDataType)
                    );
                    setFieldValue("dataType", e);
                  }}
                  value={values.dataType}
                >
                  <Select.Option value="COUNTRY">
                    {t("entities:country")}
                  </Select.Option>
                  <Select.Option value="PROVINCE">
                    {t("entities:province")}
                  </Select.Option>
                  <Select.Option value="CURRENCY">
                    {t("entities:currency")}
                  </Select.Option>
                  <Select.Option value="DATE_TIME">
                    {t("properties:attributeTypeDateTime")}
                  </Select.Option>
                  <Select.Option value="DOCUMENT_TYPE">
                    {t("entities:documentType")}
                  </Select.Option>
                  <Select.Option value="LANGUAGE">
                    {t("entities:language")}
                  </Select.Option>
                  <Select.Option value="LIST">
                    {t("properties:attributeTypeList")}
                  </Select.Option>
                  <Select.Option value="MATERIAL_TYPE">
                    {t("entities:materialType")}
                  </Select.Option>
                  <Select.Option value="MULTILINGUAL_TEXT">
                    {t("properties:attributeTypeMultilingualText")}
                  </Select.Option>
                  <Select.Option value="NUMBER">
                    {t("properties:attributeTypeNumber")}
                  </Select.Option>
                  <Select.Option value="ORGANIZATION">
                    {t("entities:organization")}
                  </Select.Option>
                  <Select.Option value="PRODUCT">
                    {t("entities:product")}
                  </Select.Option>
                  <Select.Option value="REGION">
                    {t("entities:region")}
                  </Select.Option>
                  <Select.Option value="TEXT">
                    {t("properties:attributeTypeText")}
                  </Select.Option>
                  <Select.Option value="YES_NO">
                    {t("properties:attributeTypeYesNo")}
                  </Select.Option>
                  <Select.Option value="OBJECT">
                    {t("properties:attributeTypeObject")}
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={t("properties:name")}
                validateStatus={!errors.name ? "success" : "error"}
                help={errors.name}
                required={true}
              >
                <Input
                  style={{ marginTop: "5px" }}
                  placeholder={t("properties:name")}
                  disabled={isLoadingAny}
                  readOnly={!canUpdate}
                  maxLength={100}
                  value={values.name}
                  id="name"
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item
                label={t("properties:caption")}
                validateStatus={!errors.caption ? "success" : "error"}
                help={errors.caption}
              >
                <MultilingualInput
                  disabled={isLoadingAny}
                  readOnly={!canUpdate}
                  mlData={values.caption}
                  onChange={(e) => setFieldValue("caption", e)}
                />
              </Form.Item>
              <Form.Item
                label={t("properties:description")}
                validateStatus={!errors.description ? "success" : "error"}
                help={errors.description}
              >
                <MultilingualInput
                  disabled={isLoadingAny}
                  readOnly={!canUpdate}
                  mlData={values.description}
                  onChange={(e) => setFieldValue("description", e)}
                />
              </Form.Item>

              <AttributeInputSchema
                dataType={values.dataType as AttributeDataType}
                dataTypeSchema={values.dataTypeSchema as AttributeSchema}
                updateDefaultValue={(value) =>
                  setFieldValue("defaultValue", value)
                }
                updateSchemaValue={(field, value) =>
                  setFieldValue(`dataTypeSchema.${field}`, value)
                }
                addListItem={() => {
                  const items = (values.dataTypeSchema as IListSchema).items;
                  items.push({ code: "", name: {} });
                  setFieldValue("dataTypeSchema.items", items);
                }}
                removeListItem={(index) => {
                  const items = (values.dataTypeSchema as IListSchema).items;
                  items.splice(index, 1);
                  setFieldValue("dataTypeSchema.items", items);
                }}
                readonly={!canUpdate}
                disabled={isLoadingAny}
                defaultValue={values.defaultValue as string}
              />
            </CmsForm>
          </React.Fragment>
        );
      }}
    </Formik>
  );
};
