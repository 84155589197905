import React from "react";
import { Button, Layout, Modal } from "antd";
import config from "../../../Config";
import { useState } from "react";
import { SecurityFeatureSelection } from "../../../models/data/SecurityFeatureSelection";
import { CmsCancelButton, CmsSaveButton } from "../../common/ButtonComponents";
import { useTranslation } from "react-i18next";
import LassoCrop from "./SecurityFeatureSelectionCrop/LassoCrop";
import RectangleCrop from "./SecurityFeatureSelectionCrop/RectangleCrop";
import { SelectionType } from "../../../models/data/SelectionType";
import { Crop } from "react-image-crop";
import { Point } from "react-lasso-select/lib/helpers";
import { ConvertPolygon } from "../../../utilities/PolygonHelper";
import { ConvertToPercentCrop } from "../../../utilities/CropHelper";
import styles from "./CopyInstructionsModal.module.scss";

const { Sider, Content } = Layout;

interface Props {
  fileId: string;
  featureName: string;
  selection: SecurityFeatureSelection;
  onClose?(): void;
  onSave(selection: SecurityFeatureSelection): void;
}

export const SecurityFeatureSelectionEditor = (props: Props) => {
  const { t } = useTranslation();

  const [selectionType, setSelectionType] = useState<SelectionType>({
    type: props.selection.shapeType ? props.selection.shapeType : null,
    selection: {
      unit: "%",
      height: props.selection.height as number,
      width: props.selection.width as number,
      x: props.selection.x as number,
      y: props.selection.y as number,
    },
  });

  const [croppedImage, setCroppedImage] = useState<string | undefined>();
  const [imageSize, setImageSize] = useState<{
    width: number;
    height: number;
    natWidth: number;
    natHeight: number;
  }>({ width: 0, height: 0, natWidth: 0, natHeight: 0 });

  const handleCroppedImage = (croppedImage) => {
    setCroppedImage(croppedImage);
  };
  const handleImageSize = (imageSize) => {
    setImageSize(imageSize);
  };
  const handleSelection = (crop) => {
    setSelectionType({
      type: selectionType.type,
      selection: crop,
    });
  };

  const src = `${config.apiUrl}file/${props.fileId}?imageSize=XL`;

  const onSaveSelection = () => {
    if (!croppedImage) return;

    let SecuritySelectionCompile = {
      ...props.selection,
      shapeType: selectionType.type,
    } as SecurityFeatureSelection;

    switch (selectionType.type) {
      case "RECTANGLE":
        const rectangle = selectionType.selection as Crop;
        const convert = ConvertToPercentCrop(
          imageSize.width,
          imageSize.height,
          rectangle
        );
        SecuritySelectionCompile.height = (convert.height ?? 0) / 100;
        SecuritySelectionCompile.width = (convert.width ?? 0) / 100;
        SecuritySelectionCompile.y = (convert.y ?? 0) / 100;
        SecuritySelectionCompile.x = (convert.x ?? 0) / 100;
        break;
      case "POLYGON":
        const polygon = selectionType.selection as Point[];
        SecuritySelectionCompile.height = undefined;
        SecuritySelectionCompile.width = undefined;
        SecuritySelectionCompile.y = undefined;
        SecuritySelectionCompile.x = undefined;
        SecuritySelectionCompile.points = ConvertPolygon(
          polygon,
          "RELATIVE",
          imageSize
        );
        break;
      default:
        break;
    }

    props.onSave(SecuritySelectionCompile);
  };

  return (
    <Modal
      width={(imageSize.width > 0 ? imageSize.width : 600) + 400}
      style={{ height: "750px", maxHeight: "80%" }}
      title={props.featureName}
      open={true}
      closable={true}
      onCancel={() => props.onClose && props.onClose()}
      footer={[
        <CmsCancelButton
          key="cancel"
          onClick={() => props.onClose && props.onClose()}
        />,
        <CmsSaveButton
          key="save"
          disabled={!croppedImage}
          onClick={() => onSaveSelection()}
        />,
      ]}
    >
      <Layout>
        <Sider
          width={"auto"}
          style={{
            overflow: "hidden",
            background: "#fff",
            paddingRight: "16px",
          }}
        >
          <div className={"image"}>
            {selectionType.type !== null && (
              <>
                {selectionType.type === "RECTANGLE" && (
                  <RectangleCrop
                    src={src}
                    t={t}
                    onCroppedImage={handleCroppedImage}
                    onImageSize={handleImageSize}
                    onSelection={handleSelection}
                    preSelection={{
                      unit: "%",
                      x: props.selection.x as number,
                      y: props.selection.y as number,
                      width: props.selection.width as number,
                      height: props.selection.height as number,
                    }}
                  />
                )}
                {selectionType.type === "POLYGON" && (
                  <LassoCrop
                    src={src}
                    t={t}
                    onCroppedImage={handleCroppedImage}
                    onImageSize={handleImageSize}
                    onSelection={handleSelection}
                    preSelection={props.selection.points}
                  />
                )}
              </>
            )}
          </div>
        </Sider>
        <Content style={{ background: "#fff", paddingLeft: "10px" }}>
          <div className={styles["button-bar"]}>
            <Button
              type={selectionType.type === "RECTANGLE" ? "primary" : "default"}
              onClick={() =>
                setSelectionType({
                  type: "RECTANGLE",
                  selection: null,
                } as SelectionType)
              }
            >
              Rectangle
            </Button>
            <Button
              type={selectionType.type === "POLYGON" ? "primary" : "default"}
              style={{ marginLeft: "5px" }}
              onClick={() =>
                setSelectionType({
                  type: "POLYGON",
                  selection: null,
                } as SelectionType)
              }
            >
              Polygon
            </Button>
          </div>
          <h3>{t("common:preview")}</h3>
          <img
            alt=""
            src={
              croppedImage ? croppedImage : "/assets/images/keesing-logo.jpg"
            }
            style={{
              objectFit: "contain",
              maxWidth: "100%",
              margin: "5px",
              maxHeight: "750px",
            }}
            crossOrigin={"anonymous"}
          />
        </Content>
      </Layout>
    </Modal>
  );
};
