import { MultilingualInputData } from "../MultilingualInputData";
import { AttributeSchema } from "../InputComponentData";

export enum AttributeDataType {
  None = "NONE",
  Text = "TEXT",
  MultiLingualText = "MULTILINGUAL_TEXT",
  Number = "NUMBER",
  YesNo = "YES_NO",
  DateTime = "DATE_TIME",
  List = "LIST",
  Currency = "CURRENCY",
  Country = "COUNTRY",
  Province = "PROVINCE",
  Language = "LANGUAGE",
  Region = "REGION",
  Organization = "ORGANIZATION",
  Product = "PRODUCT",
  DocumentType = "DOCUMENT_TYPE",
  MaterialType = "MATERIAL_TYPE",
  Object = "OBJECT",
}

export class Attribute {
  public id?: string;
  public groupId?: string;
  public name?: string;
  public dataType?: AttributeDataType;
  public caption: MultilingualInputData = {};
  public description: MultilingualInputData = {};
  public defaultValue?: string;
  public filterable?: boolean;
  public dataTypeSchema?: AttributeSchema;

  public withGroup(groupId: string) {
    this.groupId = groupId;
    return this;
  }
}
