import { Tag } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Organization } from "../../../models/reference_lists/Organization";
import { CmsCreateButton } from "../../common/ButtonComponents";
import {
  CmsPageHeader,
  CmsSearch,
  CmsTable,
} from "../../common/PageComponents";
import { AppContext } from "../../../context/app/context";
import { useQueryOrganizations } from "../../../queries/organizations/lists";
import { useDeleteOrganization } from "../../../queries/organizations/detail";

export const OrganizationList = () => {
  const { t } = useTranslation();
  const { canCreate, canDelete, canUpdate, canView } =
    useAuthorization("organization");
  const { push } = useHistory();
  const { dispatch } = useContext(AppContext);
  const {
    data: organizations,
    isLoading: isLoadingOrganizations,
    refetch: refetchOrganizations,
  } = useQueryOrganizations();
  const { mutateAsync: deleteOrganization, isLoading: isDeletingOrganization } =
    useDeleteOrganization();

  const [organizationsFilter, setOrganizationsFilter] = useState<
    Organization[]
  >(organizations ?? []);

  useEffect(() => {
    if (organizations) {
      setOrganizationsFilter(organizations);
    }
  }, [organizations]);

  useEffect(() => {
    if (organizationsFilter) {
      dispatch({
        type: "SET_CUSTOM_DUPLICATE_ERROR_MESSAGE",
        payload: t("errors:organizationLinked"),
      });
    }
  }, [organizationsFilter, dispatch, t]);

  const search = (searchText: any) => {
    if (organizations) {
      const search = searchText.toLowerCase();
      const filteredOrganizations = organizations.filter((organization) => {
        const name = organization.name && organization.name.toLowerCase();
        return name && name.includes(search);
      });

      setOrganizationsFilter(filteredOrganizations);
    }
  };

  return (
    <React.Fragment>
      <CmsPageHeader
        title={t("entities:organizations")}
        extra={[
          <CmsSearch key="search" onChange={search} />,
          canCreate ? (
            <CmsCreateButton
              key="create"
              onClick={() => push(`/organization`)}
            />
          ) : null,
        ]}
      />
      <CmsTable
        loading={isLoadingOrganizations || isDeletingOrganization}
        dataSource={organizationsFilter}
        rowKey="id"
        columns={[
          { title: t("properties:name"), dataIndex: "name" },
          {
            title: t("properties:type"),
            dataIndex: "type",
            render: (types) =>
              types &&
              types.split(",").map((type) => {
                let value = type.trim();

                switch (value) {
                  case "PRODUCER":
                    value = t("properties:organizationTypeProducer");
                    break;
                  case "ISSUER":
                    value = t("properties:organizationTypeIssuer");
                    break;
                  case "REFERENCE":
                    value = t("properties:organizationTypeReference");
                    break;
                  default:
                    value = t("common:unknown");
                }

                return (
                  <Tag color={"blue"} key={value}>
                    {value}
                  </Tag>
                );
              }),
          },
          {
            title: t("properties:productType"),
            dataIndex: "productType",
            render: (productTypes) =>
              productTypes &&
              productTypes.split(",").map((productType) => {
                let value = productType.trim();

                switch (value) {
                  case "DOCUMENT":
                    value = t("properties:productTypeDocument");
                    break;
                  case "BANKNOTE":
                    value = t("properties:productTypeBanknote");
                    break;
                  default:
                    value = t("common:unknown");
                }

                return (
                  <Tag color={"geekblue"} key={value}>
                    {value}
                  </Tag>
                );
              }),
          },
          { title: t("properties:contact"), dataIndex: "contact.name" },
          {
            title: t("properties:phoneNumber"),
            dataIndex: "contact.telephoneWork",
          },
        ]}
        onEdit={
          canUpdate || canView ? (id) => push(`/organization/${id}`) : undefined
        }
        onDelete={
          canDelete
            ? async (id) => {
                await deleteOrganization(id);
                await refetchOrganizations();
              }
            : undefined
        }
      />
    </React.Fragment>
  );
};
