import { Modal, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../../hooks/useApi";
import {
  DocumentSeries,
  DocumentStatus,
} from "../../../../models/data/Document";
import { Country } from "../../../../models/reference_lists/Country";
import { DocumentType } from "../../../../models/reference_lists/DocumentType";
import { MaterialType } from "../../../../models/reference_lists/MaterialType";
import { DocumentTemplate } from "../../../../models/templates/DocumentTemplate";
import styles from "./DocumentWizard.module.scss";
import { SelectedViewAndImageTemplate } from "./interfaces";
import { StepProperties } from "./StepProperties";
import { StepSummary } from "./StepSummary";
import { CreationMode, StepTemplate } from "./StepTemplate";
import { StepViewsAndImages } from "./StepViewsAndImages";
import { AttributeValue } from "../../../../models/data/AttributeValue";
import { View } from "../../../../models/data/View";
import { Region } from "../../../../models/reference_lists/Region";
import { Image } from "../../../../models/data/Image";
import { getAttributeLink } from "../../../../utilities/configs/AttributeConfig";
import {
  DOCUMENTTEMPLATE_NAMES,
  ERROR_CODES,
} from "../../../../utilities/Constants";
import { useNotification } from "../../../../hooks/useNotification";

enum WizardSteps {
  Template = 0,
  Properties = 1,
  ViewsAndImages = 2,
  Summary = 3,
}

interface State {
  currentStep: WizardSteps;
  creationMode: CreationMode;
  documentTemplate?: DocumentTemplate;
  document?: DocumentSeries;
  selectedTemplates: SelectedViewAndImageTemplate[];
  code?: string;
  countryId?: string;
  regionId?: string;
  documentTypeId?: string;
  materialTypeId?: string;
  isSavingDocument: boolean;
}

export const DocumentWizard = (props: {
  onClose: (newDocument?: DocumentSeries) => void;
}) => {
  const [state, setState] = useState<State>({
    currentStep: WizardSteps.Template,
    creationMode: CreationMode.NewDocument,
    documentTemplate: undefined,
    document: undefined,
    selectedTemplates: [],
    code: undefined,
    countryId: undefined,
    regionId: undefined,
    documentTypeId: undefined,
    materialTypeId: undefined,
    isSavingDocument: false,
  });

  const { t } = useTranslation();
  const { notifyError } = useNotification();

  const {
    data: documentTemplates,
    getAll: getDocumentTemplates,
    get: getDocumentTemplate,
  } = useApi<DocumentTemplate>("documentTemplates");
  const { data: countries, getAll: getCountries } =
    useApi<Country>("countries");
  const { data: regions, getAll: getRegions } = useApi<Region>("regions");
  const { data: documentTypes, getAll: getDocumentTypes } =
    useApi<DocumentType>("documentTypes");
  const { data: materialTypes, getAll: getMaterialTypes } =
    useApi<MaterialType>("materialTypes");
  const { get: getDocument, post: saveDocument } =
    useApi<DocumentSeries>("documentSeries");

  useEffect(() => {
    (async () => {
      switch (state.currentStep) {
        case WizardSteps.Template:
          if (documentTemplates.length === 0) {
            await getDocumentTemplates();
          }
          break;
        case WizardSteps.Properties:
          if (
            countries.length === 0 &&
            documentTypes.length === 0 &&
            materialTypes.length === 0
          ) {
            await getCountries({
              querystringParams: {
                expand:
                  "CountryCurrencies,CountryRegions.Region.RegionCurrencies",
              },
            });
            await getRegions();
            await getDocumentTypes();
            await getMaterialTypes();
          }
          break;
      }
    })();
  }, [
    state.currentStep,
    documentTemplates.length,
    countries.length,
    documentTypes.length,
    materialTypes.length,
    getDocumentTemplates,
    getCountries,
    getRegions,
    getDocumentTypes,
    getMaterialTypes,
  ]);

  const createDocument = async () => {
    setState((prevState) => ({ ...prevState, isSavingDocument: true }));
    await handleDocumentSubmit();
    setState((prevState) => ({ ...prevState, isSavingDocument: false }));
  };

  const handleDocumentSubmit = async () => {
    const document = await handleDocumentCreation();
    if (document !== null) {
      switch (state.creationMode) {
        case CreationMode.NewDocument:
          const instanceId = document.instances?.[0].id;

          if (state.documentTemplate?.attributeLinks) {
            if (state.countryId) {
              const LinkId = getAttributeLink(
                "issuedByCountry",
                state.documentTemplate?.attributeLinks
              );

              if (LinkId) {
                document.attributeValues?.push({
                  attributeLinkId: LinkId,
                  value: state.countryId,
                });
              }
            }

            if (state.regionId) {
              const LinkId = getAttributeLink(
                "issuedByRegion",
                state.documentTemplate?.attributeLinks
              );

              if (LinkId) {
                document.attributeValues?.push({
                  attributeLinkId: LinkId,
                  value: state.regionId,
                });
              }
            }

            if (
              state.documentTemplate?.name &&
              [
                DOCUMENTTEMPLATE_NAMES.COUNTERFEIT_BANKNOTE,
                DOCUMENTTEMPLATE_NAMES.BANKNOTE,
              ].includes(state.documentTemplate.name)
            ) {
              if (state.countryId) {
                const country = countries.find(
                  (value) => value.id === state.countryId
                );

                if (country?.currencyIds && country?.currencyIds.length > 0) {
                  const LinkId = getAttributeLink(
                    "currency",
                    state.documentTemplate?.attributeLinks
                  );

                  document.attributeValues?.push({
                    attributeLinkId: LinkId,
                    value: country?.currencyIds[0],
                  });
                }
              }

              if (state.regionId) {
                const region = regions.find(
                  (value) => value.id === state.regionId
                );

                if (region?.currencyIds && region?.currencyIds.length > 0) {
                  const LinkId = getAttributeLink(
                    "currency",
                    state.documentTemplate?.attributeLinks
                  );

                  document.attributeValues?.push({
                    attributeLinkId: LinkId,
                    value: region?.currencyIds[0],
                  });
                }
              }
            }
          }

          document.views = state.selectedTemplates.map((template) => {
            const viewTemplate = state.documentTemplate?.viewTemplates.find(
              (viewTemplate) => viewTemplate.id === template.viewTemplateId
            );

            let attributeValues = [] as AttributeValue[];

            if (
              viewTemplate?.name === "FRONT" ||
              viewTemplate?.name === "BACK"
            ) {
              const LinkId = getAttributeLink(
                "pageNumber",
                viewTemplate.attributeLinks
              );

              if (LinkId) {
                attributeValues.push({
                  attributeLinkId: LinkId,
                  value: viewTemplate.name === "FRONT" ? "00/01" : "00/02",
                });
              }
            }

            return {
              viewTemplateId: viewTemplate?.id ?? "",
              exportable: true,
              deepZoomExportable: true,
              images: template.imageTemplateIds?.map((imageTemplateId) => ({
                instanceId,
                imageTemplateId: imageTemplateId,
                exportable: true,
              })) as Image[],
              attributeValues,
            } as View;
          });
          break;

        case CreationMode.CopyDocument:
          document.views = state.selectedTemplates.map((template) => {
            const view =
              state.document?.views.find(
                (view) => view.viewTemplateId === template.viewTemplateId
              ) ?? ({ viewTemplateId: template.viewTemplateId } as View);

            const {
              id,
              documentId,
              instructions,
              attributeValues,
              images,
              ...newView
            } = view;

            return {
              ...newView,
              instructions: instructions?.map(({ caption, text, order }) => ({
                caption,
                text,
                order,
              })),
              attributeValues: attributeValues?.map(
                ({ id, documentId, ...attributeValue }) => attributeValue
              ),
              images: template.imageTemplateIds?.map((imageTemplateId) => {
                const image = images?.find(
                  (image) => image.imageTemplateId === imageTemplateId
                ) ?? { imageTemplateId };

                const {
                  id,
                  instanceId,
                  viewId,
                  fileId,
                  fieldValues,
                  securityFeatureSelections,
                  instructions,
                  ...newImage
                } = image;

                return {
                  ...newImage,
                  instanceId: document.instances?.[0].id,
                  instructions: instructions?.map(
                    ({ caption, text, order }) => ({
                      caption,
                      text,
                      order,
                    })
                  ),
                  securityFeatureSelections: securityFeatureSelections?.map(
                    ({
                      id,
                      imageId,
                      instructions,
                      attributeValues,
                      images: securityFeatureSelectionImages,
                      ...securityFeature
                    }) => ({
                      ...securityFeature,
                      instructions: instructions?.map(
                        ({ caption, text, order }) => ({
                          caption,
                          text,
                          order,
                        })
                      ),
                      attributeValues: attributeValues?.map(
                        ({ id, documentId, ...attributeValue }) =>
                          attributeValue
                      ),
                      images: securityFeatureSelectionImages?.map(
                        (_, index) => ({
                          fileId: null,
                          order: index,
                        })
                      ),
                    })
                  ),
                };
              }),
            };
          });
          break;
      }

      const result = await saveDocument(document);
      if (result.isSuccess) {
        props.onClose(result.data);
      }
      return null;
    }

    notifyError(
      ERROR_CODES.SERVER_ERROR,
      t("common:errorSave", {
        entity: t("common:document").toLocaleLowerCase(),
      })
    );
  };

  const handleDocumentCreation = async (): Promise<DocumentSeries | null> => {
    let width: number | undefined;
    let height: number | undefined;
    switch (state.documentTemplate?.name) {
      case DOCUMENTTEMPLATE_NAMES.DOUBLE_SIDED:
        width = 85;
        height = 54;
        break;
      case DOCUMENTTEMPLATE_NAMES.MULTI_SIDED:
        width = 88;
        height = 125;
        break;
      default:
        break;
    }

    let newDocument: DocumentSeries = {
      documentTemplateId: state.documentTemplate?.id,
      documentTypeId: state.documentTypeId,
      materialTypeId: state.materialTypeId,
      exportable: true,
      code: state.code,
      width,
      height,
      status: DocumentStatus.New,
      instances: [{}],
      views: [],
    };

    if (state.creationMode === CreationMode.CopyDocument && state.document) {
      const { id, code, views, notes, ...copyDocument } = state.document;
      newDocument = {
        ...newDocument,
        ...copyDocument,
        instances: state.document?.instances?.map(
          ({ id, documentId, ...instance }) => instance
        ),
        instructions: state.document?.instructions?.map(
          ({ caption, text, order }) => ({ caption, text, order })
        ),
        attributeValues: state.document?.attributeValues?.map(
          ({ id, documentId, ...attributeValue }) => attributeValue
        ),
      };
    }

    const result = await saveDocument(newDocument);
    if (result.isSuccess) {
      return result.data as DocumentSeries;
    }
    return null;
  };

  return (
    <Modal
      width={1000}
      title={t("common:newDocumentSeries")}
      open={true}
      footer={null}
      onCancel={() => props.onClose()}
    >
      <div className={styles["wizard"]}>
        <Steps
          current={state.currentStep}
          direction="vertical"
          className={styles["stepper"]}
        >
          <Steps.Step title={t("common:template")} />
          <Steps.Step title={t("common:properties")} />
          <Steps.Step title={t("common:viewsAndImages")} />
          <Steps.Step title={t("common:summary")} />
        </Steps>
        <div className={styles["step"]}>
          {state.currentStep === WizardSteps.Template && (
            <StepTemplate
              documentTemplates={documentTemplates}
              documentTemplateId={state.documentTemplate?.id}
              documentId={state.document?.id}
              documentCode={state.document?.code}
              creationMode={state.creationMode}
              onNext={async (creationMode, documentTemplateId, documentId) => {
                let document: DocumentSeries;
                let documentTemplate: DocumentTemplate;
                let changed = false;
                let additional: Partial<State> = {};

                switch (creationMode) {
                  case CreationMode.NewDocument:
                    if (documentTemplateId) {
                      documentTemplate = await getDocumentTemplate(
                        documentTemplateId
                      );
                      if (documentTemplate) {
                        additional = {
                          documentTypeId: undefined,
                          materialTypeId: undefined,
                        };
                        changed = true;
                      }
                    }

                    break;
                  case CreationMode.CopyDocument:
                    if (documentId) {
                      changed = documentId !== state.document?.id;
                      document = await getDocument(documentId);

                      if (document && document.documentTemplateId) {
                        documentTemplate = await getDocumentTemplate(
                          document.documentTemplateId
                        );
                        additional = {
                          documentTypeId: document?.documentTypeId,
                          materialTypeId: document?.materialTypeId,
                        };
                      }
                    }
                    break;
                }

                setState((prevState) => ({
                  ...prevState,
                  creationMode: creationMode,
                  code: !changed ? prevState.code : undefined,
                  countryId: !changed ? prevState.countryId : undefined,
                  regionId: !changed ? prevState.regionId : undefined,
                  documentTemplate: documentTemplate,
                  document: document,
                  ...additional,
                  currentStep: WizardSteps.Properties,
                }));
              }}
            />
          )}
          {state.currentStep === WizardSteps.Properties && (
            <StepProperties
              code={state.code}
              countries={countries}
              regions={regions}
              documentTypes={documentTypes}
              materialTypes={materialTypes}
              countryId={state.countryId}
              regionId={state.regionId}
              documentTypeId={state.documentTypeId}
              materialTypeId={state.materialTypeId}
              readOnly={state.document !== undefined}
              onPrevious={() =>
                setState((prevState) => ({
                  ...prevState,
                  currentStep: WizardSteps.Template,
                }))
              }
              onNext={(
                code,
                documentTypeId,
                materialTypeId,
                countryId,
                regionId
              ) =>
                setState((prevState) => ({
                  ...prevState,
                  code,
                  countryId,
                  regionId,
                  documentTypeId,
                  materialTypeId,
                  currentStep: WizardSteps.ViewsAndImages,
                }))
              }
            />
          )}
          {state.currentStep === WizardSteps.ViewsAndImages && (
            <StepViewsAndImages
              documentTemplate={state.documentTemplate}
              document={state.document}
              templates={state.selectedTemplates}
              onPrevious={() =>
                setState((prevState) => ({
                  ...prevState,
                  currentStep: WizardSteps.Properties,
                }))
              }
              onNext={(selectedViewAndImageTemplates) =>
                setState((prevState) => ({
                  ...prevState,
                  selectedTemplates: selectedViewAndImageTemplates,
                  currentStep: WizardSteps.Summary,
                }))
              }
            />
          )}
          {state.currentStep === WizardSteps.Summary && (
            <StepSummary
              documentTemplate={state.documentTemplate}
              code={state.code}
              documentType={documentTypes.find(
                (d) => d.id === state.documentTypeId
              )}
              materialType={materialTypes.find(
                (m) => m.id === state.materialTypeId
              )}
              selectedTemplates={state.selectedTemplates}
              isSavingDocument={state.isSavingDocument}
              onPrevious={() =>
                setState((prevState) => ({
                  ...prevState,
                  currentStep: WizardSteps.ViewsAndImages,
                }))
              }
              onNext={createDocument}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
