import { Checkbox } from "antd";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { AppContext } from "../../../context/app/context";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { Language } from "../../../models/reference_lists/Language";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import { CmsForm, CmsFormItem, CmsText } from "../../common/FormComponents";
import { CmsPageHeader, CmsPageLoader } from "../../common/PageComponents";
import {
  useQueryLanguage,
  useSaveLanguage,
} from "../../../queries/languages/detail";
import { ValidateLanguage } from "../../../utilities/helpers/language-helper";

export const LanguageDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("language");
  const { push } = useHistory();
  const { id: languageId } = useParams<Entity>();
  const { dispatch } = React.useContext(AppContext);

  const {
    data: language,
    isLoading: isLoadingLanguage,
    refetch: refetchLanguage,
  } = useQueryLanguage(languageId);
  const { mutateAsync: saveLanguage, isLoading: isSavingLanguage } =
    useSaveLanguage();

  React.useEffect(() => {
    if (language) {
      dispatch({
        type: "SET_CUSTOM_DUPLICATE_ERROR_MESSAGE",
        payload: t("errors:duplicateLanguage", {
          isoCode: language.isoCode,
        }),
      });
      dispatch({
        type: "SET_BREADCRUMB_ITEMS",
        payload: [
          {
            key: "language",
            name: language.name ?? t("common:new"),
          },
        ],
      });
    }
  }, [language, dispatch, t]);

  const isLoadingAny = (isLoadingLanguage && !!languageId) || isSavingLanguage;
  const noDataAny = !language && !!languageId;

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <Formik
      initialValues={language ?? new Language()}
      enableReinitialize={true}
      validate={(language: Language) => ValidateLanguage(language, t)}
      onSubmit={async (language: Language) => {
        await saveLanguage(language);
        !language.id ? push(`/languages`) : await refetchLanguage();
      }}
    >
      {(formikProps) => {
        const {
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
          errors,
          isValid,
          dirty,
        } = formikProps;

        return (
          <React.Fragment>
            <Prompt when={dirty} message={t("common:unsavedChanges")}></Prompt>
            <CmsPageHeader
              title={t("entities:language")}
              extra={[
                <CmsBackButton
                  key="back"
                  disabled={isLoadingAny}
                  onClick={() => push(`/languages`)}
                />,
                !!canUpdate && (
                  <CmsSaveButton
                    key="save"
                    disabled={!isValid || isLoadingAny}
                    loading={isLoadingAny}
                    onClick={() => handleSubmit()}
                  />
                ),
              ]}
            />
            <CmsForm>
              <CmsText
                id="name"
                label={t("properties:name")}
                placeholder={t("properties:name")}
                required={true}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={100}
                error={errors.name}
                value={values.name}
                onChange={handleChange}
              />

              <CmsText
                id="isoCode"
                label={t("properties:isoCode")}
                placeholder={t("properties:isoCode")}
                required={true}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={2}
                error={errors.isoCode}
                value={values.isoCode}
                onChange={handleChange}
              />

              <CmsFormItem label={t("properties:rightToLeft")}>
                <Checkbox
                  name="isRightToLeft"
                  disabled={!canUpdate || isLoadingAny}
                  checked={values.isRightToLeft}
                  onChange={(e) =>
                    setFieldValue("isRightToLeft", e.target.checked)
                  }
                />
              </CmsFormItem>
            </CmsForm>
          </React.Fragment>
        );
      }}
    </Formik>
  );
};
