import { DocumentSeries } from "../../models/data/Document";
import api from "../../services/api";
import { CountResponse } from "../../services/api/types";
import { CONST_DOCUMENT_SERIES } from "../../utilities/constants/query-contants";
import {
  IndexSearchQueryParams,
  QueryStringParams,
} from "./document-series-type";
import { useQuery } from "@tanstack/react-query";

export const getDocumentSeries = (
  payload: QueryStringParams
): Promise<DocumentSeries[]> => {
  return api.post({
    path: `${CONST_DOCUMENT_SERIES.plural}/Filter`,
    query: { ...payload },
  });
};

export const useQueryDocumentSeries = (payload: QueryStringParams) => {
  return useQuery<DocumentSeries[]>(
    [`${CONST_DOCUMENT_SERIES.plural}-list`, { payload }],
    () => getDocumentSeries(payload)
  );
};

export const getDocumentSeriesIndex = (
  payload: IndexSearchQueryParams
): Promise<CountResponse<DocumentSeries[]>> => {
  return api.post({
    path: `${CONST_DOCUMENT_SERIES.plural}/QueryFilter`,
    body: { ...payload },
    isTotalCount: true,
  });
};

export const useQueryDocumentSeriesIndex = (
  payload: IndexSearchQueryParams
) => {
  return useQuery<CountResponse<DocumentSeries[]>>(
    [`${CONST_DOCUMENT_SERIES.plural}-list-index`, { payload }],
    () => getDocumentSeriesIndex(payload)
  );
};
