import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Radio,
} from "antd";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  AttributeSchema,
  IDateTimeSchema,
  IListSchema,
  IMultiLingualTextSchema,
  INumberSchema,
  IObjectSchema,
  ITextSchema,
} from "../../../models/InputComponentData";
import { AttributeDataType } from "../../../models/templates/Attribute";
import { MultilingualInput } from "../../common/MultilingualInput";

export const AttributeInputSchema = (props: {
  dataType: AttributeDataType;
  dataTypeSchema: AttributeSchema;
  defaultValue: string;
  readonly: boolean;
  disabled: boolean;
  updateDefaultValue: (value: string) => void;
  updateSchemaValue: (property: string, value: any) => void;
  addListItem: () => void;
  removeListItem: (index: number) => void;
}) => {
  const { t } = useTranslation();

  const generateInputs = () => {
    switch (props.dataType) {
      case AttributeDataType.Number:
        return generateInputs4NumberSchema();
      case AttributeDataType.DateTime:
        return generateInputs4DateSchema();
      case AttributeDataType.List:
        return generateInputs4ListSchema();
      case AttributeDataType.Text:
        return generateInputs4TextSchema();
      case AttributeDataType.MultiLingualText:
        return generateInputs4MultiLingualTextSchema();
      case AttributeDataType.YesNo:
        return generateInputs4BooleanSchema();
      case AttributeDataType.Object:
        return generateInputs4ObjectSchema();
      default:
        return null;
    }
  };

  const generateInputs4ObjectSchema = () => {
    const objectSchema = props.dataTypeSchema as IObjectSchema;

    return (
      <>
        <Form.Item label={t("properties:maximumLength")}>
          <InputNumber
            maxLength={10}
            value={objectSchema.maxLength}
            onChange={(e) => props.updateSchemaValue("maxLength", e)}
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
        <Form.Item label={t("properties:regularExpression")}>
          <Input
            maxLength={100}
            value={objectSchema.regex}
            onChange={(e) => props.updateSchemaValue("regex", e.target.value)}
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
        <Form.Item label={t("properties:errorMessage")}>
          <Input
            placeholder={t("properties:errorMessage")}
            maxLength={100}
            value={objectSchema.errorMessage}
            onChange={(e) =>
              props.updateSchemaValue("errorMessage", e.target.value)
            }
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
      </>
    );
  };

  const generateInputs4BooleanSchema = () => {
    return (
      <Form.Item label={t("properties:defaultValue")}>
        <Radio.Group
          onChange={(e) =>
            props.updateDefaultValue(e.target.value ? "Yes" : "No")
          }
          value={props.defaultValue === "Yes"}
          buttonStyle="solid"
          disabled={props.readonly || props.disabled}
        >
          <Radio.Button value={true}>{t("common:yes")}</Radio.Button>
          <Radio.Button value={false}>{t("common:no")}</Radio.Button>
        </Radio.Group>
      </Form.Item>
    );
  };

  const generateInputs4TextSchema = () => {
    const textSchema = props.dataTypeSchema as ITextSchema;

    return (
      <React.Fragment>
        <Form.Item label={t("properties:defaultValue")}>
          <Input
            placeholder={t("properties:defaultValue")}
            maxLength={100}
            value={props.defaultValue}
            onChange={(e) => props.updateDefaultValue(e.target.value)}
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
        <Form.Item label={t("properties:minimumLength")}>
          <InputNumber
            maxLength={10}
            value={textSchema.minLength}
            onChange={(e) => props.updateSchemaValue("minLength", e)}
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
        <Form.Item label={t("properties:maximumLength")}>
          <InputNumber
            maxLength={10}
            value={textSchema.maxLength}
            onChange={(e) => props.updateSchemaValue("maxLength", e)}
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
        <Form.Item label={t("properties:regularExpression")}>
          <Input
            maxLength={100}
            value={textSchema.regex}
            onChange={(e) => props.updateSchemaValue("regex", e.target.value)}
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
        <Form.Item label={t("properties:errorMessage")}>
          <Input
            placeholder={t("properties:errorMessage")}
            maxLength={100}
            value={textSchema.errorMessage}
            onChange={(e) =>
              props.updateSchemaValue("errorMessage", e.target.value)
            }
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
      </React.Fragment>
    );
  };

  const generateInputs4MultiLingualTextSchema = () => {
    const multiLingualSchema = props.dataTypeSchema as IMultiLingualTextSchema;

    return (
      <React.Fragment>
        <Form.Item label={t("properties:defaultValue")}>
          <Input
            placeholder={t("properties:defaultValue")}
            maxLength={100}
            value={props.defaultValue}
            onChange={(e) => props.updateDefaultValue(e.target.value)}
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
        <Form.Item label={t("properties:minimumLength")}>
          <InputNumber
            maxLength={10}
            value={multiLingualSchema.minLength}
            onChange={(e) => props.updateSchemaValue("minLength", e)}
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
        <Form.Item label={t("properties:maximumLength")}>
          <InputNumber
            maxLength={10}
            value={multiLingualSchema.maxLength}
            onChange={(e) => props.updateSchemaValue("maxLength", e)}
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
        <Form.Item label={t("properties:regularExpression")}>
          <Input
            maxLength={100}
            value={multiLingualSchema.regex.toString()}
            onChange={(e) => props.updateSchemaValue("regex", e.target.value)}
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
        <Form.Item label={t("properties:errorMessage")}>
          <Input
            placeholder={t("properties:errorMessage")}
            maxLength={100}
            value={multiLingualSchema.errorMessage}
            onChange={(e) =>
              props.updateSchemaValue("errorMessage", e.target.value)
            }
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
      </React.Fragment>
    );
  };

  const generateInputs4NumberSchema = () => {
    const numberSchema = props.dataTypeSchema as INumberSchema;
    return (
      <React.Fragment>
        <Form.Item label={t("properties:defaultValue")}>
          <InputNumber
            value={
              props.defaultValue ? parseFloat(props.defaultValue) : undefined
            }
            onChange={(e) => e && props.updateDefaultValue(e.toString())}
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
        <Form.Item label={t("properties:minimumValue")}>
          <InputNumber
            id="minValue"
            name="minValue"
            maxLength={10}
            value={numberSchema.minValue}
            onChange={(e) => props.updateSchemaValue("minValue", e)}
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
        <Form.Item label={t("properties:maximumValue")}>
          <InputNumber
            id="maxValue"
            name="maxValue"
            maxLength={10}
            value={numberSchema.maxValue}
            onChange={(e) => props.updateSchemaValue("maxValue", e)}
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
        <Form.Item label={t("properties:numberOfDecimals")}>
          <InputNumber
            id="decimalCount"
            name="decimalCount"
            value={numberSchema.decimalCount}
            onChange={(e) => props.updateSchemaValue("decimalCount", e)}
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
        <Form.Item label={t("properties:errorMessage")}>
          <Input
            placeholder={t("properties:errorMessage")}
            maxLength={100}
            value={numberSchema.errorMessage}
            onChange={(e) =>
              props.updateSchemaValue("errorMessage", e.target.value)
            }
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
      </React.Fragment>
    );
  };

  const generateInputs4ListSchema = () => {
    const listSchema = props.dataTypeSchema as IListSchema;

    const getRows = (listSchema: IListSchema): React.ReactNode => {
      const rows: React.ReactNode[] = [];
      listSchema.items.forEach((value, index) => {
        rows.push(
          <tr>
            <td style={{ verticalAlign: "top" }}>
              <Radio
                name="defaultOfYesNo"
                checked={props.defaultValue === value.code}
                onChange={(e) =>
                  props.updateDefaultValue(listSchema.items[index].code)
                }
                disabled={props.readonly || props.disabled}
              />
            </td>
            <td style={{ verticalAlign: "top", paddingTop: "2px" }}>
              <Input
                placeholder={t("properties:value")}
                maxLength={50}
                value={value.code}
                onChange={(e) =>
                  props.updateSchemaValue(
                    `items[${index}].code`,
                    e.target.value
                  )
                }
                readOnly={props.readonly}
                disabled={props.disabled}
              />
            </td>
            <td style={{ verticalAlign: "top" }}>
              <MultilingualInput
                disabled={props.readonly || props.disabled}
                mlData={value.name}
                onChange={(e) =>
                  props.updateSchemaValue(`items[${index}].name`, e)
                }
              />
            </td>
            <td style={{ verticalAlign: "top" }}>
              <Popconfirm
                title={t("common:confirmDelete")}
                onConfirm={() => props.removeListItem(index)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  key="delete"
                  disabled={props.readonly || props.disabled}
                  style={{ margin: "0px 2px 0px 2px" }}
                  danger
                  shape="circle"
                  icon={<DeleteOutlined />}
                  size="small"
                />
              </Popconfirm>
            </td>
          </tr>
        );
      });

      return (
        <table>
          <thead>
            <tr>
              <td
                style={{
                  width: "10%",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                {t("properties:default")}
              </td>
              <td style={{ width: "15%", fontWeight: "bold" }}>
                {t("properties:value")}
              </td>
              <td style={{ width: "75%", fontWeight: "bold" }}>
                {t("properties:description")}
              </td>
              <td align="right">
                <Button
                  type="primary"
                  disabled={props.readonly || props.disabled}
                  shape="circle"
                  icon={<PlusOutlined />}
                  style={{ margin: "4px" }}
                  size="small"
                  onClick={() => props.addListItem()}
                />
              </td>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      );
    };

    return (
      <React.Fragment>
        <Form.Item label={t("properties:multiSelect")}>
          <Checkbox
            checked={listSchema.multiSelect}
            disabled={props.readonly || props.disabled}
            onChange={(e) =>
              props.updateSchemaValue("multiSelect", e.target.checked)
            }
          />
        </Form.Item>
        <Form.Item label={t("properties:items")}>
          <React.Fragment>{getRows(listSchema)}</React.Fragment>
        </Form.Item>
        <Form.Item label={t("properties:errorMessage")}>
          <Input
            placeholder={t("properties:errorMessage")}
            maxLength={100}
            value={listSchema.errorMessage}
            onChange={(e) =>
              props.updateSchemaValue("errorMessage", e.target.value)
            }
            readOnly={props.readonly}
            disabled={props.disabled}
          />
        </Form.Item>
      </React.Fragment>
    );
  };

  const generateInputs4DateSchema = () => {
    const dateTimeSchema = props.dataTypeSchema as IDateTimeSchema;

    return (
      <Form.Item label={t("properties:format")}>
        <Radio.Group
          disabled={props.readonly || props.disabled}
          onChange={(e) => props.updateSchemaValue("format", e.target.value)}
          value={dateTimeSchema.format}
          buttonStyle="solid"
        >
          <Radio.Button key={"DATE_TIME"} value={"DATE_TIME"}>
            {t("properties:dateTime")}
          </Radio.Button>
          <Radio.Button key={"DATE"} value={"DATE"}>
            {t("properties:date")}
          </Radio.Button>
          <Radio.Button key={"TIME"} value={"TIME"}>
            {t("properties:time")}
          </Radio.Button>
          <Radio.Button key={"YEAR_AND_MONTH"} value={"YEAR_AND_MONTH"}>
            {t("properties:yearAndMonth")}
          </Radio.Button>
          <Radio.Button key={"YEAR"} value={"YEAR"}>
            {t("properties:year")}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
    );
  };

  return generateInputs();
};
