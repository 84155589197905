import { TFunction } from "i18next";
import { MaterialType } from "../../models/reference_lists/MaterialType";
import { validateName, validateRequired } from "../Validators";

export const ValidateMaterialType = (
  materialType: MaterialType,
  t: TFunction
) => {
  const errors: any = {};

  const nameResult =
    validateRequired(materialType.name, t("properties:name")) ||
    validateName(materialType.name, t("properties:name"));
  if (nameResult != null) {
    errors.name = nameResult;
  }

  return errors;
};
