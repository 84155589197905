import { MultilingualInputData } from "../MultilingualInputData";
import { AttributeLink } from "./AttributeLink";
import { ViewTemplate } from "./ViewTemplate";

export class DocumentTemplate {
  public id?: string;
  public groupId?: string;
  public name?: string;
  public caption: MultilingualInputData = {};
  public viewTemplates: ViewTemplate[] = [];
  public attributeLinks: AttributeLink[] = [];

  public withGroup(groupId: string) {
    this.groupId = groupId;
    return this;
  }
}
