import { Point } from "react-lasso-select/lib/helpers";

export class FieldValue {
  public id?: string;
  public imageId?: string;
  public fieldLinkId?: string;
  public x?: number;
  public y?: number;
  public width?: number;
  public height?: number;
  public points?: Point[];
  public shapeType?: "RECTANGLE" | "POLYGON";

  public withFieldLink?(fieldLinkId: string) {
    this.fieldLinkId = fieldLinkId;
    return this;
  }
}
