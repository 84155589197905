import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Select, Typography } from "antd";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../../hooks/useApi";
import i18n from "../../../../i18n";
import { DocumentCode } from "../../../../models/data/DocumentCode";
import { Country } from "../../../../models/reference_lists/Country";
import { DocumentType } from "../../../../models/reference_lists/DocumentType";
import { MaterialType } from "../../../../models/reference_lists/MaterialType";
import { getLocalizedValue } from "../../../../utilities/MultilingualHelper";
import { validateRequired } from "../../../../utilities/Validators";
import { CmsForm, CmsSelect, CmsText } from "../../../common/FormComponents";
import styles from "./DocumentWizard.module.scss";
import { Region } from "../../../../models/reference_lists/Region";
const { Title } = Typography;

interface Props {
  code?: string;
  countries: Country[];
  regions: Region[];
  documentTypes: DocumentType[];
  materialTypes: MaterialType[];
  countryId?: string;
  regionId?: string;
  documentTypeId?: string;
  materialTypeId?: string;
  readOnly: boolean;
  onPrevious: () => void;
  onNext: (
    code?: string,
    documentTypeId?: string,
    materialTypeId?: string,
    countryId?: string,
    regionId?: string
  ) => void;
}

export const StepProperties = (props: Props) => {
  const { post: postGenerateCodeByCountry } = useApi<Country>(
    "keesing/CodeGenerator/country"
  );
  const { post: postGenerateCodeByRegion } = useApi<Region>(
    "keesing/CodeGenerator/region"
  );
  const { get: checkCode } = useApi<DocumentCode>("keesing/CodeGenerator");

  const { t } = useTranslation();

  const validateAsync = async (values) => {
    const errors: any = {};

    const codeResult = validateRequired(values.code, t("properties:code"));
    if (codeResult) {
      errors.code = codeResult;
    } else {
      const codeInUse = await validateCode(values.code);
      if (codeInUse) {
        errors.code = codeInUse;
      }
    }

    const countryResult = validateRequired(
      values.countryId,
      t("properties:countryOrRegion")
    );

    const regionResult = validateRequired(
      values.regionId,
      t("properties:countryOrRegion")
    );

    if (countryResult && regionResult) {
      errors.countryId = countryResult;
      errors.regionId = regionResult;
    }

    const documentTypeResult = validateRequired(
      values.documentTypeId,
      t("properties:type")
    );

    if (documentTypeResult) {
      errors.documentTypeId = documentTypeResult;
    }

    const materialTypeResult = validateRequired(
      values.materialTypeId,
      t("properties:materialType")
    );

    if (materialTypeResult) {
      errors.materialTypeId = materialTypeResult;
    }

    return errors;
  };

  const generateCode = async (
    generationType: "country" | "region",
    id?: string,
    documentTypeId?: string
  ) => {
    if (id && documentTypeId) {
      let response;
      switch (generationType) {
        case "country":
          response = await postGenerateCodeByCountry({
            countryId: id,
            documentTypeId,
          });
          break;
        case "region":
        default:
          response = await postGenerateCodeByRegion({
            regionId: id,
            documentTypeId,
          });
          break;
      }

      return response.data;
    }
    return null;
  };

  const validateCode = async (code: string) => {
    const documentCode = await checkCode(code);
    if (!documentCode.available) {
      return i18n.t("validations:inputCodeInUse", {
        value: code,
      });
    }
    return null;
  };

  return (
    <Formik
      initialValues={{
        code: props.code,
        countryId: props.countryId,
        regionId: props.regionId,
        documentTypeId: props.documentTypeId,
        materialTypeId: props.materialTypeId,
      }}
      validate={validateAsync}
      validateOnMount={true}
      onSubmit={async (values) => {
        props.onNext(
          values.code,
          values.documentTypeId,
          values.materialTypeId,
          values.countryId,
          values.regionId
        );
      }}
    >
      {(formikProps) => {
        const { dirty, errors, isValid, setFieldValue, submitForm, values } =
          formikProps;
        return (
          <React.Fragment>
            <div className={styles["step-content"]}>
              <Title level={3}>{t("common:properties")}</Title>
              <CmsForm>
                <CmsSelect
                  id="countryId"
                  label={t("properties:country")}
                  placeholder={t("properties:country")}
                  loading={props.countries.length === 0}
                  required={true}
                  error={dirty && errors.countryId}
                  value={values.countryId}
                  disabled={!!values.regionId}
                  onChange={async (countryId) => {
                    setFieldValue("countryId", countryId);
                    const code = await generateCode(
                      "country",
                      countryId,
                      values.documentTypeId
                    );
                    setFieldValue("code", code);
                  }}
                >
                  {props.countries.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {getLocalizedValue(item.name)}
                    </Select.Option>
                  ))}
                </CmsSelect>
                <CmsSelect
                  id="regionId"
                  label={t("entities:region")}
                  placeholder={t("entities:region")}
                  loading={props.regions.length === 0}
                  required={true}
                  error={dirty && errors.regionId}
                  value={values.regionId}
                  disabled={!!values.countryId}
                  onChange={async (regionId) => {
                    setFieldValue("regionId", regionId);
                    const code = await generateCode(
                      "region",
                      regionId,
                      values.documentTypeId
                    );
                    setFieldValue("code", code);
                  }}
                >
                  {props.regions.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {getLocalizedValue(item.name)}
                    </Select.Option>
                  ))}
                </CmsSelect>
                <CmsSelect
                  id="documentTypeId"
                  label={t("properties:type")}
                  placeholder={t("properties:type")}
                  loading={props.documentTypes.length === 0}
                  required={true}
                  readOnly={props.readOnly}
                  error={dirty && errors.documentTypeId}
                  value={values.documentTypeId}
                  onChange={async (documentTypeId) => {
                    setFieldValue("documentTypeId", documentTypeId);
                    if (values.countryId) {
                      const code = await generateCode(
                        "country",
                        values.countryId,
                        documentTypeId
                      );
                      setFieldValue("code", code);
                    } else if (values.regionId) {
                      const code = await generateCode(
                        "region",
                        values.regionId,
                        documentTypeId
                      );
                      setFieldValue("code", code);
                    }
                  }}
                >
                  {props.documentTypes.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </CmsSelect>
                <CmsText
                  id="code"
                  label={t("properties:code")}
                  required={true}
                  readOnly={
                    values.documentTypeId === null || values.countryId === null
                  }
                  error={dirty && errors.code}
                  value={values.code}
                  onChange={(event) => {
                    setFieldValue("code", event.currentTarget.value);
                  }}
                />
                <CmsSelect
                  id="materialTypeId"
                  label={t("properties:materialType")}
                  placeholder={t("properties:materialType")}
                  loading={props.materialTypes.length === 0}
                  required={true}
                  readOnly={props.readOnly}
                  error={dirty && errors.materialTypeId}
                  value={values.materialTypeId}
                  onChange={(materialTypeId) =>
                    setFieldValue("materialTypeId", materialTypeId)
                  }
                >
                  {props.materialTypes.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </CmsSelect>
              </CmsForm>
            </div>
            <div className={styles["button-bar"]}>
              <Button
                type="primary"
                icon={<LeftOutlined />}
                onClick={props.onPrevious}
              >
                {t("common:previous")}
              </Button>
              <Button
                className={"button-next"}
                type="primary"
                icon={<RightOutlined />}
                disabled={!isValid}
                onClick={submitForm}
              >
                {t("common:next")}
              </Button>
            </div>
          </React.Fragment>
        );
      }}
    </Formik>
  );
};
