import { Table } from "antd";
import { TableProps } from "antd/lib/table";
import * as _ from "lodash";
import React, { MouseEventHandler } from "react";
import { DragSource, DropTarget } from "react-dnd";
import "./DragSortTable.scss";

let dragingIndex = -1;

interface Props<T> extends TableProps<T> {
  readOnly?: boolean;
}

interface OrderableItem {
  order?: number;
}

interface OnRowFromProps {
  index?: number;
  moveRow?: () => void;
  onClick?: MouseEventHandler<HTMLElement>;
}

export const DragSortTable = <T extends OrderableItem>(props: Props<T>) => {
  return (
    <Table
      {...props}
      components={
        !props.readOnly
          ? {
              body: {
                row: DragableBodyRow,
              },
            }
          : undefined
      }
      dataSource={_.orderBy(props.dataSource, "order", "asc")}
      onRow={(record, rowIndex) => {
        if (props.readOnly) return {};

        const onRowFromProps: OnRowFromProps | undefined =
          props.onRow && props.onRow(record, rowIndex);

        if (!onRowFromProps) return {};

        return {
          index: rowIndex,
          moveRow: onRowFromProps.moveRow,
          onClick: onRowFromProps.onClick,
        };
      }}
    />
  );
};

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return;
    }

    props.moveRow(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
};

const BodyRow = (props) => {
  const {
    isOver,
    connectDragSource,
    connectDropTarget,
    moveRow,
    ...restProps
  } = props;
  const style = { ...restProps.style, cursor: "move" };

  let { className } = restProps;
  if (isOver) {
    if (restProps.index > dragingIndex) {
      className += " drop-over-downward";
    }
    if (restProps.index < dragingIndex) {
      className += " drop-over-upward";
    }
  }

  return connectDragSource(
    connectDropTarget(<tr {...restProps} className={className} style={style} />)
  );
};

const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource("row", rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow)
);
