import "core-js/features/array";
import "core-js/features/string";
import "react-app-polyfill/ie9";
import ReactDOM from "react-dom";
import { App } from "./App";
import Config from "./Config";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.css";
import "./styles/spinner.css";
import "./index.scss";

Config.loadConfiguration().then(() => {
  ReactDOM.render(<App />, document.getElementById("root"));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();
});
