import { Card, Checkbox, Popconfirm } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useFormikContext } from "formik";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CmsForm } from "../../../components/common/FormComponents";
import { DocumentSeries } from "../../../models/data/Document";
import { Image } from "../../../models/data/Image";
import { View } from "../../../models/data/View";
import { ImageTemplate } from "../../../models/templates/ImageTemplate";
import { ViewTemplate } from "../../../models/templates/ViewTemplate";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { capitalizeFirstLetter } from "../../../utilities/StringHelper";
import { CmsCancelButton, CmsSaveButton } from "../../common/ButtonComponents";

interface Props {
  visible: boolean;
  viewTemplates: ViewTemplate[];
  views: View[];
  onClose(): void;
  onSave(views: View[]): void;
}

interface State {
  viewTemplateSelections: ViewTemplateSelection[];
  isChanged: boolean;
}

interface ViewTemplateSelection {
  viewTemplateId?: string;
  viewTemplateName: string;
  initialSelected: boolean;
  selected: boolean;
  imageTemplateSelections: ImageTemplateSelection[];
}

interface ImageTemplateSelection {
  imageTemplateId?: string;
  imageTemplateName: string;
  initialSelected: boolean;
  selected: boolean;
}

export const ViewsManager = (props: Props) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<DocumentSeries>();

  const [state, setState] = useState<State>({
    viewTemplateSelections: [],
    isChanged: false,
  });

  useEffect(() => {
    const viewTemplateSelections = props.viewTemplates.map(
      (viewTemplate: ViewTemplate) => {
        const view = props.views.find(
          (item: View) => item.viewTemplateId === viewTemplate.id
        );
        return {
          viewTemplateId: viewTemplate.id,
          viewTemplateName: capitalizeFirstLetter(
            getLocalizedValue(viewTemplate.caption) as string
          ),
          initialSelected: view ? true : false,
          selected: view ? true : false,
          imageTemplateSelections: viewTemplate.imageTemplates.map(
            (imageTemplate: ImageTemplate) => {
              const image = view
                ? view.images?.find(
                    (item: Image) => item.imageTemplateId === imageTemplate.id
                  )
                : null;
              return {
                imageTemplateId: imageTemplate.id,
                imageTemplateName: capitalizeFirstLetter(
                  getLocalizedValue(imageTemplate.caption) as string
                ),
                initialSelected: !!image,
                selected: !!image,
              };
            }
          ),
        };
      }
    );

    if (viewTemplateSelections && viewTemplateSelections.length > 0) {
      setState((prevState) => ({
        ...prevState,
        viewTemplateSelections,
        isChanged: false,
      }));
    }
  }, [props.viewTemplates, props.views, props.visible]);

  const selectViewTemplate = (viewTemplateId: string, selected: boolean) => {
    const viewTemplateSelections = _.cloneDeep(state.viewTemplateSelections);

    const viewTemplateSelection = viewTemplateSelections.find(
      (item) => item.viewTemplateId === viewTemplateId
    );
    viewTemplateSelection.selected = selected;

    viewTemplateSelection.imageTemplateSelections.forEach(
      (imageTemplateSelection) => {
        imageTemplateSelection.selected = false;
      }
    );

    if (selected && viewTemplateSelection.imageTemplateSelections.length > 0) {
      viewTemplateSelection.imageTemplateSelections[0].selected = true;
    }

    setState((prevState) => ({
      ...prevState,
      viewTemplateSelections,
      isChanged: true,
    }));
  };

  const selectImageTemplate = (
    viewTemplateId: string,
    imageTemplateId: string,
    selected: boolean
  ) => {
    const viewTemplateSelections = _.cloneDeep(state.viewTemplateSelections);

    const viewTemplateSelection = viewTemplateSelections.find(
      (item: ViewTemplateSelection) => item.viewTemplateId === viewTemplateId
    );
    const imageTemplateSelection =
      viewTemplateSelection.imageTemplateSelections.find(
        (item) => item.imageTemplateId === imageTemplateId
      );
    imageTemplateSelection.selected = selected;

    if (selected) {
      viewTemplateSelection.selected = selected;
    }

    setState((prevState) => ({
      ...prevState,
      viewTemplateSelections,
      isChanged: true,
    }));
  };

  const findView = (
    views: View[],
    viewTemplateId?: string
  ): View | undefined => {
    return views.find((view) => view.viewTemplateId === viewTemplateId);
  };

  const addView = (views: View[], viewTemplateId: string): View => {
    const viewTemplate = props.viewTemplates.find(
      (item) => item.id === viewTemplateId
    );
    const newView = {
      viewTemplateId: viewTemplate?.id,
      exportable: true,
      deepZoomExportable: true,
      attributeValues: viewTemplate?.attributeLinks.map((attributeLink) => ({
        value: attributeLink.defaultValue,
        attributeLinkId: attributeLink.id,
      })),
      images: viewTemplate?.imageTemplates.map(
        (imageTemplate) =>
          ({
            imageTemplateId: imageTemplate.id,
            instanceId: values.instances?.[0].id,
            exportable: true,
          } as Image)
      ),
    } as View;
    views.push(newView);
    return newView;
  };

  const deleteView = (views: View[], viewTemplateId: string) => {
    const index = views.findIndex(
      (item) => item.viewTemplateId === viewTemplateId
    );
    if (index >= 0) {
      views.splice(index, 1);
    }
  };

  const findImage = (
    images: Image[],
    imageTemplateId: string
  ): Image | undefined => {
    return images.find((image) => image.imageTemplateId === imageTemplateId);
  };

  const addImage = (
    images: Image[],
    viewTemplateId: string,
    imageTemplateId: string
  ): Image => {
    const viewTemplate = props.viewTemplates.find(
      (item) => item.id === viewTemplateId
    );
    const imageTemplate = viewTemplate?.imageTemplates.find(
      (item) => item.id === imageTemplateId
    );
    const instanceId = values.instances?.[0].id;
    const newImage = {
      imageTemplateId: imageTemplate?.id,
      instanceId: instanceId,
      exportable: true,
    } as Image;
    images.push(newImage);
    return newImage;
  };

  const deleteImage = (images: Image[], imageTemplateId: string) => {
    const index = images.findIndex(
      (item) => item.imageTemplateId === imageTemplateId
    );
    if (index >= 0) {
      images.splice(index, 1);
    }
  };

  const onUpdateChanges = () => {
    const newViews = props.views.slice(0);

    state.viewTemplateSelections.forEach((viewTemplateSelection) => {
      if (
        viewTemplateSelection.selected &&
        viewTemplateSelection.viewTemplateId
      ) {
        let view = findView(newViews, viewTemplateSelection.viewTemplateId);
        if (!view) {
          view = addView(newViews, viewTemplateSelection.viewTemplateId);
        }

        viewTemplateSelection.imageTemplateSelections.forEach(
          (imageTemplateSelection) => {
            if (
              imageTemplateSelection.selected &&
              view?.images &&
              imageTemplateSelection.imageTemplateId
            ) {
              let image = findImage(
                view.images,
                imageTemplateSelection.imageTemplateId
              );
              if (!image && viewTemplateSelection.viewTemplateId) {
                image = addImage(
                  view.images,
                  viewTemplateSelection.viewTemplateId,
                  imageTemplateSelection.imageTemplateId
                );
              }
            } else {
              view?.images &&
                imageTemplateSelection.imageTemplateId &&
                deleteImage(
                  view.images,
                  imageTemplateSelection.imageTemplateId
                );
            }
          }
        );
      } else {
        viewTemplateSelection.viewTemplateId &&
          deleteView(newViews, viewTemplateSelection.viewTemplateId);
      }
    });

    props.onSave(newViews);
  };

  return (
    <Modal
      width={750}
      title={t("common:manageViewsAndImages")}
      destroyOnClose={true}
      open={props.visible}
      onCancel={props.onClose}
      style={{ paddingBottom: 0 }}
      bodyStyle={{ maxHeight: "80vh", overflow: "auto" }}
      centered={true}
      footer={[
        <CmsCancelButton key="cancel" onClick={props.onClose}>
          {t("common:cancel")}
        </CmsCancelButton>,
        <CmsSaveButton
          key="save"
          disabled={!state.isChanged}
          onClick={onUpdateChanges}
        >
          {t("common:save")}
        </CmsSaveButton>,
      ]}
    >
      <CmsForm>
        {state.viewTemplateSelections.map(
          (viewTemplateSelection: ViewTemplateSelection) => {
            return (
              <Card
                key={`card_${viewTemplateSelection.viewTemplateId}`}
                type="inner"
                bodyStyle={{ display: "flex", justifyContent: "flex-start" }}
                style={{ marginBottom: "10px" }}
                title={
                  <Popconfirm
                    title={t("common:confirmDelete")}
                    disabled={
                      !(
                        viewTemplateSelection.initialSelected &&
                        viewTemplateSelection.selected
                      )
                    }
                    onCancel={(e) => {
                      e?.stopPropagation();
                    }}
                    onConfirm={(e) => {
                      e?.stopPropagation();
                      viewTemplateSelection.viewTemplateId &&
                        selectViewTemplate(
                          viewTemplateSelection.viewTemplateId,
                          false
                        );
                    }}
                    okText={t("common:yes")}
                    cancelText={t("common:no")}
                  >
                    <Checkbox
                      key={`checkbox_${viewTemplateSelection.viewTemplateId}`}
                      checked={viewTemplateSelection.selected}
                      onClick={(e) => {
                        if (
                          viewTemplateSelection.initialSelected &&
                          viewTemplateSelection.selected
                        ) {
                          e.preventDefault();
                          e.stopPropagation();
                          return false;
                        } else {
                          viewTemplateSelection.viewTemplateId &&
                            selectViewTemplate(
                              viewTemplateSelection.viewTemplateId,
                              !viewTemplateSelection.selected
                            );
                        }
                      }}
                    >
                      {viewTemplateSelection.viewTemplateName}
                    </Checkbox>
                  </Popconfirm>
                }
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                  }}
                >
                  {viewTemplateSelection.imageTemplateSelections.map(
                    (imageTemplateSelection: ImageTemplateSelection) => {
                      return (
                        <div
                          key={`div_${imageTemplateSelection.imageTemplateId}`}
                          style={{ width: "150px" }}
                        >
                          <Popconfirm
                            title={t("common:confirmDelete")}
                            disabled={
                              !(
                                imageTemplateSelection.initialSelected &&
                                imageTemplateSelection.selected
                              )
                            }
                            onCancel={(e) => {
                              e?.stopPropagation();
                            }}
                            onConfirm={(e) => {
                              e?.stopPropagation();
                              viewTemplateSelection.viewTemplateId &&
                                imageTemplateSelection.imageTemplateId &&
                                selectImageTemplate(
                                  viewTemplateSelection.viewTemplateId,
                                  imageTemplateSelection.imageTemplateId,
                                  false
                                );
                            }}
                            okText={t("common:yes")}
                            cancelText={t("common:no")}
                          >
                            <Checkbox
                              key={`checkbox_${imageTemplateSelection.imageTemplateId}`}
                              checked={imageTemplateSelection.selected}
                              onClick={(e) => {
                                if (
                                  imageTemplateSelection.initialSelected &&
                                  imageTemplateSelection.selected
                                ) {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  return false;
                                } else {
                                  viewTemplateSelection.viewTemplateId &&
                                    imageTemplateSelection.imageTemplateId &&
                                    selectImageTemplate(
                                      viewTemplateSelection.viewTemplateId,
                                      imageTemplateSelection.imageTemplateId,
                                      !imageTemplateSelection.selected
                                    );
                                }
                              }}
                            >
                              {imageTemplateSelection.imageTemplateName}
                            </Checkbox>
                          </Popconfirm>
                        </div>
                      );
                    }
                  )}
                </div>
              </Card>
            );
          }
        )}
      </CmsForm>
    </Modal>
  );
};
