import { Product } from "./Product";
import { Address } from "./Address";
import { Contact } from "./Contact";

export enum OrganizationType {
  Producer = 1 << 0,
  Issuer = 1 << 1,
  Reference = 1 << 2,
  None = 0,
}

export enum OrganizationProductType {
  Document = 1 << 0,
  Banknote = 1 << 1,
}

export class Organization {
  public id?: string;
  public name?: string;
  public type?: OrganizationType;
  public productType?: OrganizationProductType;
  public address: Address = new Address();

  public contact: Contact = new Contact();
  public webAddress?: string;
  public remarks?: string;
  public countryId?: string;
  public products: Product[] = [];
}
