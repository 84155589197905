export default class Config {
  private static json: any;

  public static localStorageLanguageKey: string = "cms_language";
  public static defaultLanguage: string = "en";

  public static get apiUrl(): string {
    return this.json.apiUrl;
  }

  public static get appId(): string {
    return this.json.appId;
  }

  public static get realm(): string {
    return this.json.realm;
  }

  public static get authenticationEnabled(): boolean {
    return (
      !this.json.authenticationEnabled ||
      this.json.authenticationEnabled === "true"
    );
  }

  public static get baseUrl(): string {
    return this.json.baseUrl;
  }

  public static get idsUrl(): string {
    return this.json.idsUrl;
  }

  public static get dcBaseUrl(): string {
    return this.json.dcBaseUrl;
  }

  public static get language(): string {
    return (
      localStorage.getItem(this.localStorageLanguageKey) || this.defaultLanguage
    );
  }

  public static loadConfiguration(): Promise<void> {
    return new Promise((resolve) => {
      fetch(`/config.json?${new Date().getTime()}`).then((response) => {
        response.json().then((json) => {
          this.json = json;
          resolve();
        });
      });
    });
  }
}
