import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Layout, Popconfirm, Result, Tabs } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import Search from "antd/lib/input/Search";
import { SpinProps } from "antd/lib/spin";
import Table, { TableProps } from "antd/lib/table";
import { TabsProps } from "antd/lib/tabs";
import React, { CSSProperties, FC, ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";
const { Content, Sider } = Layout;

export const CmsPageLoader: FC<{
  loading: boolean;
  title?: string;
  subTitle?: string;
  extra?: ReactNode;
  style?: CSSProperties;
}> = (props) => {
  return (
    <>
      {props.loading ? (
        <Result
          icon={<LoadingOutlined style={{ fontSize: 48 }} spin />}
          title={props.title}
          subTitle={props.subTitle}
          extra={props.extra}
        />
      ) : (
        <div style={props.style}>{props.children}</div>
      )}
    </>
  );
};

export const CmsPageHeader: FC<{
  title: string;
  extra?: ReactNode;
}> = (props) => {
  return (
    <PageHeader
      title={props.title}
      style={{
        padding: "0 0 12px 0",
        margin: "0 0 12px 0",
        borderBottom: "1px solid rgb(235, 237, 240)",
      }}
      extra={props.extra}
    />
  );
};

export const CmsSearch: FC<{
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
}> = (props) => {
  const timer = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();

  const search = (searchString: string) => {
    if (timer.current !== null) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      props.onChange(searchString);
    }, 500);
  };

  return (
    <Search
      placeholder={props.placeholder || t("common:search")}
      defaultValue={props.value}
      onChange={(e) => search(e.target.value)}
      style={{ width: 200 }}
    />
  );
};

export const CmsSider: FC<{ children: ReactNode }> = (props) => {
  return (
    <Sider
      width={"auto"}
      style={{
        overflow: "hidden",
        background: "#fff",
        paddingRight: "10px",
        borderRight: "1px solid rgb(235, 237, 240)",
      }}
    >
      <div style={{ minWidth: "250px" }}>{props.children}</div>
    </Sider>
  );
};

export const CmsContent: FC<{ children: ReactNode }> = (props) => {
  return (
    <Content style={{ backgroundColor: "#fff" }}>{props.children}</Content>
  );
};

interface CmsTableProps<T> extends TableProps<T> {
  rowKey: string;
  loading?: boolean;
  columns: any;
  bordered?: boolean;
  dataSource: T[];
  onEdit?: (id: string) => void;
  onDeleteHeader?: ReactNode;
  onDelete?: (id: string) => void;
}

export const CmsTable = <T extends any>(props: CmsTableProps<T>) => {
  const { t } = useTranslation();
  const loader = {
    spinning: props.loading || false,
    indicator: <LoadingOutlined style={{ fontSize: 32 }} spin />,
    size: "large",
  } as SpinProps;

  if (props.onDelete && props.columns) {
    props.columns.push({
      title: props.onDeleteHeader,
      width: 100,
      align: "right",
      render: (_, record) => {
        return (
          <React.Fragment>
            <Popconfirm
              title={t("common:confirmDelete")}
              onCancel={(e) => e?.stopPropagation()}
              onConfirm={(e) => {
                e?.stopPropagation();
                props.onDelete && props.onDelete(record[props.rowKey]);
              }}
              okText={t("common:yes")}
              cancelText={t("common:no")}
            >
              <Button
                style={{ marginLeft: "4px" }}
                danger
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
                size="small"
                onClick={(e) => e.stopPropagation()}
              />
            </Popconfirm>
          </React.Fragment>
        );
      },
    });
  }

  return (
    <Table
      loading={loader}
      size={props.size}
      bordered={props.bordered}
      columns={[...props.columns]}
      onRow={(record) => ({
        onClick: () => props.onEdit && props.onEdit(record[props.rowKey]),
      })}
      dataSource={props.dataSource as any[]}
      rowKey={props.rowKey}
      pagination={props.pagination}
    />
  );
};

type CmsTabsProps = TabsProps & { children?: ReactNode };

export const CmsTabs = (props: CmsTabsProps) => {
  return (
    <Tabs type="card" animated={false} {...props}>
      {props.children}
    </Tabs>
  );
};
