import { useQuery } from "@tanstack/react-query";
import { Country } from "../../models/reference_lists/Country";
import api from "../../services/api";
import { CONST_COUNTRIES } from "../../utilities/constants/query-contants";

export const getCountries = (): Promise<Country[]> => {
  return api.get({
    path: CONST_COUNTRIES.plural,
  });
};

export const useQueryCountries = () => {
  return useQuery<Country[]>([CONST_COUNTRIES.plural], () => getCountries());
};
