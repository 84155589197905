export enum Role {
  Admin = "CmsAdministrator",
  Editor = "CmsEditor",
  Helpdesk = "CmsHelpdesk",
  FieldsEditor = "CmsFieldsEditor",
}

export const rolePermissions = [
  {
    role: Role.Admin,
    permissions: [],
  },
  {
    role: Role.Editor,
    permissions: [
      "view:document-full",
      "create:document-full",
      "update:document-full",
      "delete:document-full",
      "copy:document-full",
      "view:field-data",
      "create:field-data",
      "update:field-data",
      "delete:field-data",
      "copy:field-data",
    ],
  },
  {
    role: Role.FieldsEditor,
    permissions: [
      "view:document-full",
      "view:field-data",
      "create:field-data",
      "update:field-data",
      "delete:field-data",
      "copy:field-data",
    ],
  },
  {
    role: Role.Helpdesk,
    permissions: ["view:document-full"],
  },
];
