export enum ActionType {
  NotSpecified = "NOT_SPECIFIED",
  DocumentPublished = "DOCUMENT_PUBLISHED",
  DocumentExpected = "DOCUMENT_EXPECTED",
  DocumentEdited = "DOCUMENT_EDITED",
  NoteAdded = "NOTE_ADDED",
  DocumentOutOfCirculation = "DOCUMENT_OUT_OF_CIRCULATION",
  DocumentGoingOutOfCirculation = "DOCUMENT_GOING_OUT_OF_CIRCULATION",
  DocumentOutmoded = "DOCUMENT_OUTMODED",
}
