import * as _ from "lodash";
import update from "immutability-helper";

export interface OrderableItem {
  order?: number;
}

export const rearrangeItems = (
  items: OrderableItem[],
  fromIndex: number,
  toIndex: number
) => {
  const ordenedItems = _.orderBy(items, "order", "asc");

  let rearrangedItems = update(ordenedItems, {
    $splice: [
      [fromIndex, 1],
      [toIndex, 0, ordenedItems[fromIndex]],
    ],
  });

  const minIndex = _.min([fromIndex, toIndex]) ?? 0;
  const maxIndex = _.max([fromIndex, toIndex]) ?? 0;

  for (let i = minIndex; i <= maxIndex; i++) {
    rearrangedItems = update(rearrangedItems, {
      [i]: { $merge: { order: i } },
    });
  }

  return rearrangedItems;
};

export const addItem = <T extends OrderableItem>(item: T, items: T[]) => {
  const ordenedItems = _.orderBy(items, "order", "asc");

  ordenedItems.push(item);

  updateOrder(ordenedItems);

  return ordenedItems;
};

export const removeItem = <T extends OrderableItem>(
  index: number,
  items: T[]
) => {
  const ordenedItems = _.orderBy(items, "order", "asc");

  ordenedItems.splice(index, 1);

  updateOrder(ordenedItems);

  return ordenedItems;
};

const updateOrder = (items: OrderableItem[]) => {
  for (let i = 0; i < items.length; i++) {
    items[i].order = i;
  }
};
