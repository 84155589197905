import * as _ from "lodash";
import { ErrorDetails } from "../models/ErrorResponse";

export const hasSameKeys = (
  itemA: { [key: string]: string },
  itemB: { [key: string]: string }
): boolean => {
  for (const key of Object.keys(itemA)) {
    if (itemB[key] === undefined) {
      return false;
    }
  }

  for (const key of Object.keys(itemB)) {
    if (itemA[key] === undefined) {
      return false;
    }
  }

  return true;
};

export const hasSameValues = (
  itemA: { [key: string]: string },
  itemB: { [key: string]: string }
): boolean => {
  for (const key of Object.keys(itemA)) {
    if (itemA[key] !== itemB[key]) {
      return false;
    }
  }

  return true;
};

export const errorFormatter = (errors: ErrorDetails): string[] => {
  const sanatizedErrors = [] as string[];
  Object.entries(errors).forEach((value) => {
    let error = "";
    Object.values(value).forEach((item) => {
      if (typeof item === "string") {
        error += item;
      }

      if (typeof item === "object") {
        error += `: ${Object.values(item)[0]}`;
      }
    });
    sanatizedErrors.push(error);
  });

  return sanatizedErrors;
};

export const extractStrings = (object: any): string[] => {
  console.log(object);

  return _.flattenDeep(
    Object.keys(object).map((key) => {
      return typeof object[key] === "string"
        ? object[key]
        : extractStrings(object[key]);
    })
  );
};
