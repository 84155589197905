import { MultilingualInputData } from "../MultilingualInputData";

export class Field {
  public id?: string;
  public groupId?: string;
  public name?: string;
  public code?: string;
  public caption: MultilingualInputData = {};

  public withGroup(groupId: string) {
    this.groupId = groupId;
    return this;
  }
}
