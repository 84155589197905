import { useQuery } from "@tanstack/react-query";
import { DocumentTemplate } from "../../models/templates/DocumentTemplate";
import api from "../../services/api";
import { CONST_DOCUMENT_TEMPLATES } from "../../utilities/constants/query-contants";

export const getDocumentTemplates = (
  groupId?: string
): Promise<DocumentTemplate[]> => {
  return api.get({
    path: CONST_DOCUMENT_TEMPLATES.plural,
    query: groupId ? { groupId: groupId } : undefined,
  });
};

export const useQueryDocumentTemplates = (groupId?: string) => {
  return useQuery<DocumentTemplate[]>(
    [CONST_DOCUMENT_TEMPLATES.plural, { groupId }],
    () => getDocumentTemplates(groupId)
  );
};
