import { Form, Input, TreeSelect } from "antd";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { AppContext } from "../../../context/app/context";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { Field } from "../../../models/templates/Field";
import { convertGroupsForTreeSelect } from "../../../utilities/GroupHelper";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import { CmsForm } from "../../common/FormComponents";
import { MultilingualInput } from "../../common/MultilingualInput";
import { CmsPageHeader, CmsPageLoader } from "../../common/PageComponents";
import { useQueryField, useSaveField } from "../../../queries/fields/detail";
import { useQueryFieldGroups } from "../../../queries/field-groups/lists";
import { ValidateField } from "../../../utilities/helpers/field-helper";

export const FieldDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("field");
  const { push } = useHistory();
  const { id: fieldId } = useParams<Entity>();
  const { dispatch } = React.useContext(AppContext);

  const {
    data: field,
    isLoading: isLoadingField,
    refetch: refetchField,
  } = useQueryField(fieldId);
  const { mutateAsync: saveField, isLoading: isSavingField } = useSaveField();
  const { data: fieldGroups, isLoading: isLoadingFieldGroups } =
    useQueryFieldGroups();

  useEffect(() => {
    if (field) {
      dispatch({
        type: "SET_CUSTOM_DUPLICATE_ERROR_MESSAGE",
        payload: t("errors:duplicateField", {
          name: field.name,
          code: field.code,
        }),
      });
      dispatch({
        type: "SET_BREADCRUMB_ITEMS",
        payload: [
          {
            key: "field",
            name: field.name ?? t("common:new"),
          },
        ],
      });
    }
  }, [field, dispatch, t]);

  const noDataAny = (!field && !!fieldId) || !fieldGroups;

  const isLoadingAny =
    (isLoadingField && !!fieldId) || isSavingField || isLoadingFieldGroups;

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <Formik
      initialValues={field ?? new Field()}
      enableReinitialize={true}
      validate={(field: Field) => ValidateField(field, t)}
      onSubmit={async (field: Field) => {
        await saveField(field);
        !field.id ? push(`/fields`) : await refetchField();
      }}
    >
      {(formikProps) => {
        const {
          handleChange,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          isValid,
          dirty,
        } = formikProps;

        return (
          <React.Fragment>
            <Prompt when={dirty} message={t("common:unsavedChanges")}></Prompt>
            <CmsPageHeader
              title={t("entities:field")}
              extra={[
                <CmsBackButton
                  key="back"
                  disabled={isLoadingAny}
                  onClick={() => push(`/fields`)}
                />,
                !!canUpdate ? (
                  <CmsSaveButton
                    key="save"
                    disabled={!isValid || isLoadingAny}
                    loading={isLoadingAny}
                    onClick={() => handleSubmit()}
                  />
                ) : null,
              ]}
            />
            <CmsForm>
              <Form.Item
                label={t("properties:group")}
                validateStatus={!errors.groupId ? "success" : "error"}
                help={errors.groupId}
                required={true}
              >
                <TreeSelect
                  style={{ width: "100%", marginTop: "5px" }}
                  placeholder={t("properties:group")}
                  disabled={!canUpdate || isLoadingAny}
                  treeDefaultExpandAll={true}
                  treeData={convertGroupsForTreeSelect(fieldGroups)}
                  value={values.groupId}
                  id="groupId"
                  onChange={(value) => setFieldValue("groupId", value)}
                />
              </Form.Item>
              <Form.Item
                label={t("properties:name")}
                validateStatus={!errors.name ? "success" : "error"}
                help={errors.name}
                required={true}
              >
                <Input
                  id="name"
                  placeholder={t("properties:name")}
                  disabled={isLoadingAny}
                  readOnly={!canUpdate}
                  maxLength={50}
                  key="2"
                  value={values.name}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item
                label={t("properties:code")}
                validateStatus={!errors.code ? "success" : "error"}
                help={errors.code}
                required={true}
              >
                <Input
                  id="code"
                  placeholder={t("properties:code")}
                  disabled={isLoadingAny}
                  readOnly={!canUpdate}
                  maxLength={50}
                  key="3"
                  value={values.code}
                  onChange={(e) => {
                    e.target.value = e.target.value.toUpperCase();
                    e.target.value = e.target.value.replace(" ", "_");
                    handleChange(e);
                  }}
                />
              </Form.Item>
              <Form.Item
                label={t("properties:caption")}
                validateStatus={!errors.caption ? "success" : "error"}
                help={errors.caption}
              >
                <MultilingualInput
                  disabled={isLoadingAny}
                  readOnly={!canUpdate}
                  key="mlcAttrsCaption"
                  mlData={values.caption}
                  onChange={(value) => setFieldValue("caption", value)}
                />
              </Form.Item>
            </CmsForm>
          </React.Fragment>
        );
      }}
    </Formik>
  );
};
