import React, { useContext } from "react";
import { Route, RouteProps } from "react-router-dom";
import { AppContext } from "../../context/app/context";
import { useAuthorization } from "../../hooks/useAuthorization";

interface Props extends RouteProps {
  section?: string;
}

export const PrivateRoute = (props: Props) => {
  const { state: context } = useContext(AppContext);
  const { canView } = useAuthorization(props.section);

  return context.user && (canView || !props.section) ? (
    <Route {...props} />
  ) : null;
};
