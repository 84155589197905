import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";
import { connect } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { MultilingualInputData } from "../../../models/MultilingualInputData";
import { AttributeLink } from "../../../models/templates/AttributeLink";
import { DocumentTemplate } from "../../../models/templates/DocumentTemplate";
import {
  OrderableItem,
  addItem,
  rearrangeItems,
  removeItem,
} from "../../../utilities/ListHelpers";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { DragSortTable } from "../../common/DragSortTable";
import { CmsForm } from "../../common/FormComponents";
import { MultilingualInput } from "../../common/MultilingualInput";
import { RowButtonSet } from "../../common/RowButtonSet";
import { AttributeLinkDetailsModal } from "../attributeLinks/AttributeLinkDetailsModal";

interface Props {
  disabled?: boolean;
  readOnly?: boolean;
  viewTemplateIndex: number;
}

interface State {
  selectedAttributeLink: AttributeLink | null;
  selectedAttributeLinkIndex: number | null;
  attributeLinkModalVisible: boolean;
}

export const ViewTemplateDetails = connect<Props, DocumentTemplate>((props) => {
  const { t } = useTranslation();
  const [state, setState] = React.useState<State>({
    selectedAttributeLink: null,
    selectedAttributeLinkIndex: null,
    attributeLinkModalVisible: false,
  });

  const { handleChange, values, setFieldValue } = props.formik;
  const errors =
    props.formik.errors.viewTemplates &&
    props.formik.errors.viewTemplates[props.viewTemplateIndex]
      ? (props.formik.errors.viewTemplates[props.viewTemplateIndex] as any)
      : {};

  const viewTemplate = values.viewTemplates[props.viewTemplateIndex];
  const pathToViewTemplate = `viewTemplates[${props.viewTemplateIndex}]`;

  return (
    <React.Fragment>
      <CmsForm>
        <Form.Item
          label={t("properties:name")}
          validateStatus={!errors.name ? "success" : "error"}
          help={errors.name}
          required={true}
        >
          <Input
            placeholder={t("properties:name")}
            disabled={props.disabled}
            readOnly={props.readOnly}
            maxLength={50}
            id={`${pathToViewTemplate}.name`}
            value={viewTemplate.name}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item
          label={t("properties:caption")}
          validateStatus={!errors.caption ? "success" : "error"}
          help={errors.caption}
          required={true}
        >
          <MultilingualInput
            disabled={props.disabled}
            readOnly={props.readOnly}
            mlData={viewTemplate.caption}
            onChange={(e) => setFieldValue(`${pathToViewTemplate}.caption`, e)}
          />
        </Form.Item>
        <Form.Item label={t("properties:required")}>
          <Checkbox
            checked={viewTemplate.isRequired}
            disabled={props.readOnly || props.disabled}
            onChange={(e) =>
              setFieldValue(
                `${pathToViewTemplate}.isRequired`,
                e.target.checked
              )
            }
          />
        </Form.Item>
        <Form.Item label={t("entities:attributes")}>
          <DragSortTable
            bordered
            size={"small"}
            onRow={(record, index) =>
              ({
                moveRow: (fromIndex, toIndex) =>
                  setFieldValue(
                    `${pathToViewTemplate}.attributeLinks`,
                    rearrangeItems(
                      viewTemplate.attributeLinks as OrderableItem[],
                      fromIndex,
                      toIndex
                    )
                  ),
              } as any)
            }
            columns={[
              { title: t("entities:attribute"), dataIndex: "attributeName" },
              {
                title: t("properties:caption"),
                dataIndex: "caption",
                render: (caption: MultilingualInputData) => {
                  if (caption) {
                    return <span>{getLocalizedValue(caption)}</span>;
                  } else {
                    return null;
                  }
                },
              },
              {
                title: t("properties:exportable"),
                dataIndex: "exportable",
                render: (value) => (value ? t("common:yes") : t("common:no")),
              },
              {
                title: t("properties:required"),
                dataIndex: "isRequired",
                render: (value) => (value ? t("common:yes") : t("common:no")),
              },
              ...(!props.readOnly
                ? [
                    {
                      title: (
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<PlusOutlined />}
                          disabled={props.disabled}
                          style={{ margin: "0px 2px" }}
                          size="small"
                          onClick={() => {
                            setState((prevState) => ({
                              ...prevState,
                              selectedAttributeLink: new AttributeLink(),
                              selectedAttributeLinkIndex: -1,
                              attributeLinkModalVisible: true,
                            }));
                          }}
                        />
                      ),
                      dataIndex: "actions",
                      align: "right" as any,
                      render: (text: any, record: any, index) => (
                        <RowButtonSet
                          onEdit={() =>
                            setState((prevState) => ({
                              ...prevState,
                              selectedAttributeLink: record,
                              attributeLinkModalVisible: true,
                              selectedAttributeLinkIndex: index,
                            }))
                          }
                          onDelete={() => {
                            const attributeLinks = removeItem(
                              index,
                              viewTemplate.attributeLinks as OrderableItem[]
                            );
                            setFieldValue(
                              `${pathToViewTemplate}.attributeLinks`,
                              attributeLinks
                            );
                            setState((prevState) => ({
                              ...prevState,
                              selectedAttributeLink: null,
                              selectedAttributeLinkIndex: -1,
                            }));
                          }}
                        />
                      ),
                    },
                  ]
                : []),
            ]}
            dataSource={viewTemplate.attributeLinks}
            rowKey={(row, index) => row.id || (index as number)}
            pagination={false}
            readOnly={props.readOnly}
          />
        </Form.Item>
        <Form.Item label={t("properties:order")}>
          <DragSortTable
            bordered
            size={"small"}
            onRow={(record, index) =>
              ({
                moveRow: (fromIndex, toIndex) =>
                  setFieldValue(
                    `${pathToViewTemplate}.imageTemplates`,
                    rearrangeItems(
                      viewTemplate.imageTemplates as OrderableItem[],
                      fromIndex,
                      toIndex
                    )
                  ),
              } as any)
            }
            columns={[{ title: t("properties:name"), dataIndex: "name" }]}
            dataSource={viewTemplate.imageTemplates}
            rowKey={(row, index) => row.id || (index as number)}
            pagination={false}
            readOnly={props.readOnly}
          />
        </Form.Item>
      </CmsForm>
      {state.attributeLinkModalVisible && state.selectedAttributeLink ? (
        <AttributeLinkDetailsModal
          attributeLink={state.selectedAttributeLink}
          onSave={(attributeLink) => {
            let attributeLinks = viewTemplate.attributeLinks;

            if (
              state.selectedAttributeLinkIndex &&
              state.selectedAttributeLinkIndex >= 0
            ) {
              attributeLinks[state.selectedAttributeLinkIndex] = attributeLink;
            } else {
              attributeLinks = addItem(
                attributeLink as OrderableItem,
                attributeLinks as OrderableItem[]
              ) as AttributeLink[];
            }

            setFieldValue(
              `${pathToViewTemplate}.attributeLinks`,
              attributeLinks
            );

            setState((prevState) => ({
              ...prevState,
              selectedAttributeLink: null,
              selectedAttributeLinkIndex: -1,
              attributeLinkModalVisible: false,
            }));
          }}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              selectedAttributeLink: null,
              selectedAttributeLinkIndex: -1,
              attributeLinkModalVisible: false,
            }));
          }}
        />
      ) : null}
    </React.Fragment>
  );
});
