export class Province {
  public id?: string;
  public isoCode2?: string;
  public countryId?: string;
  public name?: string;
  public currencyIds: string[] = [];

  public withCountryId(countryId: string) {
    this.countryId = countryId;
    return this;
  }
}
