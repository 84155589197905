import { useQuery } from "@tanstack/react-query";
import { Attribute } from "../../models/templates/Attribute";
import api from "../../services/api";
import { CONST_ATTRIBUTES } from "../../utilities/constants/query-contants";

export const getAttributes = (groupId?: string): Promise<Attribute[]> => {
  return api.get({
    path: CONST_ATTRIBUTES.plural,
    query: groupId ? { groupId: groupId } : undefined,
  });
};

export const useQueryAttributes = (groupId?: string) => {
  return useQuery<Attribute[]>([CONST_ATTRIBUTES.plural, { groupId }], () =>
    getAttributes(groupId)
  );
};
