import Config from "../../Config";
import { Role } from "../../models/Role";
import { AppActionTypes, IBreadcrumbItem } from "./types";

export interface IState {
  user: {
    name: string;
    role: Role;
  } | null;
  breadcrumbItems: IBreadcrumbItem[];
  hasUnsavedChanges: boolean;
  multilingualInputActiveTab: string;
  multilingualInputCompareView: boolean;
  setFilterDocumentSeriesPanel: boolean;
  setSafeEditMode: boolean;
  setInstructionsAutoSave: boolean;
  setCustomDuplicateErrorMessage: string | undefined;
}

export const getInitialState = (authenticationEnabled: boolean) => {
  const initialState: IState = {
    user: null,
    breadcrumbItems: [],
    hasUnsavedChanges: false,
    multilingualInputActiveTab: Config.language.toLowerCase(),
    multilingualInputCompareView: false,
    setFilterDocumentSeriesPanel: false,
    setSafeEditMode: true,
    setInstructionsAutoSave: false,
    setCustomDuplicateErrorMessage: undefined,
  };

  if (!authenticationEnabled) {
    initialState.user = {
      name: "Admin",
      role: Role.Admin,
    };
  }

  return initialState;
};

export const reducer = (state: IState, action: AppActionTypes) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.payload };
    case "SET_BREADCRUMB_ITEMS":
      return { ...state, breadcrumbItems: action.payload };
    case "SET_HAS_UNSAVED_CHANGES":
      return { ...state, hasUnsavedChanges: action.payload };
    case "SET_MULTILINGUALINPUT_ACTIVE_TAB":
      return { ...state, multilingualInputActiveTab: action.payload };
    case "SET_MULTILINGUALINPUT_COMPARE_VIEW":
      return { ...state, multilingualInputCompareView: action.payload };
    case "SET_FILTER_DOCUMENTSERIES_PANEL":
      return { ...state, setFilterDocumentSeriesPanel: action.payload };
    case "SET_SAFE_EDIT_MODE":
      return { ...state, setSafeEditMode: action.payload };
    case "SET_INSTRUCTION_AUTOSAVE":
      return { ...state, setInstructionsAutoSave: action.payload };
    case "SET_CUSTOM_DUPLICATE_ERROR_MESSAGE":
      return { ...state, setCustomDuplicateErrorMessage: action.payload };
  }
};
