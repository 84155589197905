import { TFunction } from "i18next";
import {
  Country,
  CountryDocumentSupplier,
} from "../../models/reference_lists/Country";
import { DocumentType } from "../../models/reference_lists/DocumentType";
import {
  validateMLInput,
  validateRegEx,
  validateRequired,
} from "../Validators";

export const MapCountryDocumentSuppliers = (
  documentTypes: DocumentType[],
  countries: Country[],
  data: CountryDocumentSupplier[],
  type: "usedBy" | "suppliedBy",
  language: string
): {
  documentTypeId?: string;
  country?: string;
  documentTypeCode?: string;
  documentType?: string;
}[] => {
  return data.map((item) => {
    const documentType = documentTypes.find(
      (documentType) => documentType.id === item.documentTypeId
    );

    return {
      documentTypeId: item.documentTypeId,
      country: countries.find((country) =>
        type === "suppliedBy"
          ? country.id === item.supplierCountryId
          : country.id === item.countryId
      )?.name[language],
      documentType: documentType?.caption[language],
      documentTypeCode: documentType?.code,
    };
  });
};

export const ValidateCountry = (country: Country, t: TFunction) => {
  const errors: any = {};

  const nameResult = validateMLInput(country.name, t("properties:name"));
  if (nameResult != null) {
    errors.name = nameResult;
  }

  if (!country.isoCode2 || !country.isoCode3) return;
  const isoCode2Result =
    validateRequired(country.isoCode2, t("properties:iso2")) ||
    validateRegEx(
      country.isoCode2,
      /^[a-zA-Z]{2}$/,
      t("validations:inputLettersX", {
        property: t("properties:iso2"),
        value: 2,
      })
    );
  if (isoCode2Result != null) {
    errors.isoCode2 = isoCode2Result;
  }

  const isoCode3Result =
    validateRequired(country.isoCode3, t("properties:iso3")) ||
    validateRegEx(
      country.isoCode3,
      /^[a-zA-Z]{3}$/,
      t("validations:inputLettersX", {
        property: t("properties:iso3"),
        value: 3,
      })
    );
  if (isoCode3Result != null) {
    errors.isoCode3 = isoCode3Result;
  }

  return errors;
};
