import { Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Config from "../Config";
import { CmsForm, CmsSelect } from "./common/FormComponents";

interface Props {
  onClose(): void;
}

interface State {
  language: string;
}

export const Settings = (props: Props) => {
  const { t, i18n } = useTranslation();
  const [state, setState] = useState<State>({
    language: i18n.language,
  });

  return (
    <Modal
      title={t("common:settings")}
      open={true}
      onOk={() => {
        localStorage.setItem(Config.localStorageLanguageKey, state.language);
        i18n.changeLanguage(state.language);
        props.onClose();
      }}
      onCancel={props.onClose}
    >
      <CmsForm>
        <CmsSelect
          label={t("entities:language")}
          value={state.language}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, language: value }))
          }
        >
          <Select.Option value={"en"}>English</Select.Option>
          <Select.Option value={"nl"}>Nederlands</Select.Option>
        </CmsSelect>
      </CmsForm>
    </Modal>
  );
};
