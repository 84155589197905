import { useTranslation } from "react-i18next";
import {
  Country,
  CountryDocumentSupplier,
} from "../../../models/reference_lists/Country";
import { DocumentType } from "../../../models/reference_lists/DocumentType";
import { validateRequired } from "../../../utilities/Validators";
import { DetailsModal } from "../../data/documents/DetailsModal";

export const DocumentSupplierModal = ({
  show,
  editIndex,
  documentTypes,
  countries,
  data,
  onClose,
  onSave,
}: {
  show: boolean;
  editIndex?: number;
  documentTypes: DocumentType[];
  countries: Country[];
  data: CountryDocumentSupplier[];
  onClose: () => void;
  onSave: (suppliers: CountryDocumentSupplier[]) => void;
}) => {
  const { t, i18n } = useTranslation();

  const processSubmit = (value: CountryDocumentSupplier) => {
    let countryDocumentSuppliers: CountryDocumentSupplier[] = [];

    if (data) {
      const currentCountryDocumentSuppliers = data;
      if (editIndex !== undefined && editIndex !== -1) {
        currentCountryDocumentSuppliers[editIndex] = value;
      } else {
        currentCountryDocumentSuppliers.push(value);
      }

      countryDocumentSuppliers = currentCountryDocumentSuppliers;
    } else {
      countryDocumentSuppliers.push(value);
    }

    onSave(countryDocumentSuppliers);
  };

  const validate = (supplier: CountryDocumentSupplier) => {
    const errors: any = {};

    const documentTypeCheck = validateRequired(
      supplier.documentTypeId,
      t("entities:documentType")
    );

    if (documentTypeCheck != null) {
      errors.documentTypeId = documentTypeCheck;
    }

    const supplierCountryCheck = validateRequired(
      supplier.supplierCountryId,
      t("properties:country")
    );

    if (supplierCountryCheck != null) {
      errors.supplierCountryId = supplierCountryCheck;
    }

    return errors;
  };

  if (data == null) {
    return null;
  }

  const modalData = () => {
    let obj = {} as CountryDocumentSupplier;
    if (editIndex !== undefined && editIndex !== -1 && data) {
      obj = data[editIndex];
    }

    return obj;
  };

  return (
    <DetailsModal
      key={"countryDocumentSupplierModal"}
      title="countryDocumentSupplier"
      validate={validate}
      data={modalData()}
      onClose={onClose}
      fields={[
        {
          name: "documentTypeId",
          type: "SELECT",
          dataset: documentTypes.map((documentType) => ({
            id: documentType.id,
            caption: `${documentType.caption[i18n.language]} (${
              documentType.code
            })`,
          })),
        },
        {
          name: "supplierCountryId",
          type: "SELECT",
          dataset: countries.map((country) => ({
            id: country.id,
            caption: country.name[i18n.language],
          })),
        },
      ]}
      onSave={processSubmit}
      show={show}
    />
  );
};
