import { Divider, Statistic } from "antd";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../hooks/useApi";
import { CmsPageLoader } from "./common/PageComponents";

interface State {
  publishedDocuments: StatisticsResult[];
  publishedImages: StatisticsResult[];
}

interface StatisticsResult {
  name: string;
  count: number;
}

export const CmsDashboard = () => {
  const { t } = useTranslation();

  const [state, setState] = useState<State>({
    publishedDocuments: [],
    publishedImages: [],
  });

  const { get: getStatistics, isLoading } = useApi<StatisticsResult[]>(
    "reporting/statistics"
  );

  useEffect(() => {
    (async () => {
      const publishedDocuments = await getStatistics("publisheddocuments");
      const publishedImages = await getStatistics("publishedimages");
      setState((prevState) => ({
        ...prevState,
        publishedDocuments,
        publishedImages,
      }));
    })();
  }, [getStatistics]);

  return (
    <React.Fragment>
      <CmsPageLoader loading={isLoading} subTitle={t("common:loadingData")}>
        {state.publishedDocuments ? (
          <React.Fragment>
            <Divider orientation="left">
              {t("common:numberOfDocumentsPublished")}
            </Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
              }}
            >
              {_.map(state.publishedDocuments, (publishedDocument, index) => (
                <div
                  key={`div_${index}`}
                  style={{
                    width: "200px",
                    padding: "20px",
                    marginRight: "20px",
                    marginBottom: "20px",
                    backgroundColor: "#ebebeb",
                  }}
                >
                  <Statistic
                    title={publishedDocument.name}
                    value={publishedDocument.count}
                    precision={0}
                    groupSeparator="."
                  />
                </div>
              ))}
            </div>
          </React.Fragment>
        ) : null}

        {state.publishedImages ? (
          <React.Fragment>
            <Divider orientation="left">
              {t("common:numberOfImagesPublished")}
            </Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
              }}
            >
              {_.map(state.publishedImages, (publishedImage, index) => (
                <div
                  key={`div_${index}`}
                  style={{
                    width: "200px",
                    padding: "20px",
                    marginRight: "20px",
                    marginBottom: "20px",
                    backgroundColor: "#ebebeb",
                  }}
                >
                  <Statistic
                    title={publishedImage.name}
                    value={publishedImage.count}
                    precision={0}
                    groupSeparator="."
                  />
                </div>
              ))}
            </div>
          </React.Fragment>
        ) : null}
      </CmsPageLoader>
    </React.Fragment>
  );
};
