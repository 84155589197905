import { DocumentTemplate } from "../../models/templates/DocumentTemplate";

import * as _ from "lodash";
import { validateMLInput, validateName, validateRequired } from "../Validators";
import { TFunction } from "i18next";

export const ValidateDocumentTemplate = (
  documentTemplate: DocumentTemplate,
  t: TFunction
) => {
  return _.omitBy(
    {
      name:
        validateRequired(documentTemplate.name, t("properties:name")) ||
        validateName(documentTemplate.name, t("properties:name")),
      groupId: validateRequired(documentTemplate.groupId, t("properties:code")),
      viewTemplates: _.omitBy(
        documentTemplate.viewTemplates.map((viewTemplate) => {
          return _.omitBy(
            {
              name:
                validateRequired(viewTemplate.name, t("properties:name")) ||
                validateName(viewTemplate.name, t("properties:name")),
              caption: validateMLInput(
                viewTemplate.caption,
                t("properties:caption") ?? ""
              ),
              imageTemplates: _.omitBy(
                viewTemplate.imageTemplates.map((imageTemplate) => {
                  return _.omitBy(
                    {
                      name:
                        validateRequired(
                          imageTemplate.name,
                          t("properties:name")
                        ) ||
                        validateName(imageTemplate.name, t("properties:name")),
                      caption: validateMLInput(
                        imageTemplate.caption,
                        t("properties:caption")
                      ),
                    },
                    _.isEmpty
                  );
                }),
                _.isEmpty
              ),
            },
            _.isEmpty
          );
        }),
        _.isEmpty
      ),
    },
    _.isEmpty
  );
};
