import { MultilingualInputData } from "../MultilingualInputData";
import { FieldLink } from "./FieldLink";

export enum ScanningLight {
  Visible = "VISIBLE",
  Ultraviolet = "ULTRAVIOLET",
  Infrared = "INFRARED",
}

export class ImageTemplate {
  public id?: string;
  public viewTemplateId?: string;
  public name?: string;
  public caption: MultilingualInputData = {};
  public isRequired: boolean = true;
  public isDefault: boolean = false;
  public order?: number;
  public light: ScanningLight = ScanningLight.Visible;
  public fieldLinks: FieldLink[] = [];

  public withViewTemplateId(viewTemplateId: string) {
    this.viewTemplateId = viewTemplateId;
    return this;
  }
}
