import { CopyOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { DocumentSeries } from "../../../models/data/Document";
import { CopyInstructionsModal } from "./CopyInstructionsModal";
import { InstructionsList } from "./InstructionsList";

interface Props {
  viewIndex?: number;
  imageIndex?: number;
}

interface State {
  copyInstructionsModalVisible: boolean;
}

export const DocumentsImageInstruction = ({ viewIndex, imageIndex }: Props) => {
  const { canCopy } = useAuthorization("document");

  const [state, setState] = useState<State>({
    copyInstructionsModalVisible: false,
  });

  const { t } = useTranslation();

  const { values: document, setFieldValue } =
    useFormikContext<DocumentSeries>();

  if (viewIndex == null || imageIndex == null) {
    return null;
  }

  const image = document.views[viewIndex].images?.[imageIndex];

  return (
    <React.Fragment>
      {canCopy && (
        <Button
          type="primary"
          icon={<CopyOutlined />}
          style={{ marginBottom: "12px" }}
          onClick={() => {
            setState((prevState) => ({
              ...prevState,
              copyInstructionsModalVisible: true,
            }));
          }}
        >
          {t("common:copyTexts")}
        </Button>
      )}
      {image?.instructions && (
        <InstructionsList
          instructionsList={image.instructions ?? []}
          onChange={(instructions) => {
            setFieldValue(
              `views[${viewIndex}].images[${imageIndex}].instructions`,
              instructions
            );
          }}
          richText={true}
        />
      )}
      {state.copyInstructionsModalVisible ? (
        <CopyInstructionsModal
          onImport={(instructions) => {
            setFieldValue(
              `views[${viewIndex}].images[${imageIndex}].instructions`,
              instructions
            );
            setState((prevState) => ({
              ...prevState,
              copyInstructionsModalVisible: false,
            }));
          }}
          viewIndex={viewIndex}
          imageIndex={imageIndex}
          instructionType={"imageInstructions"}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              copyInstructionsModalVisible: false,
            }))
          }
        />
      ) : null}
    </React.Fragment>
  );
};
