import { Checkbox, Col, Divider, Input, Layout, Modal, Tabs } from "antd";
import DirectoryTree from "antd/lib/tree/DirectoryTree";
import { TreeNodeNormal } from "antd/lib/tree/Tree";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { default as config } from "../../../Config";
import { useApi } from "../../../hooks/useApi";
import { AttributeLink } from "../../../models/data/AttributeLink";
import { AttributeValue } from "../../../models/data/AttributeValue";
import { SecurityFeatureSelection } from "../../../models/data/SecurityFeatureSelection";
import { Group } from "../../../models/templates/Group";
import { SecurityFeature } from "../../../models/templates/SecurityFeature";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { CmsAddButton, CmsCancelButton } from "../../common/ButtonComponents";
import { CmsFormItem } from "../../common/FormComponents";
import { CmsPageLoader, CmsTabs } from "../../common/PageComponents";
import { AttributeValueEntries } from "./AttributeValueEntries";
import { InfoCircleOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const { Content, Sider } = Layout;

interface Props {
  onAdd: (
    securityFeatureSelection: SecurityFeatureSelection,
    selectedSecurityFeatureId: string,
    preGeneratedImagesCount?: number
  ) => void;
  onClose: () => void;
}

interface State {
  treeViewData: TreeNodeNormal[];
  selectedSecurityFeature: SecurityFeature | null;
  attributeValues: AttributeValue[];
  attributeValuesValid: boolean;
  previewFeature: SecurityFeature | null;
  preGenerateImages: boolean;
  preGeneratedImagesCount: number;
}

export const AddSecurityFeatureModal = (props: Props) => {
  const timer = useRef<NodeJS.Timer | null>(null);
  const { t } = useTranslation();

  const [state, setState] = useState<State>({
    treeViewData: [],
    selectedSecurityFeature: null,
    attributeValues: [],
    attributeValuesValid: true,
    previewFeature: null,
    preGenerateImages: true,
    preGeneratedImagesCount: 1,
  });

  const {
    getAll: getAllGroups,
    data: groups,
    isLoading: isLoadingGroups,
  } = useApi<Group>("securityFeatureGroups");
  const {
    getAll: getAllSecurityFeatures,
    data: securityFeatures,
    isLoading: isLoadingFeatures,
  } = useApi<SecurityFeature>("securityFeatures");
  const { get: getSecurityFeature, isLoading: isLoadingFeature } =
    useApi<SecurityFeature>("securityFeatures");

  useEffect(() => {
    getAllGroups();
    getAllSecurityFeatures();
  }, [getAllGroups, getAllSecurityFeatures]);

  useEffect(() => {
    if (groups && securityFeatures) {
      const createGroupTreeviewNodes = (groups: Group[]): TreeNodeNormal[] => {
        return groups.map((group) => {
          const securityFeatureNodes = securityFeatures
            .filter((securityFeature) => securityFeature.groupId === group.id)
            .map((securityFeature) => {
              return {
                key: securityFeature.id,
                title: getLocalizedValue(securityFeature.caption),
                selectable: true,
                isLeaf: true,
                children: undefined,
              };
            }) as TreeNodeNormal[];

          return {
            key: group.id as string,
            title: group.name,
            selectable: false,
            isLeaf: false,
            children: createGroupTreeviewNodes(
              group.children as Group[]
            ).concat(securityFeatureNodes),
          };
        });
      };

      const viewNodes = createGroupTreeviewNodes(groups);

      if (viewNodes.length > 0) {
        setState((prevState) => ({
          ...prevState,
          treeViewData: viewNodes,
        }));
      }
    }
  }, [groups, securityFeatures]);

  const onSelect = async (securityFeatureId: string) => {
    timer.current && clearTimeout(timer.current);
    const securityFeature = await getSecurityFeature(securityFeatureId);
    const attributeValues: AttributeValue[] =
      securityFeature.attributeLinks.map((attributeLink: AttributeLink) => ({
        attributeLinkId: attributeLink.id,
      }));

    setState((prevState) => ({
      ...prevState,
      selectedSecurityFeature: securityFeature,
      attributeValues,
      previewFeature: null,
    }));
  };

  const addSecurityFeatureSelection = () => {
    if (state.selectedSecurityFeature?.id) {
      const selection = {
        securityFeatureId: state.selectedSecurityFeature.id,
        securityFeatureName: state.selectedSecurityFeature.name,
        attributeValues: state.attributeValues,
        exportable: true,
      } as SecurityFeatureSelection;

      props.onAdd(
        selection,
        state.selectedSecurityFeature.id,
        state.preGenerateImages ? state.preGeneratedImagesCount : undefined
      );
    }
  };

  const onMouseEnter = async (info: { event; node }) => {
    timer.current && clearTimeout(timer.current);
    if (info.node.isLeaf) {
      timer.current = setTimeout(async () => {
        if (info.node.key !== state.selectedSecurityFeature?.id) {
          const previewFeature = await getSecurityFeature(info.node.key);
          setState((prevState) => ({ ...prevState, previewFeature }));
        } else {
          setState((prevState) => ({ ...prevState, previewFeature: null }));
        }
      }, 500);
    }
  };

  const onMouseOut = async () => {
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setState((prevState) => ({ ...prevState, previewFeature: null }));
    }, 250);
  };

  const displayFeature = state.previewFeature || state.selectedSecurityFeature;

  return (
    <Modal
      width={1200}
      style={{ height: "750px", maxHeight: "80%" }}
      title={t("common:addSecurityFeature")}
      destroyOnClose={true}
      open={true}
      onCancel={props.onClose}
      footer={[
        <CmsCancelButton key="cancel" onClick={props.onClose} />,
        <CmsAddButton
          key="create"
          disabled={
            !state.selectedSecurityFeature ||
            (state.attributeValues.length > 0 && !state.attributeValuesValid)
          }
          onClick={addSecurityFeatureSelection}
        />,
      ]}
    >
      <CmsPageLoader
        loading={
          isLoadingGroups ||
          isLoadingFeatures ||
          state.treeViewData.length === 0
        }
        subTitle={t("common:loadingData")}
      >
        <CmsTabs
          onChange={() =>
            setState((prevState) => ({
              ...prevState,
              selectedSecurityFeature: null,
              attributeValues: [],
              previewFeature: null,
            }))
          }
          tabPosition={"left"}
        >
          {state.treeViewData.map((item: TreeNodeNormal) => {
            return (
              <TabPane key={`tab_${item.key}`} tab={item.title}>
                <Layout
                  style={{
                    minHeight: "400px",
                    height: "50vh",
                    background: "#fff",
                  }}
                >
                  <Sider
                    width={"350px"}
                    style={{
                      overflow: "auto",
                      background: "#fff",
                      paddingRight: "24px",
                      borderRight: "1px solid rgb(235, 237, 240)",
                    }}
                  >
                    <DirectoryTree
                      onSelect={(selectedKeys) =>
                        onSelect(selectedKeys[0] as string)
                      }
                      selectedKeys={
                        state.selectedSecurityFeature
                          ? [state.selectedSecurityFeature.id as string]
                          : undefined
                      }
                      defaultExpandAll
                      treeData={item.children}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseOut}
                    />
                  </Sider>
                  <CmsPageLoader
                    loading={isLoadingFeature}
                    subTitle={t("common:loadingData")}
                    style={{
                      overflow: "auto",
                    }}
                  >
                    <Content
                      style={{
                        padding: "0px",
                        paddingLeft: "24px",
                        overflow: "auto",
                      }}
                    >
                      {displayFeature && (
                        <>
                          <Divider orientation="left" plain>
                            {t("common:exampleImage")}
                          </Divider>
                          {displayFeature.fileId === null ? (
                            "No preview image available"
                          ) : (
                            <p>
                              <img
                                src={`${config.apiUrl}file/${displayFeature.fileId}`}
                                alt="example"
                                style={{
                                  maxWidth: "400px",
                                  maxHeight: "400px",
                                  marginBottom: "10px",
                                }}
                              />
                            </p>
                          )}
                          <Divider orientation="left" plain>
                            {t("common:description")}
                          </Divider>
                          <p>{getLocalizedValue(displayFeature.description)}</p>
                          {displayFeature.attributeLinks.length > 0 && (
                            <>
                              <Divider orientation="left" plain>
                                {t("common:attributes")}
                              </Divider>
                              <p>
                                <AttributeValueEntries
                                  attributeLinks={displayFeature.attributeLinks}
                                  labelAlign="left"
                                  colLayout={{ xs: { span: 24 } }}
                                  formLayout={{
                                    labelCol: { xs: { span: 8 } },
                                    wrapperCol: { xs: { span: 16 } },
                                  }}
                                  attributeValues={
                                    state.previewFeature
                                      ? []
                                      : state.attributeValues
                                  }
                                  onChange={(attributeValues) => {
                                    setState((prevState) => ({
                                      ...prevState,
                                      attributeValues,
                                    }));
                                  }}
                                  onValidityChanged={(isValid) => {
                                    if (!state.previewFeature) {
                                      setState((prevState) => ({
                                        ...prevState,
                                        attributeValuesValid: isValid,
                                      }));
                                    }
                                  }}
                                />
                              </p>
                            </>
                          )}
                          <Divider orientation="left" plain>
                            {t("common:extraOptions")}
                          </Divider>
                          <p>
                            <Col>
                              <CmsFormItem
                                key={"pre-generate-images"}
                                label={t("common:preGenerateImages")}
                                labelAlign={"left"}
                                info={
                                  <InfoCircleOutlined
                                    title={t("common:preGenerateImagesInfo")}
                                  />
                                }
                              >
                                <Checkbox
                                  defaultChecked
                                  onChange={() =>
                                    setState((prevState) => ({
                                      ...prevState,
                                      preGenerateImages:
                                        !state.preGenerateImages,
                                    }))
                                  }
                                />
                                {state.preGenerateImages && (
                                  <Input
                                    type="number"
                                    style={{
                                      width: "80px",
                                      marginLeft: "10px",
                                    }}
                                    onChange={(images) => {
                                      const count = parseInt(
                                        images.currentTarget.value
                                      );
                                      setState((prevState) => ({
                                        ...prevState,
                                        preGeneratedImagesCount: count,
                                      }));
                                    }}
                                    defaultValue={1}
                                    min={1}
                                    max={26}
                                  />
                                )}
                              </CmsFormItem>
                            </Col>
                          </p>
                        </>
                      )}
                    </Content>
                  </CmsPageLoader>
                </Layout>
              </TabPane>
            );
          })}
        </CmsTabs>
      </CmsPageLoader>
    </Modal>
  );
};
