import { Checkbox, Col, InputNumber, Row } from "antd";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { DocumentSeries } from "../../../models/data/Document";
import {
  CmsColLayout,
  CmsForm,
  CmsFormItem,
  CmsFormLayout,
} from "../../common/FormComponents";

interface Props {
  viewIndex: number;
}

export const ViewProperties = (props: Props) => {
  const { canUpdate } = useAuthorization("document");

  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<DocumentSeries>();

  return (
    <React.Fragment>
      <Row>
        <Col {...CmsColLayout}>
          <CmsForm {...CmsFormLayout.twocolumn}>
            <CmsFormItem label={t("properties:width")}>
              <InputNumber
                readOnly={!canUpdate}
                value={values.views[props.viewIndex].width}
                onChange={(value) =>
                  setFieldValue(`views[${props.viewIndex}].width`, value)
                }
                placeholder={t("properties:width")}
                style={{ width: "150px" }}
              />
            </CmsFormItem>
            <CmsFormItem label={t("properties:height")}>
              <InputNumber
                readOnly={!canUpdate}
                value={values.views[props.viewIndex].height}
                onChange={(value) =>
                  setFieldValue(`views[${props.viewIndex}].height`, value)
                }
                placeholder={t("properties:height")}
                style={{ width: "150px" }}
              />
            </CmsFormItem>
          </CmsForm>
        </Col>
        <Col {...CmsColLayout}>
          <CmsForm {...CmsFormLayout.twocolumn}>
            <CmsFormItem label={t("properties:exportable")}>
              <Checkbox
                disabled={!canUpdate}
                checked={values.views[props.viewIndex].exportable}
                onChange={(e) =>
                  setFieldValue(
                    `views[${props.viewIndex}].exportable`,
                    e.target.checked
                  )
                }
              />
            </CmsFormItem>
            <CmsFormItem label={t("properties:deepZoomExportable")}>
              <Checkbox
                disabled={!canUpdate}
                checked={values.views[props.viewIndex].deepZoomExportable}
                onChange={(e) =>
                  setFieldValue(
                    `views[${props.viewIndex}].deepZoomExportable`,
                    e.target.checked
                  )
                }
              />
            </CmsFormItem>
          </CmsForm>
        </Col>
      </Row>
    </React.Fragment>
  );
};
