import { Point } from "react-lasso-select/lib/helpers";
import ImageDimensions from "../models/data/TargetImageDimensions";

export const ConvertPolygon = (
  points: Point[],
  type: "ABSOLUTE" | "RELATIVE" | "PERCENT",
  img: ImageDimensions
) => {
  const scaleX = img.natWidth / img.width;
  const scaleY = img.natHeight / img.height;

  let pointsArray = [] as Point[];
  if (type === "ABSOLUTE") {
    points.forEach((point) => {
      pointsArray.push({
        x: img.width * scaleX * point.x,
        y: img.height * scaleY * point.y,
      });
    });
    return pointsArray;
  }

  if (type === "PERCENT") {
    points.forEach((point) => {
      pointsArray.push({
        x: (img.width / scaleX) * point.x,
        y: (img.height / scaleY) * point.y,
      });
    });
    return pointsArray;
  }

  if (type === "RELATIVE") {
    const percentWidth = (img.width * scaleX) / 100;
    const percentHeight = (img.height * scaleY) / 100;
    points.forEach((point) => {
      pointsArray.push({
        x: point.x / percentWidth / 100,
        y: point.y / percentHeight / 100,
      });
    });
    return pointsArray;
  }
  return undefined;
};
