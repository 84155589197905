import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Country } from "../../../models/reference_lists/Country";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { CmsCreateButton } from "../../common/ButtonComponents";
import {
  CmsPageHeader,
  CmsSearch,
  CmsTable,
} from "../../common/PageComponents";
import { useQueryCountries } from "../../../queries/countries/lists";
import { useDeleteCountry } from "../../../queries/countries/detail";
import { AppContext } from "../../../context/app/context";

export const CountryList = () => {
  const { t } = useTranslation();
  const { canCreate, canDelete, canUpdate, canView } =
    useAuthorization("country");
  const { push } = useHistory();
  const { dispatch } = useContext(AppContext);
  const {
    data: countries,
    isLoading: isLoadingCountries,
    refetch: refetchCountries,
  } = useQueryCountries();
  const { mutateAsync: deleteCountry, isLoading: isLoadingDeleteCountry } =
    useDeleteCountry();

  const [countriesFilter, setCountriesFilter] = useState<Country[]>(
    countries ?? []
  );

  useEffect(() => {
    if (!!countries) {
      setCountriesFilter(countries);
    }
  }, [countries]);

  useEffect(() => {
    if (countriesFilter) {
      dispatch({
        type: "SET_CUSTOM_DUPLICATE_ERROR_MESSAGE",
        payload: t("errors:countryLinked"),
      });
    }
  }, [countriesFilter, dispatch, t]);

  const search = (searchText: string) => {
    if (countries) {
      const search = searchText.toLowerCase();
      const filteredCountries = countries.filter((country) => {
        const name = getLocalizedValue(country.name)?.toLowerCase();
        const iso2 = country.isoCode2 && country.isoCode2.toLowerCase();
        const iso3 = country.isoCode3 && country.isoCode3.toLowerCase();
        return (
          (name && name.includes(search)) ||
          (iso2 && iso2.includes(search)) ||
          (iso3 && iso3.includes(search))
        );
      });

      setCountriesFilter(filteredCountries);
    }
  };

  return (
    <React.Fragment>
      <CmsPageHeader
        title={t("entities:countries")}
        extra={[
          <CmsSearch key="search" onChange={search} />,
          canCreate && (
            <CmsCreateButton key="create" onClick={() => push(`/country`)} />
          ),
        ]}
      />
      <CmsTable
        loading={isLoadingCountries || isLoadingDeleteCountry}
        dataSource={countriesFilter}
        rowKey="id"
        columns={[
          {
            title: t("properties:name"),
            dataIndex: "name",
            render: (name) => getLocalizedValue(name),
          },
          { title: t("properties:iso2"), dataIndex: "isoCode2" },
          { title: t("properties:iso3"), dataIndex: "isoCode3" },
        ]}
        onEdit={
          canUpdate || canView ? (id) => push(`/country/${id}`) : undefined
        }
        onDelete={
          canDelete
            ? async (id) => {
                await deleteCountry(id);
                await refetchCountries();
              }
            : undefined
        }
      />
    </React.Fragment>
  );
};
