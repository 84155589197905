import { Divider, List } from "antd";
import { TFunction } from "i18next";
import * as React from "react";
import { MultilingualInputData } from "../../models/MultilingualInputData";
import "./MultilingualCompareList.scss";
import parse from "html-react-parser";

interface Props {
  mlData: MultilingualInputData;
  languages: any[];
  translation: TFunction;
}

export const MultilingualCompareList = (props: Props) => {
  const listItems = props.languages.map((order) => (
    <>
      <div style={{ minWidth: "30px", fontWeight: 700 }}>
        {order.isoCode.toLocaleUpperCase()}
      </div>
      <div style={{ textAlign: "left", lineBreak: "anywhere" }}>
        {parse(
          props.mlData[order.isoCode] !== undefined
            ? props.mlData[order.isoCode]
            : ""
        )}
      </div>
    </>
  ));

  return (
    <>
      <Divider orientation="left" style={{ fontSize: 12 }}>
        {props.translation("common:compare")}
      </Divider>
      <List
        size="small"
        bordered
        dataSource={listItems}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </>
  );
};
