import { Formik } from "formik";
import { CmsButton } from "../common/ButtonComponents";
import { useTranslation } from "react-i18next";
import { Form, Input, Modal } from "antd";
import { CmsModalForm } from "../common/FormComponents";
import { validateName, validateRequired } from "../../utilities/Validators";

type SearchName = {
  name: string;
};

export const CmsSearchSettingsModal = ({
  name,
  visible,
  onClose,
  onSave,
}: {
  name?: string;
  visible: boolean;
  onClose: () => void;
  onSave: (values: SearchName) => void;
}) => {
  const { t } = useTranslation();

  const validate = (setting: SearchName) => {
    const errors: any = {};

    const nameResult =
      validateRequired(setting.name, t("properties:name")) ||
      validateName(setting.name, t("properties:name"));
    if (nameResult != null) {
      errors.name = nameResult;
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{ name: name ?? "" }}
      enableReinitialize={true}
      validate={validate}
      onSubmit={(values) => onSave(values)}
    >
      {(formikProps) => {
        const { values, handleSubmit, setFieldValue, errors, isValid } =
          formikProps;

        return (
          <Modal
            width={400}
            title={"add / edit"}
            open={visible}
            onCancel={onClose}
            footer={[
              <CmsButton
                buttonType="cancel"
                key="cancel"
                style={{ marginRight: "8px" }}
                onClick={onClose}
              >
                {t("common:cancel")}
              </CmsButton>,
              <CmsButton
                buttonType="save"
                key="save"
                type="primary"
                disabled={!isValid}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {t("common:save")}
              </CmsButton>,
            ]}
          >
            <CmsModalForm>
              <Form.Item
                label={t("properties:name")}
                validateStatus={!errors.name ? "success" : "error"}
                help={errors.name}
                required={true}
              >
                <Input
                  id="name"
                  type="text"
                  placeholder={t("properties:name")}
                  style={{ width: "250px" }}
                  maxLength={30}
                  value={values.name}
                  onChange={(e) => setFieldValue("name", e.target.value)}
                />
              </Form.Item>
            </CmsModalForm>
          </Modal>
        );
      }}
    </Formik>
  );
};
