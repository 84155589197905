import React, { useReducer } from "react";
import config from "../../Config";
import { getInitialState, IState, reducer } from "./reducer";
import { AppActionTypes } from "./types";

interface IContext {
  state: IState;
  dispatch: (action: AppActionTypes) => void;
}

export const AppContext = React.createContext<IContext>({} as IContext);

export const AppProvider = (props) => {
  const [state, dispatch] = useReducer(
    reducer,
    getInitialState(config.authenticationEnabled)
  );

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AppContext.Provider>
  );
};
