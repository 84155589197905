import handleStatusCodes from "./handleErrorCodes";
import { ApiError, RequestOptions } from "./types";

const request = async ({
  path,
  options,
  isFile,
  isJson,
  isTotalCount,
  rawResponse,
}: RequestOptions) => {
  try {
    const response = await fetch(path, options);

    if (!isFile && handleStatusCodes(response.status)) return;

    if (response.ok) {
      if (rawResponse) return response;
      if (isFile) return await response.blob();
      if (isTotalCount) {
        return {
          count: response.headers.get("X-Total-Count"),
          data: await response.json(),
        };
      }
      if (isJson) return await response.json();

      return await response.text();
    }

    throw await response.json();
  } catch (error: any) {
    // eslint-disable-next-line
    throw error as ApiError;
  }
};

export default request;
