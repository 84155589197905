import { Button, Table, Tag } from "antd";
import { useFormik } from "formik";
import * as _ from "lodash";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Crop } from "react-image-crop";
import { Point } from "react-lasso-select/lib/helpers";
import Config from "../../../Config";
import { AppContext } from "../../../context/app/context";
import { useApi } from "../../../hooks/useApi";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { FieldValue } from "../../../models/data/FieldValue";
import { Field } from "../../../models/templates/Field";
import { FieldLink } from "../../../models/templates/FieldLink";
import "../../../styles/fieldlist.css";
import { capitalizeFirstLetter } from "../../../utilities/StringHelper";
import { CmsPageLoader } from "../../common/PageComponents";
import { RowButtonSet } from "../../common/RowButtonSet";
import FieldValuesEditorModal from "./FieldValuesEditorModal";
import { CopyOutlined } from "@ant-design/icons";
import FieldsCopyModal from "./FieldsCopyModal";
import { ColumnsType } from "antd/lib/table";

interface Props {
  fileId: string;
  imageId: string;
  fieldLinks: FieldLink[];
  fieldValues: FieldValue[];
  documentTemplateId: string;
  viewTemplateId: string;
  imageTemplateId: string;
  onChange?(fieldValues: FieldValue[]): void;
  onShowRegion?(
    shapeType: "POLYGON" | "RECTANGLE",
    crop?: Crop,
    points?: Point[]
  ): void;
  onClearRegion?(): void;
  onSaveDocument?(): void;
}

interface State {
  fields: Field[];
  selectedFieldIndex?: number;
  showSelectionModal: boolean;
  showFieldCopyModal: boolean;
}

interface MemorizedFieldValues {
  fieldId: string;
  fieldLinkId: string;
  fieldName: string;
  shapeType: "RECTANGLE" | "POLYGON";
  fieldValues: FieldValue[];
}

const defaultLanguage = Config.language;

export const FieldList = (props: Props) => {
  const { canCopy, canUpdate } = useAuthorization("field", "data");

  const [state, setState] = React.useState<State>({
    fields: [],
    selectedFieldIndex: undefined,
    showSelectionModal: false,
    showFieldCopyModal: false,
  });
  const [prevFieldValues, setPrevFieldValues] = React.useState<FieldValue[]>(
    props.fieldValues
  );

  const { dispatch } = React.useContext(AppContext);
  const { t } = useTranslation();
  const { get: getField, isLoading } = useApi<Field>("fields");

  const form = useFormik({
    initialValues: props.fieldValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch({ type: "SET_HAS_UNSAVED_CHANGES", payload: false });
      props.onChange && props.onChange(values);
    },
  });

  useEffect(() => {
    dispatch({ type: "SET_HAS_UNSAVED_CHANGES", payload: form.dirty });
  }, [form.dirty, dispatch]);

  useEffect(() => {
    (async () => {
      const fields = await Promise.all(
        props.fieldLinks
          .map((fieldLink) => fieldLink.fieldId)
          .map(async (id) => await getField(id as string))
      );

      setState((prevState) => ({ ...prevState, fields }));
    })();
  }, [props.fieldLinks, getField]);

  const memorizedFieldValues = React.useMemo(() => {
    if (state.fields.length > 0) {
      return props.fieldLinks
        .sort((a, b) => {
          const orderA = a.order ?? 0;
          const orderB = b.order ?? 0;

          return orderB > orderA ? -1 : orderB < orderA ? 1 : 0;
        })
        .map((fieldLink) => {
          const field = state.fields.find(
            (field) => field.id === fieldLink.fieldId
          );
          const values = props.fieldValues.filter(
            (fieldValue) => fieldValue.fieldLinkId === fieldLink.id
          );

          return {
            fieldId: field?.id,
            fieldLinkId: fieldLink.id,
            fieldName:
              field?.caption[defaultLanguage] &&
              capitalizeFirstLetter(field.caption[defaultLanguage]),
            fieldValues: values,
          } as MemorizedFieldValues;
        });
    }

    return [];
  }, [props.fieldLinks, props.fieldValues, state.fields]);

  const columns = [
    { title: t("properties:name"), dataIndex: "fieldName" },
    {
      title: t("properties:selections"),
      dataIndex: "fieldValues",
      render: (text, record, index) => {
        return _.map(text, (value, tagIndex) => {
          return (
            <Tag
              key={`field_tag_${index}_${tagIndex}`}
              style={{
                width: "35px",
                textAlign: "center",
                marginBottom: "5px",
              }}
              onMouseEnter={() => {
                props.onShowRegion &&
                  props.onShowRegion(
                    value.shapeType,
                    {
                      unit: "px",
                      x: value.x,
                      y: value.y,
                      width: value.width,
                      height: value.height,
                    },
                    value.points
                  );
              }}
              onMouseLeave={() => props.onClearRegion && props.onClearRegion()}
            >{`#${tagIndex + 1}`}</Tag>
          );
        });
      },
    },
    canUpdate && {
      width: "30px",
      render: (text, record, index) => (
        <RowButtonSet
          onEdit={() =>
            setState((prevState) => ({
              ...prevState,
              showSelectionModal: true,
              selectedFieldIndex: index,
            }))
          }
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      {canCopy && (
        <Button
          type="primary"
          icon={<CopyOutlined />}
          style={{ marginBottom: "12px" }}
          onClick={() => {
            setState((prevState) => ({
              ...prevState,
              showFieldCopyModal: true,
            }));
          }}
        >
          {t("common:copyFields")}
        </Button>
      )}
      <CmsPageLoader loading={isLoading} subTitle={t("common:loadingData")}>
        <Table
          bordered
          size={"small"}
          dataSource={memorizedFieldValues}
          rowKey={(row, index) => row.fieldLinkId ?? index}
          pagination={false}
          className="fieldListTable"
          columns={
            columns.filter(
              (column) => column
            ) as ColumnsType<MemorizedFieldValues>
          }
        />
      </CmsPageLoader>

      {state.showSelectionModal && (
        <FieldValuesEditorModal
          fileId={props.fileId}
          featureName={
            memorizedFieldValues[state.selectedFieldIndex ?? -1].fieldName
          }
          imageId={props.imageId}
          selection={memorizedFieldValues[state.selectedFieldIndex ?? -1]}
          onClose={(save = true) => {
            setState((prevState) => ({
              ...prevState,
              showSelectionModal: false,
            }));

            if (!props.onSaveDocument || !props.onChange) return;

            save ? props.onSaveDocument() : props.onChange(prevFieldValues);
          }}
          onSave={() => {
            setState((prevState) => ({
              ...prevState,
              selectedFieldIndex: undefined,
              showSelectionModal: false,
            }));
            props.onSaveDocument && props.onSaveDocument();
            setPrevFieldValues(props.fieldValues);
          }}
          onChange={(newFieldValues) => {
            const oldFieldValues = props.fieldValues.filter(
              (fieldValue) =>
                fieldValue.fieldLinkId !==
                memorizedFieldValues[state.selectedFieldIndex ?? -1].fieldLinkId
            );

            props.onChange &&
              props.onChange(oldFieldValues.concat(newFieldValues));
          }}
        />
      )}

      {state.showFieldCopyModal && (
        <FieldsCopyModal
          fields={memorizedFieldValues}
          documentTemplateId={props.documentTemplateId}
          imageTemplateId={props.imageTemplateId}
          viewTemplateId={props.viewTemplateId}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              showFieldCopyModal: false,
            }))
          }
          onSave={(newFieldValues) => {
            props.onChange && props.onChange(newFieldValues);
            setState((prevState) => ({
              ...prevState,
              showFieldCopyModal: false,
            }));
          }}
        />
      )}
    </React.Fragment>
  );
};
