import { useQuery } from "@tanstack/react-query";
import { SecurityFeature } from "../../models/templates/SecurityFeature";
import { CONST_SECURITY_FEATURES } from "../../utilities/constants/query-contants";
import api from "../../services/api";

export const getSecurityFeatures = (
  groupId?: string
): Promise<SecurityFeature[]> => {
  return api.get({
    path: CONST_SECURITY_FEATURES.plural,
    query: groupId ? { groupId: groupId } : undefined,
  });
};

export const useQuerySecurityFeatures = (groupId?: string) => {
  return useQuery<SecurityFeature[]>(
    [CONST_SECURITY_FEATURES.plural, { groupId }],
    () => getSecurityFeatures(groupId)
  );
};
