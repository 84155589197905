import { Select } from "antd";
import { Formik } from "formik";
import React, { Key, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../hooks/useApi";
import { Language } from "../../models/reference_lists/Language";
import {
  validateDifferent,
  validateRequired,
} from "../../utilities/Validators";
import { CmsDownloadButton, CmsUploadButton } from "../common/ButtonComponents";
import { CmsForm, CmsSelect } from "../common/FormComponents";
import { CmsPageHeader, CmsPageLoader } from "../common/PageComponents";
import { RcFile } from "antd/lib/upload/interface";
import { useNotification } from "../../hooks/useNotification";

interface State {
  languages: Languages;
}

interface Languages {
  source?: string;
  target?: string;
}

export const Translations = () => {
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  const [state, setState] = React.useState<State>({
    languages: {
      source: undefined,
      target: undefined,
    },
  });

  const {
    data: languages,
    getAll: getAllLanguages,
    isLoading: isLoadingLanguages,
  } = useApi<Language>("languages");
  const { get: exportTranslations, post: importTranslations } =
    useApi<File>("translations");

  const uploadFile = async (file: string | Blob | RcFile) => {
    const response = await importTranslations(file, { postAsFile: true });
    if (response.isSuccess) {
      notifySuccess("The translations import process was started succesfully.");
    }
  };

  useEffect(() => {
    getAllLanguages();
  }, [getAllLanguages]);

  const validate = (languages: Languages) => {
    const errors: any = {};

    const sourceResult = validateRequired(
      languages.source,
      t("properties:sourceLanguage")
    );
    if (sourceResult != null) {
      errors.source = sourceResult;
    }

    const targetResult = validateRequired(
      languages.target,
      t("properties:targetLanguage")
    );
    if (targetResult != null) {
      errors.target = targetResult;
    }

    if (sourceResult == null && targetResult == null) {
      const differentResult = validateDifferent(
        languages.source,
        languages.target,
        t("properties:sourceLanguage").toLowerCase(),
        t("properties:targetLanguage").toLowerCase()
      );
      if (differentResult != null) {
        errors.target = differentResult;
      }
    }

    return errors;
  };

  return (
    <React.Fragment>
      <CmsPageLoader
        loading={isLoadingLanguages}
        subTitle={t("common:loadingData")}
      >
        {languages ? (
          <Formik
            initialValues={state.languages}
            enableReinitialize={true}
            validate={validate}
            onSubmit={async (languages) => {
              setState((prevState) => ({ ...prevState, languages }));

              const response = await exportTranslations(
                `?sourceLanguage=${languages.source}&targetLanguage=${languages.target}`
              );

              const blob = new Blob(["\ufeff", response], {
                type: "text/csv;charset=utf-8;",
              });
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = `CMS_Translations_${languages.source?.toUpperCase()}_${languages.target?.toUpperCase()}.csv`;
              link.click();
            }}
          >
            {(formikProps) => {
              const { handleSubmit, setFieldValue, errors, isValid } =
                formikProps;

              return (
                <React.Fragment>
                  <CmsPageHeader
                    title={t("common:translations")}
                    extra={[
                      <CmsUploadButton
                        key="import"
                        accept=".csv"
                        customRequest={(options) => uploadFile(options.file)}
                      />,
                      <CmsDownloadButton
                        key="export"
                        disabled={!isValid}
                        onClick={() => handleSubmit()}
                      />,
                    ]}
                  />
                  <CmsForm>
                    <CmsSelect
                      label={t("properties:sourceLanguage")}
                      error={errors.source}
                      required={true}
                      placeholder={t("entities:language")}
                      onChange={(value) => setFieldValue("source", value)}
                      children={languages.map((language, index) => (
                        <Select.Option
                          key={`field_${index}`}
                          value={language.isoCode as Key}
                        >
                          {language.name}
                        </Select.Option>
                      ))}
                    />

                    <CmsSelect
                      label={t("properties:targetLanguage")}
                      error={errors.target}
                      required={true}
                      placeholder={t("entities:language")}
                      onChange={(value) => setFieldValue("target", value)}
                      children={languages.map((language, index) => (
                        <Select.Option
                          key={`field_${index}`}
                          value={language.isoCode as Key}
                        >
                          {language.name}
                        </Select.Option>
                      ))}
                    />
                  </CmsForm>
                </React.Fragment>
              );
            }}
          </Formik>
        ) : null}
      </CmsPageLoader>
    </React.Fragment>
  );
};
