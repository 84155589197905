import i18n from "i18next";
import {
  DateTimeFormat,
  IDateTimeSchema,
  IListSchema,
  IMultiLingualTextSchema,
  INumberSchema,
  ITextSchema,
} from "../models/InputComponentData";
import { AttributeDataType } from "../models/templates/Attribute";
import {
  validateMaxChars,
  validateMinChars,
  validateRegEx,
  validateRequired,
} from "../utilities/Validators";

export const validateAttributeValue = (
  dataType: AttributeDataType,
  schema: any,
  required: boolean,
  caption: string,
  value: string
) => {
  if (required) {
    const result = validateRequired(value, caption);
    if (result != null) {
      return result;
    }
  }

  switch (dataType) {
    case AttributeDataType.Text:
      return validateText(schema, value, caption);
    case AttributeDataType.MultiLingualText:
      return validateMLText(schema, value, caption);
    case AttributeDataType.Number:
      return validateNumber(schema, value, caption);
    case AttributeDataType.YesNo:
      return validateYesNo(value, caption);
    case AttributeDataType.DateTime:
      return validateDateTime(schema, value, caption);
    case AttributeDataType.List:
      return validateList(schema, value, caption);
    case AttributeDataType.Country:
    case AttributeDataType.Currency:
    case AttributeDataType.Language:
    case AttributeDataType.Region:
    case AttributeDataType.Organization:
    case AttributeDataType.Product:
    case AttributeDataType.DocumentType:
    case AttributeDataType.MaterialType:
      return validateGuid(value, caption);
  }
};

const createError = (
  errorMessage: string,
  defaultErrorMessage: string
): string => {
  return defaultErrorMessage ? defaultErrorMessage : errorMessage;
};

const validateText = (
  schema: ITextSchema,
  value: string,
  caption: string
): string | null => {
  if (schema.minLength) {
    const result = validateMinChars(value, schema.minLength, caption);
    if (result != null) {
      return createError(result, schema.errorMessage);
    }
  }

  if (schema.maxLength) {
    const result = validateMaxChars(value, schema.maxLength, caption);
    if (result != null) {
      return createError(result, schema.errorMessage);
    }
  }

  if (schema.regex) {
    if (value) {
      const result = validateRegEx(
        value,
        new RegExp(schema.regex),
        i18n.t("validations:regex", { property: caption, regex: schema.regex })
      );
      if (result != null) {
        return createError(result, schema.errorMessage);
      }
    }
  }

  return null;
};

const validateMLText = (
  schema: IMultiLingualTextSchema,
  value: string,
  caption: string
): string | null => {
  const mlTextValue = JSON.parse(value);
  // tslint:disable-next-line: forin
  for (const key in mlTextValue) {
    const item = mlTextValue[key];

    if (schema.minLength) {
      const result = validateMinChars(item, schema.minLength, caption);
      if (result != null) {
        return createError(result, schema.errorMessage);
      }
    }

    if (schema.maxLength) {
      const result = validateMaxChars(item, schema.maxLength, caption);
      if (result != null) {
        return createError(result, schema.errorMessage);
      }
    }

    if (schema.regex) {
      const result = validateRegEx(
        item,
        new RegExp(schema.regex),
        i18n.t("validations:regex", { property: caption, regex: schema.regex })
      );
      if (result != null) {
        return createError(result, schema.errorMessage);
      }
    }
  }

  return null;
};

const validateNumber = (
  schema: INumberSchema,
  value: string,
  caption: string
): string | null => {
  if (value && value.length > 0) {
    const floatValue = parseFloat(value);

    if (isNaN(floatValue)) {
      return createError(
        i18n.t("validations:number", { property: caption }),
        schema.errorMessage
      );
    }

    if (schema.decimalCount) {
      const decimals = value?.toString().split(".")[1]?.length || 0;
      if (decimals !== schema.decimalCount) {
        return createError(
          i18n.t("validations:decimalCount", {
            property: caption,
            value: schema.decimalCount,
          }),
          schema.errorMessage
        );
      }
    }

    if (schema.minValue) {
      if (floatValue < schema.minValue) {
        return createError(
          i18n.t("validations:min", {
            property: caption,
            value: schema.minValue,
          }),
          schema.errorMessage
        );
      }
    }

    if (schema.maxValue) {
      if (floatValue > schema.maxValue) {
        return createError(
          i18n.t("validations:max", {
            property: caption,
            value: schema.maxValue,
          }),
          schema.errorMessage
        );
      }
    }
  }

  return null;
};

const validateYesNo = (value: string, caption: string): string | undefined => {
  if (
    value &&
    value.toString().toLowerCase() !== "true" &&
    value.toString().toLowerCase() !== "false"
  ) {
    return i18n.t("validations:yesNo", { property: caption });
  }
};

const validateDateTime = (
  schema: IDateTimeSchema,
  value: string,
  caption: string
) => {
  let regexpattern = "";
  let pattern = "";
  switch (schema.format) {
    case DateTimeFormat.Date:
      regexpattern = "^\\d{4}-\\d{2}-\\d{2}$";
      pattern = "YYYY-MM-DD";
      break;
    case DateTimeFormat.DateTime:
      regexpattern = "^\\d{4}-\\d{2}-\\d{2} \\d{2}:\\d{2}$";
      pattern = "YYYY-MM-DD HH:mm";
      break;
    case DateTimeFormat.Time:
      regexpattern = "^\\d{2}:\\d{2}$";
      pattern = "HH:mm";
      break;
    case DateTimeFormat.YearAndMonth:
      regexpattern = "^\\d{4}-\\d{2}$";
      pattern = "YYYY-MM";
      break;
  }

  const regex = new RegExp(regexpattern);
  if (value && !value.match(regex)) {
    return i18n.t("validations:regex", { property: caption, regex: pattern });
  }
};

const validateList = (schema: IListSchema, value: string, caption: string) => {
  if (value) {
    if (schema.multiSelect) {
      const items = JSON.parse(value);
      for (const item in items) {
        if (
          !schema.items.find(
            (p) => p.code?.toUpperCase() === item?.toUpperCase()
          )
        ) {
          return createError(
            i18n.t("validations:invalidValue", { property: caption }),
            schema.errorMessage
          );
        }
      }
    } else {
      if (
        !schema.items.find(
          (p) => p.code?.toUpperCase() === value?.toUpperCase()
        )
      ) {
        return createError(
          i18n.t("validations:invalidValue", { property: caption }),
          schema.errorMessage
        );
      }
    }
  }
};

const validateGuid = (value: string, caption: string) => {
  const pattern =
    "^({{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}}{0,1})$";
  const regexp = new RegExp(pattern);
  if (value && !value.match(regexp)) {
    return i18n.t("validations:invalidValue", { property: caption });
  }
};
