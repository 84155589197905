import { useQuery } from "@tanstack/react-query";
import { Language } from "../../models/reference_lists/Language";
import api from "../../services/api";
import { CONST_LANGUAGES } from "../../utilities/constants/query-contants";

export const getLanguages = (): Promise<Language[]> => {
  return api.get({
    path: CONST_LANGUAGES.plural,
  });
};

export const useQueryLanguages = () => {
  return useQuery<Language[]>([CONST_LANGUAGES.plural], () => getLanguages());
};
