import { TFunction } from "i18next";
import React, { useEffect, useState } from "react";
import ReactLassoSelect, { getCanvas } from "react-lasso-select";
import { Point } from "react-lasso-select/lib/helpers";
import ImageDimensions from "../../../../models/data/TargetImageDimensions";
import { ConvertPolygon } from "../../../../utilities/PolygonHelper";

export default function LassoCrop(props: {
  src: string;
  t: TFunction;
  onCroppedImage: (x: any) => void;
  onImageSize: (x: any) => void;
  onSelection: (x: any) => void;
  preSelection?: Point[] | null;
}) {
  const [selection, setSelection] = useState<Point[]>([]);

  const [imageSize, setImageSize] = useState<ImageDimensions>({
    width: 0,
    height: 0,
    natWidth: 0,
    natHeight: 0,
  });

  const [croppedImage, setCroppedImage] = useState<string | undefined>();

  // TODO: remove this when new implemenation is done
  const imageSource = `${props.src}&cmsException=true`;

  const handleCroppedImageComplete = () => {
    props.onCroppedImage(croppedImage);
  };
  const handleImageSizeComplete = () => {
    props.onImageSize(imageSize);
  };
  const handleSelectionComplete = () => {
    props.onSelection(selection);
  };

  useEffect(() => {
    if (selection && croppedImage && imageSize) {
      handleImageSizeComplete();
      handleSelectionComplete();
      handleCroppedImageComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection, croppedImage, imageSize]);

  useEffect(() => {
    if (props.preSelection && imageSize.height > 0 && imageSize.width > 0) {
      const polygon = ConvertPolygon(props.preSelection, "ABSOLUTE", imageSize);
      if (polygon) {
        setSelection(polygon);
      }
    }
  }, [imageSize.height, imageSize.width, props.preSelection, imageSize]);

  return (
    <ReactLassoSelect
      src={imageSource}
      value={selection}
      onImageLoad={({ currentTarget }) => {
        setImageSize({
          width: currentTarget.width,
          height: currentTarget.height,
          natWidth: currentTarget.naturalWidth,
          natHeight: currentTarget.naturalHeight,
        });
      }}
      imageStyle={{ maxWidth: "600px" }}
      onComplete={(points) => {
        if (!points.length) return;
        setSelection(points);
        getCanvas(
          imageSource,
          points,
          (err, canvas) => !err && setCroppedImage(canvas.toDataURL())
        );
      }}
    />
  );
}
