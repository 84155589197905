import { Province } from "./Province";
import { MultilingualInputData } from "../MultilingualInputData";
import { Instruction } from "../data/Instruction";

export class Country {
  public id?: string;
  public name: MultilingualInputData = {};
  public isoCode2?: string;
  public isoCode3?: string;
  public monetaryMemberSince?: Date;
  public exportable?: boolean;
  public provinces?: Province[] = [];
  public regionIds?: string[] = [];
  public currencyIds?: string[] = [];
  public currencySortingOrder?: string[] = [];
  public documentsSuppliedByCountries?: CountryDocumentSupplier[] = [];
  public documentsUsedByCountries?: CountryDocumentSupplier[] = [];
  public instructions?: Instruction[];
}

export type CountryDocumentSupplier = {
  countryId: string;
  documentTypeId: string;
  supplierCountryId: string;
};
