import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect } from "react";
import Config from "../../Config";
import { Role } from "../../models/Role";
import { AppContext } from "./context";

export default function AuthenticatedContext() {
  const { keycloak } = useKeycloak();
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    if (Config.authenticationEnabled) {
      if (!keycloak.authenticated) {
        keycloak.login();
      } else {
        const role = Object.values(Role).find((providerName) => {
          if (
            keycloak.clientId !== undefined &&
            keycloak.resourceAccess !== undefined &&
            keycloak.resourceAccess[keycloak.clientId] !== undefined
          ) {
            return keycloak.resourceAccess[keycloak.clientId].roles.find(
              (roleName) => roleName === providerName
            );
          } else {
            return keycloak.realmAccess?.roles.find(
              (roleName) => roleName === providerName
            );
          }
        });

        if (role === undefined) {
          keycloak.logout();
          return;
        }

        dispatch({
          type: "SET_USER",
          payload: {
            name: keycloak.idTokenParsed?.name,
            role,
          },
        });
      }
    }
  }, [dispatch, keycloak]);

  return <></>;
}
