import { Button, Result } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export const ServerError = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <React.Fragment>
      <Result
        status={500}
        title={t("errors:serverError")}
        subTitle={t("errors:serverErrorText")}
        extra={
          <Button type="primary" onClick={() => history.push("/")}>
            {t("common:home")}
          </Button>
        }
      />
    </React.Fragment>
  );
};
