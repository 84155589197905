import { useApi } from "../../hooks/useApi";
import { CmsDownloadButton } from "../common/ButtonComponents";
import blobToFile from "js-file-download";
import { CmsPageHeader } from "../common/PageComponents";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CmsNumber } from "../common/FormComponents";

export const MagazineExport = () => {
  const { download, isLoading: isDownloading } = useApi<any>(`documentSeries`);
  const { t } = useTranslation();
  const [exportYear, setExportYear] = useState<number>(
    new Date().getFullYear()
  );

  return (
    <>
      <CmsPageHeader title={t("common:magazineExport")} />
      <CmsNumber
        id="magazineExport"
        label={t("properties:year")}
        placeholder={t("properties:year")}
        value={exportYear}
        onChange={(value) => setExportYear(value == null ? 2024 : value)}
      />
      <p>{t("common:magazineExportExplaination")}</p>
      <CmsDownloadButton
        key="download"
        disabled={isDownloading}
        onClick={async () => {
          const file = await download("TDO", {
            querystringParams: {
              untilYear: exportYear.toString(),
            },
          });
          if (!file) return;
          blobToFile(file, `keesing-documents-export-${exportYear}.zip`);
        }}
      />
    </>
  );
};
