export const IsImage = (filename?: string) => {
  if (!filename) {
    return false;
  }

  const index = filename.lastIndexOf(".");
  const extension = filename.substring(index + 1).toLowerCase();
  const imageExtensions = [
    "jpg",
    "jpeg",
    "gif",
    "png",
    "apng",
    "svg",
    "bmp",
    "tiff",
    "ico",
    "webp",
    "jfif",
  ];

  return imageExtensions.includes(extension);
};
