import { useTranslation } from "react-i18next";
import { useState } from "react";
import Search from "antd/lib/input/Search";
import { useQueryDocumentSeriesIndex } from "../../queries/document-series/lists";
import { CmsTable } from "../common/PageComponents";
import { ToDateTimeFormat } from "../../utilities/helpers/date-helper";

export const ChangeTracker = () => {
  const { t } = useTranslation();
  const [queryString, setQueryString] = useState<string | undefined>();
  const [pagination, setPagination] = useState<{ limit: number; skip: number }>(
    {
      limit: 10,
      skip: 0,
    }
  );

  const {
    data: search,
    isLoading: isLoadingSearch,
    refetch,
  } = useQueryDocumentSeriesIndex({
    code: queryString,
    orderBy: "modified",
    sortDirection: 1,
    limit: queryString ? 10 : pagination.limit,
    skip: queryString ? 0 : pagination.skip,
  });

  return (
    <>
      <Search
        placeholder={t("common:searchQuery")}
        onSearch={async (query) => {
          setQueryString(query);
          await refetch();
        }}
        allowClear
        enterButton
        loading={isLoadingSearch}
      />
      <CmsTable
        loading={isLoadingSearch}
        dataSource={search?.data ?? []}
        rowKey="id"
        columns={[
          {
            title: t("properties:code"),
            dataIndex: "code",
          },
          { title: t("properties:assertedBy"), dataIndex: "assertedBy" },
          {
            title: t("properties:modified"),
            dataIndex: "modified",
            render: (value) => ToDateTimeFormat(value, "YYYY-MM-DD hh:mm:ss"),
          },
        ]}
        pagination={{
          total: search?.count,
          pageSize: 10,
          onChange: (page) => {
            setPagination({ limit: 10, skip: page === 0 ? 0 : page * 10 });
          },
        }}
      />
    </>
  );
};
