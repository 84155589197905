import { useQuery } from "@tanstack/react-query";
import api from "../../services/api";
import { Region } from "../../models/reference_lists/Region";
import { CONST_REGIONS } from "../../utilities/constants/query-contants";

export const getRegions = (): Promise<Region[]> => {
  return api.get({
    path: CONST_REGIONS.plural,
  });
};

export const useQueryRegions = () => {
  return useQuery<Region[]>([CONST_REGIONS.plural], () => getRegions());
};
